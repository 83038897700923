export const hasNonEmptyTextDeep = (children: any) => {
    return children?.some((child: any) => {
        if (child.type === 'text' && child.data.trim().length > 0) {
            return true;
        } else if (child.children) {
            return hasNonEmptyTextDeep(child.children);
        }
        return false;
    });
};
