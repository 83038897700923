import { Dispatch } from '@reduxjs/toolkit';
import { AnyAction } from 'redux-saga';
import { deleteEmptyParams } from '../../../../utils';
import { zooluxCallCenterAPI } from '../../../zooluxCallCenterAPI';
import { IUsersResponse } from '../../repository/IUser';

const userAPI = zooluxCallCenterAPI.injectEndpoints({
    endpoints: (builder) => ({
        getUsersAdmin: builder.query<IUsersResponse, any>({
            query: (params) => ({
                url: `/admin/user/list`,
                method: 'GET',
                params: deleteEmptyParams(params)
            }),
            async onQueryStarted(_, { dispatch, queryFulfilled }: {
                dispatch: Dispatch<AnyAction>,
                queryFulfilled: Promise<{ data: IUsersResponse; }>,
            }) {
                try { } catch (error) {
                    console.error("Error fetching getUsersAdmin:", error);
                }
            },
            providesTags: ["UserAdmin"]
        }),
    }),
    overrideExisting: false,
});

export const { useGetUsersAdminQuery } = userAPI;
