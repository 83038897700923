import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface IInitialState {
    tempSmsCode: number | null;
    isPhoneConfirmed: boolean | null;
    isLoading: boolean;
    isConfirmedSms: boolean;
    isProcessing: boolean;
    isLoggedIn: any;
}

interface VerifyPayloadAction {
    isPhoneConfirmed: boolean | null;
}

const initialState: IInitialState = {
    tempSmsCode: null,
    isPhoneConfirmed: null,
    isLoading: false,
    isConfirmedSms: false,
    isProcessing: false,
    isLoggedIn: globalThis.localStorage.getItem('isLoggedIn'),
};

const AuthSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        setConfirmedSms: (state, action: PayloadAction<boolean>) => {
            state.isConfirmedSms = action.payload;
        },
        setLoading: (state, action: PayloadAction<boolean>) => {
            state.isLoading = action.payload;
        },
        setProcessing: (state, action: PayloadAction<boolean>) => {
            state.isProcessing = action.payload;
        },
        setIsLoggedIn: (state, action: PayloadAction<boolean>) => {
            state.isLoggedIn = action.payload;
        },
        setVerify: (state, action: PayloadAction<VerifyPayloadAction>) => {
            state.isPhoneConfirmed = action.payload.isPhoneConfirmed;
        },
        setLogout: () => {
            globalThis.localStorage.removeItem('isLoggedIn');
            return { ...initialState };
        },
    },
});

export const { setLoading, setVerify, setLogout, setConfirmedSms, setProcessing, setIsLoggedIn } = AuthSlice.actions;

export const AuthReducer = AuthSlice.reducer;
