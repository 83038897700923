import { AddCircleOutline as AddCircleOutlineIcon, RemoveCircleOutline as RemoveCircleOutlineIcon, VisibilityOffOutlined as VisibilityOffOutlinedIcon } from '@mui/icons-material';
import { Box, IconButton, Stack, SvgIcon, Typography } from "@mui/material";
import { Dispatch, FC, SetStateAction, useCallback, useMemo } from "react";
import { Link, useLocation } from "react-router-dom";
import { usePermissionContext } from '../../../../../hooks';
import { ICategory } from '../../../../../store/category/repository/ICategory';
import { useDragCategory } from "../../presenters/useDragCategory";
import { getStyles } from "./styles";
import DeleteForeverOutlinedIcon from '@mui/icons-material/DeleteForeverOutlined';
import ModeEditOutlineOutlinedIcon from '@mui/icons-material/ModeEditOutlineOutlined';
import { useHandleCategory } from '../../presenters/useHandleCategory';
import { DeleteCategoryModalAdmin } from '../../../deleteCategoryModalAdmin/ui';
import { UpsertCategoryModalAdmin } from '../../../../../features/modals';

interface IProps {
	category: any;
	capturedCategory: any;
	paddingLeft: number;
	paramsCategoryId: number;
	setCapturedCategory: (category: any) => void;
	currentCategory: number[];
	setCurrentCategory: Dispatch<SetStateAction<number[]>>;
	treeCategories: ICategory[];
}; 

export const CategoriesItem: FC<IProps> = ({ category, capturedCategory, setCapturedCategory, currentCategory, setCurrentCategory, paddingLeft, paramsCategoryId, treeCategories }) => {
	const { pathname: pathnameCategory } = useLocation();
	const styles = useMemo(() => getStyles(paddingLeft), [paddingLeft]);
	const { permissionMap } = usePermissionContext();
	const { dragDropHandler, dragEndHandler, dragLeaveHandler, dragOverHandler, dragStartHandler } = useDragCategory({ category, capturedCategory, setCapturedCategory, treeCategories });
	const { onDeleteCategoryModal, isOpenDeleteCategory, onCloseDeleteCategory, isOpenUpdateCategory, onCloseUpdateCategory, onUpdateCategoryModal } = useHandleCategory();

	const handleCategoryToggle = useCallback(() => {
		setCurrentCategory((prevCategoryId: number[]) => {
			if (prevCategoryId.includes(category.id)) {
				return prevCategoryId.filter(el => el !== category.id);
			}
			return [...prevCategoryId, category.id];
		});
	}, [category.id, setCurrentCategory]);

	const isCurrent = currentCategory.includes(category.id);
	const categoryItemAmount = category.category_items?.length ? ` (${category.category_items?.length})` : '(0)';

	return (
		<Stack
			onDragStart={permissionMap?.Edit_Category ? dragStartHandler : undefined}
			onDragLeave={permissionMap?.Edit_Category ? dragLeaveHandler : undefined}
			onDragEnd={permissionMap?.Edit_Category ? dragEndHandler : undefined}
			onDragOver={permissionMap?.Edit_Category ? dragOverHandler : undefined}
			onDrop={permissionMap?.Edit_Category ? dragDropHandler : undefined}
		>
			<Stack sx={pathnameCategory === `/category/${category.id}` ? styles.activeCategoryRow : styles.categoryRow}>
				<Stack sx={styles.chevronIcon}>
					{category?.children?.length > 0 ?
						<IconButton sx={styles.iconButton} onClick={handleCategoryToggle}>
							<SvgIcon>{isCurrent ? <RemoveCircleOutlineIcon /> : <AddCircleOutlineIcon />}</SvgIcon>
						</IconButton> : <SvgIcon></SvgIcon>
					}
				</Stack>
				<Stack sx={styles.dragIcon}>
					<Box component={'img'} src={paddingLeft === 0 ? '/images/main-category.svg' : '/images/under-category.svg'} sx={{ width: 19, height: 19 }} />
					{category.is_hidden ? <SvgIcon sx={{ width: 19, height: 19 }}><VisibilityOffOutlinedIcon sx={{ color: "gray" }} /></SvgIcon> : null}
				</Stack>
				<Stack width="100%" direction="row" alignItems="flex-start"  sx={styles.categoryLink} component={Link} to={`/category/${category.id}`}>
					<Typography pr={.5}>{category.name }</Typography>
					<Typography whiteSpace="nowrap">{categoryItemAmount}</Typography>
				</Stack>
				{permissionMap?.Edit_Category && 
				<Stack direction="row">
					<IconButton size='small' onClick={onDeleteCategoryModal}><SvgIcon><DeleteForeverOutlinedIcon /></SvgIcon></IconButton>
					<IconButton size='small' onClick={onUpdateCategoryModal}><SvgIcon><ModeEditOutlineOutlinedIcon /></SvgIcon></IconButton>
				</Stack>}
			</Stack>
			<Stack>
				{isCurrent && category?.children.length
					? category?.children.map((item: any) => (
						<CategoriesItem
							treeCategories={treeCategories}
							currentCategory={currentCategory}
							setCurrentCategory={setCurrentCategory}
							paddingLeft={paddingLeft + 2}
							category={item}
							key={item.id}
							paramsCategoryId={paramsCategoryId}
							capturedCategory={capturedCategory}
							setCapturedCategory={setCapturedCategory}
						/>
					))
					: null}
			</Stack>
			{isOpenDeleteCategory && <DeleteCategoryModalAdmin categoryId={category.id} onCloseDeleteCategory={onCloseDeleteCategory} isOpenDeleteCategory={isOpenDeleteCategory} categoryName={category?.name} />}
			{isOpenUpdateCategory && <UpsertCategoryModalAdmin actionKey='update' categoryId={category.id} onCloseUpsertCategory={onCloseUpdateCategory} isOpenUpsertCategory={isOpenUpdateCategory} />}
		</Stack>
	);
};