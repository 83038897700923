import PersonOutlineIcon from '@mui/icons-material/PersonOutline';
import { Button, Divider, Paper, Popover, Stack, Typography } from '@mui/material';
import { useEffect, type FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { tokens } from '../../locales/tokens';
import { selectUser, setUser } from '../../store/user/repository/slice';
import { MenuItem } from './menuItem';
import { getStyles } from './styles';
import { useGetAccessToAccountMutation } from '../../store/user/useCases/getAccessToAccount/worker';
import { useNavigate } from 'react-router-dom';
import { paths } from '../../paths';
import toast from 'react-hot-toast';
import { usePermissionContext } from '../../hooks';
import ReplyIcon from '@mui/icons-material/Reply';

interface IProps {
  open?: boolean;
  anchorEl: null | Element;
  onClose?: () => void;
  handleLogout: () => void;
};

export const AccountPopover: FC<IProps> = ({ anchorEl, onClose, open, handleLogout, }) => {
  const styles = getStyles();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const user = useSelector(selectUser);
  const [getAccessToAccount, { isSuccess: isSuccessGetAccessToAccount, data: dataGetAccessToAccount }] = useGetAccessToAccountMutation();

  const { refetchGetProfileUser } = usePermissionContext();
  
  const previousUserString = localStorage.getItem('previousUser');
  const previousUser = JSON.parse(previousUserString || 'null');
  const userId = previousUser ? previousUser.id : null;

  useEffect(() => {
    if (isSuccessGetAccessToAccount) {
      localStorage.removeItem('previousUser');
      localStorage.removeItem('previousAccessToken');
      localStorage.setItem('accessToken', dataGetAccessToAccount.token);
      dispatch(setUser(dataGetAccessToAccount.user));

      toast.success(`Ви зайши під даними ${dataGetAccessToAccount.user.full_name}`)
      navigate(paths.privateRoutes.users, { replace: true });
    }
  }, [isSuccessGetAccessToAccount]);

  const signInToUserAccount = (id: number) => {
    const previousAccessToken = localStorage.getItem('previousAccessToken');
    localStorage.setItem('accessToken', previousAccessToken || '');
    getAccessToAccount({ id });
    onClose?.();

    refetchGetProfileUser();
  };

  return (
    <Popover
      anchorEl={anchorEl}
      anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      disableScrollLock
      onClose={onClose}
      open={!!open}
    >
      <Paper sx={{ minWidth: "170px" }}>
        <Stack p={1.5} >
          <Typography variant="h6"> {user?.full_name || "Ім'я Прізвище"} </Typography>
          <Typography color="text.secondary" variant="body2" > {user?.phone || "+380XXXXXXXXX"} </Typography>
          <Stack width='100%'>
            {user?.roles?.map((role: any, index: number) =>
              <Typography key={index} color="text.secondary" variant="body2">{role.name}</Typography>
            )}
          </Stack>
        </Stack>
        <Divider />
        <Stack p={1} rowGap="8px">
          <MenuItem onClose={onClose} path='/profile' title='Профіль' picture={<PersonOutlineIcon />} />
          {previousUserString &&
            <MenuItem onClose={() => signInToUserAccount(userId)} path='#' title='Повернутись на  особисту сторінку' picture={<ReplyIcon />} />}
          <Button color="inherit" onClick={handleLogout} size="small" sx={styles.logout}>
            {t(tokens.auth.logout)}
          </Button>
        </Stack>
      </Paper>
    </Popover >
  );
};