import toast from 'react-hot-toast';
import { SagaIterator } from 'redux-saga';
import { call, put, takeLatest } from 'redux-saga/effects';
import { IResponse } from '../../../../libs/requester/IResponse';
import { requester } from '../../../../libs/requester/requester-axios';
import { setProcessing, setVerify } from '../../repository/slice';
import i18n from '../../../../locales/i18n';
import { tokens } from '../../../../locales/tokens';

function* verifyPhoneWorker({ payload }: any): SagaIterator {
    try {
        yield put(setProcessing(true));

        const { data, isError, message }: IResponse<any> = yield call(
            requester.post,
            '/user-auth/verify-phone',
            payload
        );

        if (isError) {
            toast.error(i18n.t(tokens.auth.messages[message as keyof typeof tokens.auth.messages]));
        } else {
            if (data?.message === 'code_sent') {
                yield put(setVerify({ isPhoneConfirmed: false }));
            } else {
                yield put(setVerify({ isPhoneConfirmed: true }));
            }
        }
    } catch (error) {
        // console.error('verifyPhoneWorker: ', error);
    } finally {
        yield put(setProcessing(false));
    }
}

export function* verifyPhoneWatcher() {
    yield takeLatest('@saga/VERIFY_PHONE', verifyPhoneWorker);
}
