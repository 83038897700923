import { LoadingButton } from '@mui/lab';
import { Checkbox, Stack, TableCell, TableHead, TableRow } from "@mui/material";
import { ChangeEvent, FC } from "react";
import { useTranslation } from "react-i18next";

interface IProps {
    selectedAll: boolean;
    selectedSome: boolean;
    handleToggleAll: (e: ChangeEvent<HTMLInputElement>) => void;
    handleDeleteAll: () => void;
    titleButton: string;
};

export const UsersTableHead: FC<IProps> = ({ handleToggleAll, selectedAll, selectedSome, handleDeleteAll, titleButton }) => {
    const { t } = useTranslation();

    return (
        <TableHead sx={{ position: "sticky", top: 0, zIndex: 1, bgcolor: 'background.default' }}>
            <TableRow>
                <TableCell padding="checkbox" sx={{ width: "5%" }}>
                    <Stack direction='row' alignItems={"center"}>
                        <Checkbox checked={selectedAll} indeterminate={selectedSome} onChange={handleToggleAll} />
                        {selectedAll || selectedSome ?
                            <LoadingButton variant='outlined' onClick={handleDeleteAll} sx={{ position: 'absolute', marginLeft: { xs: "8%", lg: "6%" } }}
                            >
                                {titleButton}
                            </LoadingButton> : null}
                    </Stack>
                </TableCell>
                <TableCell sx={{ width: '35%', pl: "220px" }} align="center">Ім'я</TableCell>
                <TableCell sx={{ width: '20%' }} align="center">Посада</TableCell>
                <TableCell sx={{ width: '20%' }} align="center">Телефон</TableCell>
                <TableCell sx={{ width: '15%' }} align="center">Дії</TableCell>
            </TableRow>
        </TableHead>
    );
};