import { useEffect } from 'react';
import { useNavigate } from 'react-router';
import { useGetAccess } from '../hooks';
import { navigationService } from '../services/navigationService';
import { Loader } from '../uiKit';
import { PrivateRoutes } from './privateRoutes';
import { PublicRoutes } from './publicRoutes';

function Router() {
    const navigate = useNavigate();

    const { user: { id }, isLoading, isLoggedIn } = useGetAccess();

    useEffect(() => {
        navigationService.setNavigator(navigate);
    }, [navigate]);

    return isLoggedIn ? <PrivateRoutes /> : <PublicRoutes />;
}

export default Router;
