import { FC } from 'react';
import { Control, Controller, FieldError } from 'react-hook-form';
import { TextField } from '@mui/material';

interface IProps {
    name: string;
    control: Control<any>;
    label: string;
    defaultValue?: string;
    placeholder?: string;
}

export const InputTextFieldController: FC<IProps> = ({ name, defaultValue, label, control, placeholder }) => (
    <Controller name={name} control={control} defaultValue={defaultValue}
        render={({ field: { onChange, value }, formState: { errors } }) => (
            <TextField
                label={label}
                onChange={onChange}
                sx={{ width: "100%" }}
                value={value}
                placeholder={placeholder}
                error={!!errors[name]}
                helperText={(errors[name] as FieldError | undefined)?.message || ''}
                margin="dense"
            />
        )}
    />
);