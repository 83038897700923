import React, { FC } from 'react';
import { Button, Stack, Typography } from '@mui/material';

interface IProps {
    onClose?: () => void;
    onSave?: () => void;
    isDisabledButton?: boolean;
}

export const ServiceControls: FC<IProps> = ({ onClose, onSave, isDisabledButton }) => {
    
    return (
        <Stack direction="row" alignItems="center" justifyContent='flex-end' mb={2} mt={2}>
            {isDisabledButton && <Typography mr={3} pt={1.5}>очікуйте, будь ласка, контент завантажується ...</Typography>}
            <Button variant="outlined" onClick={onClose} sx={{ mr: 2 }} disabled={isDisabledButton}>
                Закрити
            </Button>
            <Button variant="outlined" onClick={onSave} disabled={isDisabledButton}>
                Зберегти
            </Button>
        </Stack>
    )
};
