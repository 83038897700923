import { Close as CloseIcon, Sms as SmsIcon } from '@mui/icons-material';
import { Box } from '@mui/material';
import { FC } from 'react';
import { getStyles } from './styles';

interface IProps {
    onToggleWindowMessageClient: () => void;
    isOpenWindowMessageClient: boolean;
}

export const WindowMessageClientButton: FC<IProps> = ({ onToggleWindowMessageClient, isOpenWindowMessageClient }) => {
    const { whatsappButton, windowMessageClient } = getStyles();
    return (
        <Box onClick={onToggleWindowMessageClient} sx={windowMessageClient}><Box sx={whatsappButton}>{isOpenWindowMessageClient ? <CloseIcon /> : <SmsIcon />}</Box></Box>
    );
};


