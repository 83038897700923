import { useState } from "react";
import { useParams } from 'react-router-dom';

export const useCategoryList = () => {
    const { id } = useParams();
    const paramsCategoryId = Number(id);

    const [capturedCategory, setCapturedCategory] = useState<any>(null);
    const [currentCategory, setCurrentCategory] = useState<number[]>([]);

    return { capturedCategory, setCapturedCategory, currentCategory, setCurrentCategory, paramsCategoryId };
};