export const getStyles = () => {
    const styles = {
        actionButton: {
            pt: .5,
            columnGap: "20px",
            justifyContent: "end",
            flexDirection: "row",
        },
    };

    return styles;
};
