export const getStyles = (theme: any) => {
    const styles = {
        verticalLayoutRoot: {
            display: 'flex',
            flex: '1 1 auto',
            maxWidth: '100%',
            minHeight: "calc(100dvh - 72px - 1px)",
            px: '20px',
            pt: '20px',
            [theme.breakpoints.up('lg')]: {
                paddingLeft: 'calc(30% + 20px)',
            }
        },
        verticalLayoutContainer: {
            display: 'flex',
            flex: '1 1 auto',
            // height: "100%",
            position: "relative",
            flexDirection: 'column',
            width: '100%',
        }
    };
    return styles;
};