import { CssBaseline, PaletteMode, ThemeProvider, createTheme } from '@mui/material';
import React, { FC, ReactNode, useContext, useMemo, useState } from 'react';
import { getDesignTokens } from '../theme';
import { SettingsContext } from './settings';

interface IProps {
    children: ReactNode;
}
export const ColorModeContext = React.createContext({
    toggleColorMode: () => { },
});

export const ThemeManager: FC<IProps> = ({ children }) => {
    const { paletteMode } = useContext(SettingsContext);

    const [mode, setMode] = useState<PaletteMode>(paletteMode);

    const colorMode = useMemo(
        () => ({
            toggleColorMode: () => {
                setMode((prevMode) => (prevMode === 'light' ? 'dark' : 'light'));
            },
        }),
        []
    );

    const theme = React.useMemo(() => createTheme(getDesignTokens(mode)), [mode]);

    return (
        <ColorModeContext.Provider value={colorMode}>
            <ThemeProvider theme={theme}>
                <CssBaseline />
                {children}
            </ThemeProvider>
        </ColorModeContext.Provider>
    );
};
