import { Dispatch } from '@reduxjs/toolkit';
import { AnyAction } from 'redux-saga';
import { zooluxCallCenterAPI } from '../../../zooluxCallCenterAPI';
import { ICategory } from '../../repository/ICategory';
import { setTreeCategories } from '../../repository/slice';

const categoryAPI = zooluxCallCenterAPI.injectEndpoints({
    endpoints: (builder) => ({
        getCategoriesTreeAdmin: builder.query<ICategory[], void>({
            query: () => ({
                url: `/admin/category/list-tree`,
                method: 'GET',
            }),
            async onQueryStarted(_, { dispatch, queryFulfilled }: {
                dispatch: Dispatch<AnyAction>,
                queryFulfilled: Promise<{ data: any; }>,
            }) {
                try {
                    const { data } = await queryFulfilled;
                    dispatch(setTreeCategories(data));
                } catch (error) {
                    console.error("Error fetching getCategoriesTreeAdmin:", error);
                }
            },
            providesTags: ["CategoryAdmin"]
        }),
    }),
    overrideExisting: false,
});

export const { useGetCategoriesTreeAdminQuery } = categoryAPI;