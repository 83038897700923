import { zodResolver } from '@hookform/resolvers/zod';
import { useEffect, useRef, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import toast from 'react-hot-toast';
import { useDispatch, useSelector } from 'react-redux';
import { useDebounce } from 'use-debounce';
import { z } from 'zod';
import { config } from '../../../config';
import { selectMessageClient, setMessageClient } from '../../../store/categoryItem/repository/slice';
import { useGetPetsAdminQuery } from '../../../store/client/useCases';
import { useCreateConsultationAdminMutation } from '../../../store/consultation/useCases';

function getMessageClientSchema(iShowAdditionalInfo: boolean) {
    return z.object({
        text: z.string().min(1, { message: 'Не повинно бути пустим' }),
        phone: z.string().min(1, { message: 'Не повинно бути пустим' }),
        client: z.string().optional(),
        pets: iShowAdditionalInfo
            ? z.object({ id: z.number(), value: z.number(), label: z.string().min(1, { message: 'Не повинно бути пустим' }) }).nullable().refine((data) => data !== null, { message: 'Не повинно бути пустим' })
            : z.object({ id: z.number(), value: z.number(), label: z.string() }).optional().nullable()
    });
}

type messageClientFormSchema = z.infer<ReturnType<typeof getMessageClientSchema>>;

interface IProps {
    isOpenWindowMessageClient: boolean;
    onToggleWindowMessageClient: () => void;
}

export const useWindowMessageClient = ({ isOpenWindowMessageClient, onToggleWindowMessageClient }: IProps) => {
    const dispatch = useDispatch();

    const refWindowSmsClient = useRef<HTMLDivElement>(null);

    const [iShowAdditionalInfo, setIShowAdditionalInfo] = useState(false);

    const messageClient: string = useSelector(selectMessageClient);

    const [createConsultationAdmin, { data: dataCreateConsultationAdmin, isLoading: isLoadingCreateConsultationAdmin, isSuccess: isSuccessCreateConsultationAdmin }] = useCreateConsultationAdminMutation();

    const { control, handleSubmit, watch, formState: { errors, isDirty }, reset, setValue, clearErrors } = useForm<messageClientFormSchema>({
        resolver: zodResolver(getMessageClientSchema(iShowAdditionalInfo)),
        defaultValues: {
            text: '',
            phone: '',
            client: '',
            pets: { id: 0, value: 0, label: '' }
        }
    });

    const handleSendMessage: SubmitHandler<messageClientFormSchema> = (data) => {
        const { pets, ...props } = data;

        const payload = {
            pet_id: pets?.id,
            ...props
        };

        createConsultationAdmin(payload);
    };

    useEffect(() => {
        if (isOpenWindowMessageClient) {
            setValue("text", messageClient);
            clearErrors('text');
        }
    }, [messageClient]);

    useEffect(() => {
        if (isSuccessCreateConsultationAdmin) {
            onToggleWindowMessageClient();
            toast.success(`Успішне відправлення на номер телефону${dataCreateConsultationAdmin?.phone}`);
            reset({ text: '', phone: '', client: '', pets: { id: 0, label: '', value: 0 } });
        }
    }, [isSuccessCreateConsultationAdmin]);

    const onChangeShowAdditionalInfo = () => {
        setIShowAdditionalInfo(!iShowAdditionalInfo);
    };

    useEffect(() => {
        dispatch(setMessageClient({ key: "newMessage", message: watch('text') }));
    }, [watch('text')]);

    const [searchPetsClient] = useDebounce(watch('client'), config.smsCustomer.debounceSearchPets);

    const { data: { pets = [] } = {}, isFetching: isFetchingGetPetsAdmin } = useGetPetsAdminQuery({ search: searchPetsClient }, { skip: !isDirty && !!searchPetsClient, });

    return { iShowAdditionalInfo, control, handleSubmit, handleSendMessage, refWindowSmsClient, isFetchingGetPetsAdmin, isLoadingCreateConsultationAdmin, pets, onChangeShowAdditionalInfo };
};
