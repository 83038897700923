import { Autocomplete, Box, Popper, SxProps, TextField } from '@mui/material';
import { FC } from 'react';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { Control, Controller, FieldError } from 'react-hook-form';
import { IParentCategory } from '../../store/category/repository/ICategory';
import { Theme } from '@emotion/react';

interface IProps {
    name: string;
    control: Control<any>;
    options: IParentCategory[];
    label: string;
    handleInputChange?: (value: string) => void;
    defaultValue?: any | undefined;
    loading?: boolean;
    sx?: SxProps<Theme>;
    noOptionsText?: string;
}


export const AutocompleteController: FC<IProps> = ({ name, control, options, label, loading, handleInputChange, defaultValue, sx = [], noOptionsText }) => (
    <Controller
        name={name}
        control={control}
        defaultValue={defaultValue}
        render={({ field: { onChange, value, ref }, formState: { errors } }) => {
            return <Autocomplete
                sx={sx}
                // componentsProps={{ popper: { sx: { zIndex: 98 } } }}
                value={options.find(option => option.value === value?.value) || null}
                getOptionLabel={(option) => option.label || ''}
                isOptionEqualToValue={(option, value) => option?.id === value.id}
                renderOption={(props, option, { selected }) => (
                    <Box component={'li'} {...props} key={option.id} sx={{ overflowWrap: 'anywhere' }}>
                        {option.label}
                    </Box>
                )}
                noOptionsText={noOptionsText}
                clearOnEscape={true}
                loading={loading}
                onInputChange={(e, value) => { handleInputChange && handleInputChange(value); }}
                onChange={(_, data) => onChange(data)}
                options={options}
                renderInput={(params) => <TextField {...params} label={label} inputRef={ref} error={!!errors[name]} helperText={(errors[name] as FieldError | undefined)?.message || (errors[name] as any | undefined)?.label?.message || ''} />}
            />;
        }
        }
    />
);