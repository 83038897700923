export const getStyles = () => {
    const styles = {
        emptyTable: {
            height: 'calc(100vh - 72px - 20px - 66px - 32px - 48px - 16px - 56px - 64px - 1px - 74px)'
        }
    };

    return styles;
};

