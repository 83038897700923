export const getStyles = () => ({
    tableHead: {
        position: "sticky",
        top: "120px",
        zIndex: 1,
        bgcolor: 'background.default'
    },
    firstColumn: {
        width: '40%'
    },
    secondColumn: {
        width: '60%'

    }
});
