export const getStyles = () => {
    const styles = {
        container: {
            rowGap: "8px",
        },
        tableWrapper: {
            height: "calc(100vh - 72px - 20px - 66px - 32px - 48px - 16px - 56px - 64px - 2px)",
            position: 'relative',
            overflowY: "scroll",
            width: "100%"
        },
        table: {
            my: 2
        },
        paginationWrapper: {
            alignItems: 'center',
            height: "64px",
            justifyContent: "center"
        }
    };

    return styles;
};

