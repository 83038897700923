import { FC, ReactNode, createContext } from 'react';
import { useSelector } from 'react-redux';
import { selectAuth } from '../store/auth/repository/selector';

interface IAuthContext {
    isLoggedIn: boolean;
}

export const AuthContext = createContext<IAuthContext | undefined>(undefined);

interface AuthProviderProps {
    children: ReactNode;
}

export const AuthProvider: FC<AuthProviderProps> = ({ children }) => {
    const { isLoggedIn } = useSelector(selectAuth);

    return (
        <AuthContext.Provider value={{ isLoggedIn }}>
            {children}
        </AuthContext.Provider>
    );
};
