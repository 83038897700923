import { Dispatch } from '@reduxjs/toolkit';
import { zooluxCallCenterAPI } from '../../../zooluxCallCenterAPI';
import { AnyAction } from 'redux-saga';

interface IDeleteCategoryItemAdminResponse {
    success: boolean;
}

const categoryItemAPI = zooluxCallCenterAPI.injectEndpoints({
    endpoints: (builder) => ({
        deleteCategoryItemAdmin: builder.mutation<IDeleteCategoryItemAdminResponse, number | null>({
            query: (id) => ({
                url: '/admin/category-manager/' + id,
                method: 'DELETE',
            }),
            async onQueryStarted(_, { dispatch, queryFulfilled }: {
                dispatch: Dispatch<AnyAction>,
                queryFulfilled: Promise<{ data: IDeleteCategoryItemAdminResponse; }>,
            }) {
                try { } catch (error) {
                    console.error("Error fetching deleteCategoryItemAdmin:", error);
                }
            },
            invalidatesTags: ["CategoryItemAdmin"]
        }),
    }),
    overrideExisting: false,
});

export const { useDeleteCategoryItemAdminMutation } = categoryItemAPI;

