import { Box, IconButton, Stack, SvgIcon, Typography } from "@mui/material";
import { FC } from "react";
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import DeleteForeverOutlinedIcon from '@mui/icons-material/DeleteForeverOutlined';
import { IService } from "../../../entity/IService";

interface IProps {
    title?: string;
    item: IService;
    onEdit?: () => void;
    onDelete?: () => void;
};

export const ServicePreviewHeader: FC<IProps> = ({ title, onDelete, onEdit, item }) => {

    return (
        <Stack direction="row" alignItems="center" justifyContent="space-between" height={'20px'} >
            <Typography>{item?.label}</Typography>
            <Box flexDirection='row'>
                <IconButton sx={{ color: 'error.main', mr: 2 }} onClick={onDelete}>
                    <SvgIcon><DeleteForeverOutlinedIcon/></SvgIcon>
                </IconButton>
                <IconButton onClick={onEdit}>
                    <SvgIcon><EditOutlinedIcon/></SvgIcon>
                </IconButton>
            </Box>
        </Stack>
    )
};