import { ICategory } from '../../store/category/repository/ICategory';

interface IBreadcrumb {
    id: number | null;
    name: string | null;
}

export const findBreadCrumbCategory = (categories: ICategory[], targetId: number | undefined, path: IBreadcrumb[] = []): IBreadcrumb[] | null => {
    for (let category of categories) {
        if (category.id === targetId) {
            return [...path, { id: category.id, name: category.name }];
        }
        if (category.children && category.children.length > 0) {
            const newPath = findBreadCrumbCategory(category.children, targetId, [...path, { id: category.id, name: category.name }]);
            if (newPath) {
                return newPath;
            }
        }
    }
    return null;
};
