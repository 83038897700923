import { Dispatch } from '@reduxjs/toolkit';
import { zooluxCallCenterAPI } from '../../../zooluxCallCenterAPI';
import { AnyAction } from 'redux-saga';

interface IDeleteServiceItemAdminRequest {
    entityId: number;
    id: number;
};

interface IDeleteServiceItemAdminResponse {
    success: boolean;
};

const serviceItemAPI = zooluxCallCenterAPI.injectEndpoints({
    endpoints: (builder) => ({
        deleteServiceItemAdmin: builder.mutation<IDeleteServiceItemAdminResponse, IDeleteServiceItemAdminRequest>({
            query: (payload) => ({
                url: `/admin/category-manager/${payload.entityId}/offers/` + payload.id,
                method: 'DELETE',
            }),
            async onQueryStarted(_, { dispatch, queryFulfilled }: {
                dispatch: Dispatch<AnyAction>,
                queryFulfilled: Promise<{ data: IDeleteServiceItemAdminResponse; }>,
            }) {
                try { } catch (error) {
                    console.error("Error fetching deleteServiceItemAdmin:", error);
                }
            },
            invalidatesTags: ["CategoryItemAdmin"]
        }),
    }),
    overrideExisting: false,
});

export const { useDeleteServiceItemAdminMutation } = serviceItemAPI;

