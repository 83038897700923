export const tokens = {
    auth: {
        phone: 'auth.phone',
        password: 'auth.password',
        confirmPass: 'auth.confirmPass',
        enter: 'auth.enter',
        logout: 'auth.logout',
        createAndEnter: 'auth.createAndEnter',
        forgotPass: 'auth.forgotPass',
        checkPhone: 'auth.checkPhone',
        recoverPhone: 'auth.recoverPhone',
        confirmCode: 'auth.confirmCode',
        resend: 'auth.resend',
        smsPassword: 'auth.smsPassword',
        validation: {
            phoneValid: 'auth.validation.phoneValid',
            passValid: 'auth.validation.passValid',
            matchPass: 'auth.validation.matchPass',
        },
        messages: {
            createSafePass: 'auth.messages.createSafePass',
            confirmYourPass: 'auth.messages.confirmYourPass',
            enterSms: 'auth.messages.enterSms',
            not_valid_phone_or_password: 'auth.messages.not_valid_phone_or_password',
            not_found_phone: 'auth.messages.not_found_phone',
            not_valid_data: 'auth.messages.not_valid_data',
            not_valid_code: 'auth.messages.not_valid_code',
        },
    },
    category: {
        create_category: "category.create_category",
        messages: {
            not_valid_data: "category.messages.not_valid_data",
            empty_category_name: "category.messages.empty_category_name"
        }
    }
};
