import { InputAdornment, OutlinedInput, SvgIcon, TextField } from '@mui/material';
import { FC, ReactNode } from 'react';
import { Control, Controller, FieldError } from 'react-hook-form';

interface IProps {
    name: string;
    control: Control<any>;
    label?: string;
    defaultValue?: string;
    placeholder?: string;
    positionIcon?: 'start' | 'end';
    icon: ReactNode;
}

export const InputIconController: FC<IProps> = ({ name, control, defaultValue = "", placeholder, label, positionIcon = 'start', icon }) => {
    return (
        <Controller name={name} control={control} defaultValue={defaultValue}
            render={({ field: { onChange, value }, formState: { errors } }) => (
                <TextField
                    fullWidth
                    value={value}
                    onChange={onChange}
                    placeholder={placeholder}
                    helperText={(errors[name] as FieldError | undefined)?.message || ''}
                    error={!!errors[name]}
                    label={label}
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position={positionIcon}>
                                <SvgIcon>
                                    {icon}
                                </SvgIcon>
                            </InputAdornment>
                        ),
                    }}
                />

            )}
        />
    );
};
