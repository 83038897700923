export const getStyle = () => ({
    algolia: {
        ".ais-HierarchicalMenu > .ais-HierarchicalMenu-list": {
            display: "flex",
            overflowX: "scroll",
            columnGap: "1rem"
        },
        rowGap: "12px",
        ".ais-Panel": {
            mb: "1rem"
        },
        ".ais-Panel-body": {
            "minHeight": "28px",
            display: "flex",
            columnGap: 2,
            overflowX: "scroll"
        },
        ".ais-ClearRefinements": {
            position: "sticky",
            left: 0
        },
        ".ais-Pagination": {
            alignSelf: "center",
            mb: "1rem"
        },
        ".ais-Hits": {
            height: "calc(100vh - 72px - 20px - 66px - 48px - 44px - 48px - 5px)",
            overflowY: "scroll"
        },
        ".ais-Hits-list": {
            display: "flex",
            flexDirection: "column",
            rowGap: "0.5rem"
        },
        ".ais-Hits-item": {
            alignItems: "center",
            background: "#fff",
            boxShadow: "none",
            display: "flex",
            fontSize: "0.875rem",
            "fontWeight": 400,
            lineHeight: "1.25rem",
            padding: "0",
        },
        ".ais-Hits-item > a": {
            padding: "1.5rem",
            width: "100%",
            boxShadow: "0 0 0 1px rgba(35, 38, 59, 0.05), 0 1px 3px 0 rgba(35, 38, 59, 0.15)",
        },
    }
}
);