import { Dispatch } from '@reduxjs/toolkit';
import { zooluxCallCenterAPI } from '../../../zooluxCallCenterAPI';
import { ICategory } from '../../repository/ICategory';
import { AnyAction } from 'redux-saga';

const categoryAPI = zooluxCallCenterAPI.injectEndpoints({
    endpoints: (builder) => ({
        getOneCategoryAdmin: builder.query<ICategory, string | undefined>({
            query: (id) => ({
                url: `/admin/category/one/${id}`,
                method: 'GET',
            }),
            async onQueryStarted(_, { dispatch, queryFulfilled }: {
                dispatch: Dispatch<AnyAction>,
                queryFulfilled: Promise<{}>,
            }) {
                try { } catch (error) {
                    console.error("Error fetching getOneCategoryAdmin:", error);
                }
            },
            providesTags: ['CategoryAdmin']
        }),

    }),
    overrideExisting: false,
});

export const { useGetOneCategoryAdminQuery } = categoryAPI;
