import algoliasearch from 'algoliasearch/lite';
import { Configure, HierarchicalMenu, Hits, InstantSearch, Pagination, ClearRefinements } from 'react-instantsearch-hooks-web';
import { Stack } from '@mui/material';
import 'instantsearch.css/themes/satellite.css';
import { Autocomplete, Hit } from './components';
import { INSTANT_SEARCH_INDEX_NAME } from './constants';
import { Panel } from './widgets/Panel';
import { FC } from 'react';
import { getStyle } from './styles';

interface IProps {
    skipRequestEmptyName?: any;
    handleEmptyState?: (value: string) => void;
    isEmptyStateSearchAlgolia?: string;
    stickyPositionTabs?: number;
    stickyPositionWrapper?: number;
    stickyPositionInput?: number;
}
const searchClient = algoliasearch(process.env.REACT_APP_ALGOLIA_APPLICATION_ID as string, process.env.REACT_APP_ALGOLIA_SEARCH_API_KEY as string);

export const Algolia: FC<IProps> = ({ skipRequestEmptyName, handleEmptyState, isEmptyStateSearchAlgolia, stickyPositionTabs = 0, stickyPositionWrapper = 0, stickyPositionInput = 0 }) => {
    const { algolia } = getStyle();

    return (
        <Stack sx={algolia}>
            <InstantSearch searchClient={searchClient} indexName={INSTANT_SEARCH_INDEX_NAME} routing>
                {isEmptyStateSearchAlgolia && <Panel header="Категорії і підкатегорії" >
                    <ClearRefinements translations={{ resetButtonText: 'Усі' }} />
                    <HierarchicalMenu attributes={['category.name']} />
                </Panel>}
                <Autocomplete skipRequestEmptyName={skipRequestEmptyName} handleEmptyState={handleEmptyState} searchClient={searchClient} placeholder="Search products" detachedMediaQuery="none" openOnFocus />
                {isEmptyStateSearchAlgolia && <>
                    <Configure attributesToSnippet={['name:4', 'description:10']} snippetEllipsisText="…" />
                    <Hits hitComponent={Hit} />
                    <Pagination />
                </>}
            </InstantSearch>
        </Stack>
    );
}

