import { Dispatch } from '@reduxjs/toolkit';
import { AnyAction } from 'redux-saga';
import { zooluxCallCenterAPI } from '../../../zooluxCallCenterAPI';
import { ICategory } from '../../repository/ICategory';

const categoryAPI = zooluxCallCenterAPI.injectEndpoints({
    endpoints: (builder) => ({
        getCategoriesTree: builder.query<ICategory[], void>({
            query: () => ({
                url: `/category/list-tree`,
                method: 'GET',
            }),
            async onQueryStarted(_, { dispatch, queryFulfilled }: {
                dispatch: Dispatch<AnyAction>,
                queryFulfilled: Promise<{}>,
            }) {
                try { } catch (error) {
                    console.error("Error fetching getCategoriesTree:", error);
                }
            },
        }),
    }),
    overrideExisting: false,
});

export const { useGetCategoriesTreeQuery } = categoryAPI;