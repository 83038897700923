const config = {
    categoryItem: {
        perPage: 30,
        debounceSearch: 400
    },
    algoliaSearch: {
        debounceSearch: 2000
    },
    user: {
        perPage: 30,
        debounceSearch: 400
    },
    offer: {
        perPage: 30,
        debounceSearch: 400
    },
    smsCustomer: {
        perPage: 30,
        debounceSearch: 400,
        debounceSearchPets: 400
    },
};

export { config };