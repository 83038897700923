import { ContentCopy as ContentCopyIcon } from '@mui/icons-material';
import { Box, Breadcrumbs, Button, Divider, Link as Links, Stack, Tooltip, Typography } from '@mui/material';
import parse from 'html-react-parser';
import React from 'react';
import { Link } from 'react-router-dom';
import { WindowMessageClient, WindowMessageClientButton } from '../../../../features';
import { useDisclosure } from '../../../../hooks';
import { ActionableHeader, CircularLoading } from '../../../../uiKit';
import { Algolia } from '../../../../features';
import { useCategoryItem } from '../presenters/useCategoryItem';
import { Delete as DeleteIcon, Edit as EditIcon } from '@mui/icons-material';
import { getStyles } from './style';
import { Link as RouterLink } from 'react-router-dom';
import { paths } from '../../../../paths';
import { ConfirmModal } from '../../../../features/modals';
import dayjs from 'dayjs';

export const CategoryItemAdmin = () => {
    const { actionButton } = getStyles();
    const { options, clinicsWithOffer, copyInfoService, isLoadingGetClinics,  handleEmptyState, breadcrumbCategoryItem, isLoadingCategoryItem, onCloseDeleteCategoryItem,
        isFetchingGetOneCategoryItem, isEmptyStateSearchAlgolia, resources, name, id, onOpenDeleteCategoryItemModal, isOpenDeleteCategoryItem, nameCategoryItemRef, 
        handleDeleteCategoryItem, isLoadingDeleteCategoryItemAdmin, categoryId, permissionMap, groupedOffers } = useCategoryItem();

    const isLoading = isLoadingCategoryItem || isLoadingGetClinics || isFetchingGetOneCategoryItem;

    const { isOpen: isOpenWindowMessageClient, onToggle: onToggleWindowMessageClient } = useDisclosure({ defaultIsOpen: false });

    const checkEmpty = <T extends Record<string, any>>(obj: T | null | undefined): boolean => {
        return obj !== null && obj !== undefined && typeof obj === 'object' && Object.keys(obj).length === 0;
    };
    const isEmpty = checkEmpty(groupedOffers);

    return (
        <>
            <Stack direction="row" height="100%" columnGap={2}>
                <Stack width="100%">
                    {isLoading ? null : <Algolia skipRequestEmptyName handleEmptyState={handleEmptyState} stickyPositionWrapper={0} isEmptyStateSearchAlgolia={isEmptyStateSearchAlgolia} />}

                    {isLoading ? <CircularLoading /> :
                        !isEmptyStateSearchAlgolia && <Stack sx={{ img: { maxWidth: "100% !important" }, p: 1, height: "-webkit-fill-available" }}>
                            <Breadcrumbs separator="›" aria-label="breadcrumb">
                                {breadcrumbCategoryItem?.map((breadcrumbItem: any, index: any) => (
                                    <Links key={index} underline="hover" component={Link} to={`/category/${breadcrumbItem?.id}`}>
                                        {breadcrumbItem?.name}
                                    </Links>
                                ))}
                            </Breadcrumbs>
                            <ActionableHeader stickyPosition="73px" title={name} children={
                                permissionMap?.Edit_Category_Item &&
                                <Stack sx={actionButton}>
                                    <Tooltip title="Видалити"><Button onClick={() => onOpenDeleteCategoryItemModal(Number(id), name as string)}><DeleteIcon /></Button></Tooltip>
                                    <Tooltip title="Редагувати"><Button component={RouterLink} to={paths.privateRoutes.documentUpdate.replace(':id', String(id)).replace(":categoryId", String(categoryId))}><EditIcon /></Button></Tooltip>
                                </Stack>
                            } />
                            <Box>{resources?.map(({ description, id }: any) => parse(description, options))}</Box>
                            <Box component="h3" sx={{ my: .5 }}>Вартість основних послуг</Box>
                            {!isEmpty ?
                            Object.entries(groupedOffers).map(([serviceName, clinicOffers], index) => (
                                <React.Fragment key={index}>
                                    <Typography variant="h6" >{serviceName}</Typography>

                                    <Stack direction="row" alignItems="center" textAlign='center'>
                                        <Box component="h4" flexBasis="30%">Клініка</Box>
                                        <Box component="h4" flexBasis="30%">Послуга</Box>
                                        <Box component="h4" flexBasis="20%">Ціна</Box>
                                        <Box component="h4" flexBasis="20%">Ціна у майбутньому</Box>
                                    </Stack>
                                    {clinicOffers.map((clinicWithOffer, clinicIndex) => {
                                        const formattedDate = clinicWithOffer.future_price_date ? dayjs(clinicWithOffer.future_price_date).format('DD.MM.YYYY') : '';

                                        return (
                                            <Box key={clinicIndex} my={2}>
                                                <Stack direction="row" alignItems="center" columnGap={1} textAlign='center'>
                                                    <Tooltip title="Скопіювати">
                                                        <Box onClick={() => copyInfoService(clinicWithOffer)}
                                                            sx={{ p: .5, ":hover": { opacity: .6, cursor: "pointer" } }}>
                                                            <ContentCopyIcon />
                                                        </Box>
                                                    </Tooltip>
                                                    <Box flexBasis="30%">{clinicWithOffer.name || '-'}</Box>
                                                    <Box flexBasis="30%">{clinicWithOffer.name_service}</Box>
                                                    <Box flexBasis="20%">{clinicWithOffer.current_price ? `${clinicWithOffer.current_price} грн` : '-'}</Box>
                                                    <Tooltip title={!clinicWithOffer.future_price && "Ціна без змін"}>
                                                        <Box flexBasis="20%">{clinicWithOffer.future_price ? `з ${formattedDate} - ${clinicWithOffer.future_price} грн` : '-'}</Box>
                                                    </Tooltip>
                                                </Stack>
                                                <Divider sx={{ my: 1 }} />
                                            </Box>
                                        );
                                    })}
                                </React.Fragment>
                            ))
                        : 
                        <Stack alignItems="center" spacing={2}>
                            <Box component="img" src="/images/empty-document.png" width={200} />
                            Список послуг порожній
                        </Stack>}
                        </Stack>
                    }
                </Stack>
                {isOpenDeleteCategoryItem && <ConfirmModal name={nameCategoryItemRef.current} isOpen={isOpenDeleteCategoryItem} onClose={onCloseDeleteCategoryItem} handle={handleDeleteCategoryItem} title={"Видалити документ"} isLoading={isLoadingDeleteCategoryItemAdmin} />}
                <WindowMessageClient isOpenWindowMessageClient={isOpenWindowMessageClient} onToggleWindowMessageClient={onToggleWindowMessageClient} />
                <WindowMessageClientButton isOpenWindowMessageClient={isOpenWindowMessageClient} onToggleWindowMessageClient={onToggleWindowMessageClient} />
            </Stack>
        </>
    );
};

export default CategoryItemAdmin;