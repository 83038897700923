import { useSelector } from 'react-redux';
import { selectAuth } from '../store/auth/repository/selector';
import { selectUser } from '../store/user/repository/slice';

export const useGetAccess = () => {

    const { isLoading, isLoggedIn } = useSelector(selectAuth);
    const user = useSelector(selectUser);

    return { user, isLoading, isLoggedIn };
};
