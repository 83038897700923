import { ChangeEvent, SyntheticEvent, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useDebounce } from 'use-debounce';
import { config } from '../../../../config';
import { useDisclosure, usePermissionContext } from '../../../../hooks';
import { StatusType } from '../../../../store/user/repository/TUser';
import { useArchiveUserAdminMutation } from '../../../../store/user/useCases/archiveUserAdmin/worker';
import { useGetUsersAdminQuery } from '../../../../store/user/useCases/usersAdmin/worker';
import { IPaginationOptions } from './IListUsers';
import { useGetAccessToAccountMutation } from '../../../../store/user/useCases/getAccessToAccount/worker';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { selectUser, setUser } from '../../../../store/user/repository/slice';
import toast from 'react-hot-toast';

export const useListUsers = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [filters, setFilters] = useState<IPaginationOptions>({ page: 1, perPage: config.user.perPage, search: '', filter: '' });
    const [currentPage, setCurrentPage] = useState(filters.page);
    const [selectedIds, setSelectedIds] = useState<number[]>([]);
    const [currentTab, setCurrentTab] = useState<StatusType>(null);
    const user = useSelector(selectUser);

    const { refetchGetProfileUser } = usePermissionContext();
    
    const namesUserRef = useRef<string[]>([]);
    const idsDeleteRef = useRef<{ user_ids: number[] | null; }>({ user_ids: null });

    const { isOpen: isOpenDeleteUserModal, onClose: onCloseDeleteUserModal, onOpen: onOpenDeleteUserModal } = useDisclosure();

    const { data: { rows: users = [], count: usersCount = 0 } = {}, isLoading: isLoadingGetUsersAdmin, isFetching: isFetchingGetUsersAdmin, refetch: refetchGetUsersAdmin } = useGetUsersAdminQuery(filters, { refetchOnMountOrArgChange: true });
    const [getAccessToAccount, {isSuccess: isSuccessGetAccessToAccount, data: dataGetAccessToAccount}] = useGetAccessToAccountMutation();

    const [nameUserSearch, setNameUserSearch] = useState('');
    const [debouncedFilterName] = useDebounce(nameUserSearch, config.user.debounceSearch);

    const [archiveUserAdmin, { isLoading: isLoadingDeleteUserAdmin, isSuccess: isSuccessDeleteUserAdmin }] = useArchiveUserAdminMutation();
    
    useEffect(() => {
        if (isSuccessDeleteUserAdmin) {
            setSelectedIds([]);
            onCloseDeleteUserModal();
        }
    }, [isSuccessDeleteUserAdmin]);
 
    useEffect(() => {
        if (isSuccessGetAccessToAccount) {
            const accessToken = localStorage.getItem('accessToken');
            accessToken && localStorage.setItem('previousAccessToken', accessToken);
            localStorage.setItem('accessToken', dataGetAccessToAccount.token);
            localStorage.setItem('previousUser', JSON.stringify(user));
            
            dispatch(setUser(dataGetAccessToAccount.user));
            toast.success(`Ви зайши під даними ${dataGetAccessToAccount.user.full_name}`);
            navigate('/', { replace: true });

            refetchGetProfileUser();
        }
    }, [isSuccessGetAccessToAccount]);

    useEffect(() => {
        setFilters((prev) => ({ ...prev, search: debouncedFilterName }));
    }, [debouncedFilterName]);

    const handleRefetchUsers = () => {
        refetchGetUsersAdmin();
    };

    const handleChangeNameUser = (e: ChangeEvent<HTMLInputElement>) => {
        setNameUserSearch(e.target.value);
    };

    const handleTabsChange = useCallback((e: SyntheticEvent<Element, Event>, tab: any): void => {
        setCurrentTab(tab);

        setSelectedIds([]);

        if (tab?.is_archive === true) {
            setFilters((prevState) => ({ ...prevState, is_archive: tab?.is_archive }));
        } else {
            setFilters((prevState) => ({ ...prevState, filter: tab, is_archive: '' }));
        }
    }, [filters, selectedIds]);

    const handlePageChange = useCallback((e: ChangeEvent<unknown>, page: number) => {
        setCurrentPage(page);

        setFilters((prevState) => ({ ...prevState, page }));
    }, [filters]);

    const handleSetUserIds = useCallback((id: number[]) => {
        idsDeleteRef.current.user_ids = id;
        onOpenDeleteUserModal();
    }, []);

    const customerIds = useMemo(() => {
        return users.map((user: any) => user.id);
    }, [users]);

    const customerNames = users.filter((user: any) => selectedIds.includes(user.id)).map((user: any) => user.full_name) as string[];

    const handleSetUserNames = (names: string[]) => {
        namesUserRef.current = names;
    };

    useEffect(() => {
        handleSetUserNames(customerNames);
    }, [selectedIds, customerNames]);

    const handleDeleteUserIds = useCallback(() => {
        archiveUserAdmin(idsDeleteRef.current);
    }, [idsDeleteRef.current]);

    const selectAll = useCallback((): void => {
        setSelectedIds([...customerIds]);
    }, [customerIds]);

    const deselectAll = useCallback(() => {
        setSelectedIds([]);
    }, []);

    const handleToggleAll = useCallback((e: ChangeEvent<HTMLInputElement>) => {
        const { checked } = e.target;
        checked ? selectAll() : deselectAll();
    }, [selectAll, deselectAll]);

    const selectOne = useCallback((customerId: number): void => {
        setSelectedIds((prevState) => [...prevState, customerId]);
    }, []);

    const deselectOne = useCallback((customerId: number): void => {
        setSelectedIds((prevState) => (prevState.filter((id) => id !== customerId)));
    }, []);

    const handleToggleOne = useCallback((event: ChangeEvent<HTMLInputElement>, id: number): void => {
        const { checked } = event.target;
        checked ? selectOne(id) : deselectOne(id);
    }, [selectOne, deselectOne]);

    const handleDeleteAll = useCallback(() => {
        onOpenDeleteUserModal();
        archiveUserAdmin({ user_ids: selectedIds });
    }, [selectedIds]);

    const signInToUserAccount = (id: number) => {
        getAccessToAccount({ id });
    };
    
    return {
        users, isLoadingGetUsersAdmin, isFetchingGetUsersAdmin, filters, usersCount, selectedIds, currentPage, handleDeleteAll, handleSetUserIds, handleToggleAll, handleToggleOne, handlePageChange, isOpenDeleteUserModal, onCloseDeleteUserModal,
        handleDeleteUserIds, isLoadingDeleteUserAdmin, currentTab, handleTabsChange, handleChangeNameUser, handleRefetchUsers, customerNames, handleSetUserNames, namesUserRef, signInToUserAccount
    };
};
