import { AnyAction, Dispatch } from '@reduxjs/toolkit';
import { zooluxCallCenterAPI } from '../../../zooluxCallCenterAPI';
import { setUser } from '../../repository/slice';
import { IUser } from '../../repository/IUser';

const userAPI = zooluxCallCenterAPI.injectEndpoints({
    endpoints: (builder) => ({
        getProfileUser: builder.query<IUser, void>({
            query: () => ({
                url: `/user/profile`,
                method: 'GET',
            }),
            async onQueryStarted(_, { dispatch, queryFulfilled }: {
                dispatch: Dispatch<AnyAction>,
                queryFulfilled: Promise<{ data: IUser; }>,
            }) {
                try {
                    const { data } = await queryFulfilled;
                    dispatch(setUser(data));
                } catch (error) {
                    console.error("Error fetching getProfileUser:", error);
                }
            },
        }),

    }),

    overrideExisting: false,
});

export const { useGetProfileUserQuery } = userAPI;