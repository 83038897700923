import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../..';
import { IStatusRequest } from '../../zooluxCallCenterAPI/IZooluxCallCenterAPI';
import { IInitialStateUser, IUser } from './IUser';

const initialUser = {
    id: null,
    phone: '',
    full_name: '',
    position: null,
    avatar: null,
    ref_key: null,
    is_authorized: null,
    is_enote: null,
    fired_date: null,
    roles: [],
    permissions: [],
};

const initialState: IInitialStateUser = {
    user: initialUser,
};

const UserSlice = createSlice({
    name: 'userRepository',
    initialState,
    reducers: {
        setUser: (state, action: PayloadAction<any>) => {
            state.user = action.payload;
        },
        setClearUser: (state) => {
            state.user = initialUser;
        },
    },
});

export const { setClearUser, setUser, } = UserSlice.actions;

export const UserReducer = UserSlice.reducer;

export const selectUser = (state: RootState) => state.userRepository.user as IUser;
