import { Dispatch } from '@reduxjs/toolkit';
import { AnyAction } from 'redux-saga';
import { zooluxCallCenterAPI } from '../../zooluxCallCenterAPI';

export interface IPriceTypeResponse {
    count: number;
    rows: IPriceType[];
};

interface IPriceType {
    id: number;
    ref_key: string;
    name: string;
    name_enote: string;
    is_hidden: boolean;
    createdAt: string;
    updatedAt: string;  
    deleted_at: string | null;  
};

const clinicAPI = zooluxCallCenterAPI.injectEndpoints({
    endpoints: (builder) => ({
        getPriceType: builder.query<IPriceTypeResponse, void>({
            query: () => ({
                url: `/price-type`,
                method: 'GET',
            }),
            async onQueryStarted(_, { dispatch, queryFulfilled }: {
                dispatch: Dispatch<AnyAction>,
                queryFulfilled: Promise<{ data: IPriceTypeResponse; }>,
            }) {
                try { } catch (error) {
                    console.error("Error fetching getPriceType:", error);
                }
            },
        }),
    }),
    overrideExisting: false,
});

export const { useGetPriceTypeQuery } = clinicAPI;