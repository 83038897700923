import { Dispatch } from '@reduxjs/toolkit';
import { AnyAction } from 'redux-saga';
import { zooluxCallCenterAPI } from '../../../zooluxCallCenterAPI';
import { IPermission } from '../../../permission/getPermission/worker';

interface IGetAllRolesResponse {
    name: string,
    id: number;
    permissions?: IPermission[];
};

const userAPI = zooluxCallCenterAPI.injectEndpoints({
    endpoints: (builder) => ({
        getAllRoles: builder.query<any, void>({
            query: () => ({
                url: `/roles`,
                method: 'GET',
            }),
            async onQueryStarted(_, { dispatch, queryFulfilled }: {
                dispatch: Dispatch<AnyAction>,
                queryFulfilled: Promise<{ data: IGetAllRolesResponse; }>,
            }) {
                try { } catch (error) {
                    console.error("Error fetching getUserAdmin:", error);
                }
            },
        }),
    }),
    overrideExisting: false,
});

export const { useGetAllRolesQuery } = userAPI;