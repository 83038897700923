export const getStyles = ({ isOpenWindowMessageClient }: { isOpenWindowMessageClient: boolean; }) => ({
    mainWrapper: {
        width: isOpenWindowMessageClient ? "550px" : "0",
        boxShadow: "0px 0px 2.7px rgba(0, 128, 0, 0.025), 0px 0px 6.9px rgba(0, 128, 0, 0.035), 0px 0px 14.2px rgba(0, 128, 0, 0.045), 0px 0px 29.2px rgba(0, 128, 0, 0.055), 0px 0px 80px rgba(0, 128, 0, 0.08)",
        overflow: "hidden",
        background: "#F5F5F5",
        transition: "width .6s, opacity .4s",
        padding: 12,
        zIndex: 2,
        marginRight: "-18px",
        position: "sticky",
        top: "92px",
        height: "calc(100vh - 93px)",
        overflowY: "scroll",
        opacity: isOpenWindowMessageClient ? '1' : '0'
    },
    form: {
        display: "flex",
        flexDirection: "column",
        rowGap: 1
    },
    button: {
        overflowX: "hidden",
        fontSize: 13,
        textTransform: "none",
        width: "80%",
        whiteSpace: "nowrap"
    }
});
