import toast from 'react-hot-toast';
import { SagaIterator } from 'redux-saga';
import { call, delay, put, takeLatest } from 'redux-saga/effects';
import { IResponse } from '../../../../libs/requester/IResponse';
import { requester } from '../../../../libs/requester/requester-axios';
import { setUser } from '../../../user/repository/slice';
import { setIsLoggedIn, setLoading } from '../../repository/slice';
import i18n from '../../../../locales/i18n';
import { tokens } from '../../../../locales/tokens';

function* SignInWorker({ payload }: any): SagaIterator {
    try {
        const { data, isError, message }: IResponse<any> = yield call(requester.post, '/user-auth/sign-in', payload);
        if (isError) {
            toast.error(i18n.t(tokens.auth.messages[message as keyof typeof tokens.auth.messages]));
        } else {
            yield put(setLoading(true));
            yield put(setIsLoggedIn(true));
            globalThis.localStorage.setItem('isLoggedIn', 'true');
            yield call([localStorage, localStorage.setItem], 'accessToken', data.token);

            yield delay(1600);
            yield put(setUser(data?.user));
        }
    } catch (error) {
        // console.error('SignInWorker: ', error);
    } finally {
        yield put(setLoading(false));
    }
}

export function* SignInWatcher() {
    yield takeLatest('@saga/SIGN_IN', SignInWorker);
}
