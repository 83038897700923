import { FC, useMemo } from "react";
import { Box, useTheme } from "@mui/material";
import { EditorState } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import { getStyles } from "./styles";
import { useTranslation } from "react-i18next";
import { tokens } from "../../locales/tokens";
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

interface IProps {
    editorState: EditorState;
    onEditorStateChange: (newEditorState: EditorState) => void;
};

export const TextRichEditor: FC<IProps> = ({ editorState, onEditorStateChange }) => {
    const { t } = useTranslation();
    const theme = useTheme();
    const isDark = theme.palette.mode === 'dark';
    const styles = useMemo(() => getStyles(isDark), [isDark]);

    const uploadCallback = (file: any, callback: any) => {
        return new Promise((resolve, reject) => {
            const reader = new window.FileReader();
            reader.onloadend = async () => {
                const form_data = new FormData();
                form_data.append("files", file);
                const res = await uploadFile(form_data);

                resolve({ data: { link: res[0].url } });
            };
            reader.readAsDataURL(file);
        });
    };

    const uploadFile = async (formData: FormData) => {
        try {
            const accessToken = globalThis.localStorage.getItem("accessToken");
            const response = await fetch('https://pizzawaydev.pp.ua/api/file/add-files/', {
                method: 'POST',
                body: formData,
                headers: {
                    Authorization: "Bearer " + accessToken,
                }
            });

            if (!response.ok) {
                throw new Error('File upload failed');
            };

            return response.json();
        } catch (error) {
            console.error('Error uploading file:', error);
            throw error;
        }
    };

    const config = {
        image: {
            uploadCallback: uploadCallback,
            alt: { present: true, mandatory: false },
            previewImage: true,
            inputAccept: 'image/gif,image/jpeg,image/jpg,image/png,image/svg',
        },
        options: [
            'inline', 'fontSize', 'fontFamily', 'blockType', 'image', 'colorPicker', 'emoji', 'embedded', 'textAlign', 'list', 'link', 'history', 'remove',
        ],
        inline: { inDropdown: true },
        list: { inDropdown: true },
        link: { inDropdown: true },
    };

    return (
        <Box sx={styles.editorContainer}>
            <Editor
                toolbar={config}
                editorState={editorState}
                editorClassName="demo-editor"
                onEditorStateChange={onEditorStateChange}
                handlePastedText={() => false}
                editorStyle={styles.editor as React.CSSProperties}
                toolbarStyle={styles.toolbar as React.CSSProperties}
                toolbarClassName="rdw-option-active"
            />
        </Box>
    );
};


// import { Box } from '@mui/material';
// import { FC } from 'react';
// import { Editor, EditorState } from 'react-draft-wysiwyg';

// interface IProps {
//     editorState: EditorState;
//     onEditorStateChange: (e: EditorState) => void;
// }

// const uploadFile = async (formData: FormData) => {
//     try {
//         const accessToken = globalThis.localStorage.getItem("accessToken");
//         //@ts-ignore
//         const response = await fetch(process.env.REACT_APP_PUBLIC_URL_API + `/file/upload`, {
//             method: 'POST',
//             body: formData,
//             headers: {
//                 Authorization: "Bearer " + accessToken,
//             }
//         });

//         if (!response.ok) {
//             throw new Error('File upload failed');
//         };

//         return response.json();
//     } catch (error) {
//         // console.error('Error uploading file:', error);
//         throw error;
//     }
// };

// const config = {

//     inline: { inDropdown: true },
//     list: { inDropdown: true },
//     textAlign: { inDropdown: true },
//     link: { inDropdown: true },
//     // history: { inDropdown: true },
//     image: {
//         previewImage: true,
//         urlEnabled: true,
//         uploadCallback: (file: any) => {
//             return new Promise(
//                 (resolve, reject) => {
//                     const reader = new FileReader();
//                     reader.onloadend = async (e: any) => {
//                         const form_data = new FormData();
//                         form_data.append("files", file);
//                         const res = await uploadFile(form_data);

//                         resolve({ data: { link: res[0].path } });
//                     };
//                     reader.onerror = reject;
//                     reader.readAsDataURL(file);
//                 }
//             );
//         },
//         alt: { present: true, mandatory: false },
//     },
// };

// const ImageComponent = (props: any) => {
//     const entity = props.contentState.getEntity(props.block.getEntityAt(0));
//     const { src, alignment, ...otherProps } = entity.getData();
//     return (
//         <Box sx={{ textAlign: alignment || 'left' }}> {/* Устанавливаем выравнивание изображения */}
//             <Box component="img" src={src} {...otherProps} />
//         </Box>
//     );
// };

// export const TextRichEditor: FC<IProps> = ({ editorState, onEditorStateChange }) => {

//     const blockRendererFn = (block: any) => {
//         if (block.getType() === 'atomic') {
//             const contentState = editorState.getCurrentContent();

//             const entity = contentState.getEntity(block.getEntityAt(0));
//             if (entity && entity.getType() === 'IMAGE') {
//                 return {
//                     component: ImageComponent,
//                     editable: false,
//                 };
//             }
//         }

//         return null;
//     };

//     return <Box sx={{
//         ".rdw-editor-main": {
//             background: "background.default",
//             px: 4,
//             minHeight: "300px",
//             border: "1px solid lightgray",
//             zIndex: 0,
//             position: "relative"
//         },
//         ".rdw-editor-toolbar": {
//             position: "sticky",
//             top: "135px",
//             visibility: "inherit !important",
//             zIndex: 98
//         },
//         "figure": {
//             m: 0
//         },
//     }}>
//         <Editor
//             editorState={editorState}
//             toolbar={config}
//             // handlePastedText={() => false}
//             customBlockRenderFunc={blockRendererFn}
//             toolbarClassName="toolbarClassName"
//             wrapperClassName="wrapperClassName"
//             editorClassName="editorClassName"
//             onEditorStateChange={onEditorStateChange}

//         />
//     </Box>;
// };