import { FormControl, InputAdornment, InputLabel, OutlinedInput } from '@mui/material';
import { FC, ReactNode } from 'react';

interface IProps {
    id: string;
    label: string;
    icon: ReactNode;
    value: string | null;
    disabled: boolean;
};

export const InputIcon: FC<IProps> = ({ id, label, icon, value, disabled }) => {
    return (
        <FormControl fullWidth>
            <InputLabel htmlFor={id}>{label}</InputLabel>
            <OutlinedInput
                id={id}
                startAdornment={<InputAdornment position="start">{icon}</InputAdornment>}
                label={label}
                value={value}
                disabled={disabled}
            />
        </FormControl>
    );
};
