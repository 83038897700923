import { Dispatch } from '@reduxjs/toolkit';
import { AnyAction } from 'redux-saga';
import { zooluxCallCenterAPI } from '../../../zooluxCallCenterAPI';
import { IPermission } from '../../../permission/getPermission/worker';

interface IGetUserResponse {
    full_name: string | undefined,
    phone: string | undefined;
    roles: { name: string, id: number; permissions: IPermission[]}[];
};

const userAPI = zooluxCallCenterAPI.injectEndpoints({
    endpoints: (builder) => ({
        getUserAdmin: builder.query<IGetUserResponse, any>({
            query: (id) => ({
                url: `/admin/user/one/${id}`,
                method: 'GET',
            }),
            async onQueryStarted(_, { dispatch, queryFulfilled }: {
                dispatch: Dispatch<AnyAction>,
                queryFulfilled: Promise<{ data: IGetUserResponse; }>,
            }) {
                try { } catch (error) {
                    console.error("Error fetching getUserAdmin:", error);
                }
            },
        }),
    }),
    overrideExisting: false,
});

export const { useGetUserAdminQuery } = userAPI;