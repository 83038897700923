import { Dispatch } from '@reduxjs/toolkit';
import { AnyAction } from 'redux-saga';
import { zooluxCallCenterAPI } from '../../../zooluxCallCenterAPI';

const userAPI = zooluxCallCenterAPI.injectEndpoints({
    endpoints: (builder) => ({
        createUserAdmin: builder.mutation<any, any>({
            query: (body) => {
                return {
                    url: '/admin/user/create',
                    method: 'POST',
                    body
                };
            },
            async onQueryStarted(_, { dispatch, queryFulfilled }: {
                dispatch: Dispatch<AnyAction>,
                queryFulfilled: Promise<{ data: any; }>,
            }) {
                try { } catch (error) {
                    console.error("Error fetching createUserAdmin:", error);
                }
            },
            invalidatesTags: ["UserAdmin"]
        }),
    }),
    overrideExisting: false,
});

export const { useCreateUserAdminMutation } = userAPI;
