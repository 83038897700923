import { Archive as ArchiveIcon, Edit as EditIcon, Unarchive as UnarchiveIcon, PersonSearch as PersonIcon } from '@mui/icons-material';
import { Avatar, Box, Checkbox, IconButton, Link, Stack, SvgIcon, TableCell, TableRow, Tooltip, Typography } from "@mui/material";
import { ChangeEvent, FC, useCallback } from "react";
import { Link as Links } from 'react-router-dom';
import { IUsers } from '../../../../../../store/user/repository/IUser';


interface IProps {
    user: IUsers;
    selectedIds: number[];
    handleSetUserIds: (id: number[]) => void;
    handleToggleOne: (event: ChangeEvent<HTMLInputElement>, id: number) => void;
    handleSetUserNames: any;
    signInToUserAccount: (id: number) => void;
};

export const UsersTableItem: FC<IProps> = ({ user, selectedIds, handleToggleOne, handleSetUserNames, handleSetUserIds, signInToUserAccount }) => {
    const getAccess = useCallback(() => signInToUserAccount(user.id), [user.id]);

    return (
        <TableRow hover key={user?.id} selected={selectedIds.includes(user.id)}>
            <TableCell padding="checkbox" sx={{ width: "5%" }}>
                <Checkbox
                    checked={selectedIds.includes(user.id)}
                    onChange={(e) => {
                        handleToggleOne(e, user?.id);
                        handleSetUserNames([user.full_name]);
                    }}
                    value={selectedIds.includes(user.id)}
                />
            </TableCell>
            <TableCell sx={{ width: '35%' }}>
                <Stack alignItems="center" direction="row" spacing={1}>
                    <Avatar alt="Avatar" src={user?.avatar?.path || ''} sx={{ height: 42, width: 42 }}>
                        {/* {getInitials(customer.name)} */}
                    </Avatar>
                    <Box>
                        <Typography color="inherit" variant="subtitle2" noWrap>
                            {user?.full_name}
                        </Typography>
                        <Typography color="text.secondary" variant="body2">
                            {user?.email}
                        </Typography>
                    </Box>
                </Stack>
            </TableCell>
            <TableCell align="center" sx={{ width: '20%' }}>
                {user?.position || 'Немає позиції'}
            </TableCell>
            <TableCell align="center" sx={{ width: '20%' }}>
                {user?.phone}
            </TableCell>
            <TableCell sx={{ width: '15%' }}>
                <Stack direction='row' justifyContent={"center"}>
                    <Tooltip title="Увійти до акаунту">
                        <IconButton onClick={getAccess} disabled={!!user.deleted_at}>
                            <SvgIcon>
                                <PersonIcon />
                            </SvgIcon>
                        </IconButton>
                    </Tooltip>
                    <Tooltip title="Редагування користувача">
                        <IconButton component={Links} to={`/users/update/${user.id}`} disabled={!!user.deleted_at}>
                            <SvgIcon>
                                <EditIcon />
                            </SvgIcon>
                        </IconButton>
                    </Tooltip>
                    <Tooltip title="Архівування користувача">
                        <IconButton onClick={() => {
                            handleSetUserIds([user.id]);
                            handleSetUserNames([user.full_name]);
                        }}>
                            <SvgIcon>
                                {user?.deleted_at as any ? <UnarchiveIcon /> : <ArchiveIcon />}
                            </SvgIcon>
                        </IconButton>
                    </Tooltip>
                </Stack>
            </TableCell>
        </TableRow>
    );
};

