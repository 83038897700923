import { Add as AddIcon } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import { Stack } from '@mui/material';
import { FC } from 'react';
import { ActionableHeader, AutocompleteController, CheckboxController, CircularLoading, InputTextFieldController } from '../../../../uiKit';
import { TextRichEditor } from '../../../../features/textRichEditor';
import { useUpsertCategoryItemAdmin } from '../presenters/useUpsertCategoryItemAdmin';
import { AutocompleteWithAddController } from '../../../../uiKit/controllerFields/autocompleteWithAddController';
import { UpsertCategoryModalAdmin } from '../../../../features/modals';
import { ServicesView } from '../../serviceView/ui';

interface IProps {
    page: string;
};

const UpsertCategoryItemAdmin: FC<IProps> = ({ page }) => {
    const { handleSubmit, rowsGetOffers, handleServiceInputChange, handleCreateCategoryItemWithResource, isSuccessCreateCategoryItemAdmin, isSuccessUpdateCategoryItemAdmin, isLoadingUpdateCategoryItemAdmin, isLoadingCreateCategoryItemAdmin, isLoadingCategoryItem, control, listCategories,
        isLoadingGetOffers, isFetchingCategoryItem, isFetchingGetOffers, optionsTypes, onEditorStateChange, editorState, isOpenUpdateCategory, onCloseUpdateCategory, onUpdateCategoryModal, categoryValue, setCategoryValue, dataCategoryItemAdmin, idDocumentItem, onRefresh } = useUpsertCategoryItemAdmin();

    const isLoadingButtonCreateOrUpdate = isLoadingCreateCategoryItemAdmin && !isSuccessCreateCategoryItemAdmin || isLoadingUpdateCategoryItemAdmin && !isSuccessUpdateCategoryItemAdmin;
 
    return (
        <>
            <Stack position={"relative"} mt="-20px" height={"100%"} rowGap={1.5} pb={2} component="form" noValidate autoComplete="off" onSubmit={handleSubmit(handleCreateCategoryItemWithResource)}>
                {!isLoadingCategoryItem || !isFetchingCategoryItem || !isLoadingGetOffers ?
                    <>
                        <ActionableHeader title={page === 'create' ? 'Створення документа' : 'Оновлення документу'} children={
                            <LoadingButton startIcon={<AddIcon />} loading={isLoadingButtonCreateOrUpdate} type='submit' variant='outlined'>
                                {page === 'create' ? 'Створити' : 'Оновити'}
                            </LoadingButton>
                        } />
                        <InputTextFieldController name="name" control={control} label="Назва документа" />
                        <AutocompleteWithAddController options={listCategories} name='category_id' control={control} label='Категорія або підкатегорія' onModalOpen={onUpdateCategoryModal} setInputValue={setCategoryValue} />
                        <AutocompleteController options={optionsTypes} name='typeResources' control={control} label={'Тип документу'} />
                        {/* <AutocompleteController loading={isFetchingGetOffers} handleInputChange={handleServiceInputChange} options={rowsGetOffers?.map((dataGetOffer: any) => ({ id: dataGetOffer?.id, value: dataGetOffer?.id, label: dataGetOffer?.name, clinics: dataGetOffer?.clinic }))} name='offer_id' control={control} label='Послуга' /> */}
                        {idDocumentItem && <ServicesView services={dataCategoryItemAdmin?.offers?.map((offer: any) => ({ id: offer?.id, value: offer?.id, label: offer?.name, clinics: offer?.clinics }))} entityId={Number(idDocumentItem)} onUpdate={onRefresh}/>}
                        <CheckboxController control={control} name={'is_hidden'} label={'Опублікований'} />
                        <TextRichEditor editorState={editorState} onEditorStateChange={onEditorStateChange} />
                    </> :
                    <CircularLoading />}
            </Stack>
            {isOpenUpdateCategory && <UpsertCategoryModalAdmin actionKey='create' onCloseUpsertCategory={onCloseUpdateCategory} isOpenUpsertCategory={isOpenUpdateCategory} inputValue={categoryValue} />}
        </>
    );
};

export default UpsertCategoryItemAdmin;