import { Avatar, Box, ButtonBase } from '@mui/material';
import type { FC } from 'react';
import { useRef } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useDisclosure } from '../../hooks';
import { setIsLoggedIn, setLogout } from '../../store/auth/repository/slice';
import { setClearUser } from '../../store/user/repository/slice';
import { zooluxCallCenterAPI } from '../../store/zooluxCallCenterAPI';
import { AccountPopover } from './accountPopover';
import { getStyles } from './styles';

export const AccountButton: FC = () => {
  const styles = getStyles();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { isOpen: isOpenPopover, onOpen: onOpenPopover, onClose: onClosePopover } = useDisclosure();

  const anchorRef = useRef<HTMLButtonElement | null>(null);

  const handleLogout = () => {
    dispatch(zooluxCallCenterAPI.util.resetApiState());
    dispatch(setLogout());
    dispatch(setClearUser());
    globalThis.localStorage.removeItem('accessToken');
    globalThis.localStorage.removeItem('previousUser');
    globalThis.localStorage.removeItem('previousAccessToken');
    globalThis.localStorage.removeItem('isLoggedIn');
    dispatch(setIsLoggedIn(false));
    navigate('/');
  };

  return (
    <>
      <Box component={ButtonBase} onClick={onOpenPopover} ref={anchorRef} sx={styles.account} >
        <Avatar sx={styles.avatar} >
          <img src='/images/logo/logo-zoolux-callcenter.png' alt='account' width={50} height={50} />
        </Avatar>
      </Box>
      <AccountPopover
        anchorEl={anchorRef.current}
        onClose={onClosePopover}
        open={isOpenPopover}
        handleLogout={handleLogout}
      />
    </>
  );
};
