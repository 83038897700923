import { Dispatch } from '@reduxjs/toolkit';
import { AnyAction } from 'redux-saga';
import { zooluxCallCenterAPI } from '../../zooluxCallCenterAPI';
import { deleteEmptyParams } from '../../../utils';

interface IGetOffersResponse {
    rows: IOffer[],
    count: number;
}

export interface IOfferPrices {
    id: 313,
    price_ref_key: string;
    nomenclature_ref_key: string;
    current_price: number;
    future_price: number | null,
    is_hidden: boolean;
};

export interface IOffer {
    id: number,
    name: string;
    ref_key: string;
    is_hidden: boolean;
    prices: IOfferPrices[];
};

const offerAPI = zooluxCallCenterAPI.injectEndpoints({
    endpoints: (builder) => ({
        getOffers: builder.query<IGetOffersResponse, any>({
            query: (params) => ({
                url: `/admin/offer/list`,
                method: 'GET',
                params: deleteEmptyParams(params)
            }),
            async onQueryStarted(_, { dispatch, queryFulfilled }: {
                dispatch: Dispatch<AnyAction>,
                queryFulfilled: Promise<{ data: IGetOffersResponse; }>,
            }) {
                try { } catch (error) {
                    console.error("Error fetching getPermission:", error);
                }
            },
        }),
    }),
    overrideExisting: false,
});

export const { useGetOffersQuery } = offerAPI;