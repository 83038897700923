import { FC } from 'react';
import { LayoutModal } from '../../../uiKit/modal/layoutModal';
import { Box, Divider, Stack, Typography } from '@mui/material';
import dayjs from 'dayjs';

interface IProps {
    isOpen: boolean;
    isLoading?: boolean;
    onClose: () => void;
    handle: () => void;
    title: string;
    content?: any;
}

export const InfoSendSmsModal: FC<IProps> = ({ isOpen, onClose, title, content }) => {

    const bodyInfoSendSmsModal = <Box sx={{ display: 'flex', flexDirection: "column", rowGap: 1 }}>
        <Stack display={"flex"} flexDirection={"row"} justifyContent={'space-between'}>
            <Typography><Box sx={{ fontWeight: 'bold' }}>ФИО працівника</Box> {content?.initiator?.full_name}</Typography>
            <Typography><Box sx={{ fontWeight: 'bold' }}>Номер клієнта</Box> {content?.phone}</Typography>
            <Typography><Box sx={{ fontWeight: 'bold' }}>Дата відправлення</Box> {dayjs(content?.createdAt).format('DD.MM.YYYY')}</Typography>
        </Stack>
        <Divider />
        <Typography sx={{ fontWeight: 'bold' }}>Текст sms</Typography>
        <Typography sx={{ lineBreak: "anywhere", maxHeight: "400px", overflowY: "scroll" }} width={"100%"}>{content?.text}</Typography>
    </Box>;

    return (
        <LayoutModal isOpen={isOpen} onClose={onClose} title={title} content={bodyInfoSendSmsModal} />
    );
};

