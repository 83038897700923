import { Dispatch } from '@reduxjs/toolkit';
import { zooluxCallCenterAPI } from '../../zooluxCallCenterAPI';
import { AnyAction } from 'redux-saga';
import { deleteEmptyParams } from '../../../utils';

interface Initiator {
    id: number;
    full_name: string;
    phone: string;
}

interface IConsultation {
    id: number;
    ref_key: null;
    uuid: string;
    phone: string;
    text: string;
    createdAt: string;
    pet?: any;
    initiator: Initiator;
}

interface IConsultationsResponse {
    count: number;
    rows: IConsultation[];

}

const consultationAPI = zooluxCallCenterAPI.injectEndpoints({
    endpoints: (builder) => ({
        getConsultationAdmin: builder.query<IConsultationsResponse, any>({
            query: (params) => ({
                url: `/admin/consultations`,
                method: 'GET',
                params: deleteEmptyParams(params)
            }),
            async onQueryStarted(_, { dispatch, queryFulfilled }: {
                dispatch: Dispatch<AnyAction>,
                queryFulfilled: Promise<{}>,
            }) {
                try { } catch (error) {
                    console.error("Error fetching consultation: useGetConsultationAdminQuery", error);
                }
            },
        }),
    }),
    overrideExisting: false,
});

export const { useGetConsultationAdminQuery } = consultationAPI;