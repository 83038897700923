import { TextField, Typography } from '@mui/material';
import { ChangeEvent, FC } from 'react';

interface IProps {
  title: string;
  validText: string;
  supportText: string;
  password: string;
  onChangePassword: (event: ChangeEvent<HTMLInputElement>) => void;
  handleEnterPassword: (e: React.KeyboardEvent) => void;
  passwordValid: boolean;
  showPassword: boolean;
  isPhoneInBD: boolean;
}

export const PasswordInput: FC<IProps> = ({
  password,
  handleEnterPassword,
  onChangePassword,
  passwordValid,
  showPassword,
  isPhoneInBD,
  title,
  validText,
  supportText,
}) => {
  return (
    <>
      <TextField
        fullWidth
        label={title}
        name='password'
        onChange={onChangePassword}
        value={password}
        error={!passwordValid}
        helperText={!passwordValid ? validText : ''}
        type={showPassword ? 'text' : 'password'}
        size='medium'
        onKeyDown={handleEnterPassword}
      />
      {isPhoneInBD && passwordValid && (
        <Typography variant='caption' color='GrayText' mt='3px' ml='13px'>
          {supportText}
        </Typography>
      )}
    </>
  );
};
