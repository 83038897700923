
import { FormControl, FormHelperText, SxProps, TextareaAutosize, Theme } from '@mui/material';
import { FC } from 'react';
import { Control, Controller, FieldError } from 'react-hook-form';

interface IProps {
    name: string;
    control: Control<any>;
    label: string;
    defaultValue?: string;
    placeholder?: string;
    sxLabel?: SxProps<Theme>;
}

export const TextareaController: FC<IProps> = ({ name, defaultValue, label, control, placeholder, sxLabel }) => (
    <Controller name={name} control={control} defaultValue={defaultValue}
        render={({ field: { onChange, value, ...fields }, formState: { errors } }) => (
            <FormControl>
                <FormControl sx={sxLabel}>{label}</FormControl>
                <TextareaAutosize  {...fields} style={{ resize: "none", borderColor: !!errors[name] ? 'red' : "inherit" }} onChange={onChange} value={value} minRows={5} maxRows={45} />
                <FormHelperText error={!!errors[name]}>{(errors[name] as FieldError | undefined)?.message || ''}</FormHelperText>
            </FormControl>
        )}
    />
);