import htmlToPdfmake from "html-to-pdfmake";
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";

export const createPdf = async ({ title, content }) => {
    let parseHtml;

    pdfMake.vfs = pdfFonts.pdfMake.vfs;

    const stripFontStyles = (html) => {
        return html.replace(/font-family\s*:\s*[^;]+;|font-weight\s*:\s*[^;]+;/gi, '');
    };

    const strippedDescription = stripFontStyles(content);

    const getImageUrls = (html) => {
        const parser = new DOMParser();
        const doc = parser.parseFromString(html, 'text/html');
        const imageUrls = Array.from(doc.querySelectorAll('img')).map(img => img.src);
        return imageUrls;
    };

    const getImageInfo = (url) => {
        return new Promise((resolve, reject) => {
            const image = new Image();
            image.onload = () => resolve({ width: image.width, height: image.height });
            image.onerror = reject;
            image.src = url;
        });
    };

    const imageUrls = getImageUrls(strippedDescription);

    if (!imageUrls || imageUrls.length === 0) {
        // console.error('Image URLs not found in HTML.');
        parseHtml = strippedDescription;
    } else {
        const fetchAndConvertImages = async (urls) => {
            const promises = urls.map(async (url) => {
                const imageInfo = await getImageInfo(url);
                const imageBase64 = await fetch(url)
                    .then(response => response.blob())
                    .then(blob => new Promise((resolve, reject) => {
                        const reader = new FileReader();
                        reader.onloadend = () => resolve(reader.result.split(',')[1]);
                        reader.onerror = reject;
                        reader.readAsDataURL(blob);
                    }));
                return `data:image/png;base64,${imageBase64}`;
            });
            return await Promise.all(promises);
        };

        const imageBase64s = await fetchAndConvertImages(imageUrls);

        const replaceImageUrlsWithBase64s = (html, urls, base64s) => {
            let modifiedHtml = html;
            urls.forEach((url, index) => {
                modifiedHtml = modifiedHtml.replace(url, base64s[index]);
            });
            return modifiedHtml;
        };

        parseHtml = replaceImageUrlsWithBase64s(strippedDescription, imageUrls, imageBase64s);
    }

    var html = htmlToPdfmake(parseHtml, {
        defaultStyles: {
            // ignoreStyles: true,
            removeExtraBlanks: true,
            imagesByReference: true,
            defaultStyles: {}
        },
    });

    var dd = {
        content: [{ text: title, style: 'header' }, html],
        styles: {
            header: {
                fontSize: 28,
                bold: true,
                alignment: 'center',
                margin: [0, 0, 0, 10]

            },
            p: {
                marginBottom: 0,
            },
            h3: {
                fontSize: 12,
                bold: true,
                marginBottom: 0,
            },
            strong: {
                bold: true,
                fontSize: 12,
            },
        }
    };

    pdfMake.createPdf(dd).download(title);

};
