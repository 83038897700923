import { zodResolver } from '@hookform/resolvers/zod';
import { ContentState, EditorState, convertToRaw } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import { useEffect, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import toast from 'react-hot-toast';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { useDebounce } from 'use-debounce';
import { z } from 'zod';
import { config } from '../../../../config';
import { selectListCategories } from '../../../../store/category/repository/slice';
import { useCreateCategoryItemAdminMutation } from '../../../../store/categoryItem/useCases/createCategoryItemAdmin/worker';
import { useGetOneCategoryItemAdminQuery } from '../../../../store/categoryItem/useCases/getOneCategoryItemAdmin/worker';
import { useUpdateCategoryItemAdminMutation } from '../../../../store/categoryItem/useCases/updateCategoryItemAdmin/worker';
import { useGetOffersQuery } from '../../../../store/offer/getOffersAdmin/workers';
import { optionsTypes } from '../../../../utils';
import { useDisclosure, usePermissionContext } from '../../../../hooks';
import { useGetCategoriesTreeAdminQuery } from '../../../../store/category/useCases/getTreeCategoriesAdmin/worker';
import { paths } from '../../../../paths';

const createCategoryItemWithResourceSchema = z.object({
    name: z.string().trim().min(1, { message: "Не повинно бути пустим" }),
    category_id: z.object({
        id: z.number(), value: z.number(), label: z.string()
    }).nullable().refine(val => val !== null, { message: "Поле категорія обов'язкове" }),
    is_hidden: z.boolean().nullable().optional(),
    descriptionResource: z.string().nullable(),
    typeResources: z.object({ id: z.number(), value: z.string(), label: z.string() }).nullable(),
});

type createUserFormSchema = z.infer<typeof createCategoryItemWithResourceSchema>;

const initialStateFilterOffers = { page: 1, perPage: config.offer.perPage, search: '' };

interface IFiterOffers {
    page: number;
    perPage: number;
    search: string;
};

export const useUpsertCategoryItemAdmin = () => {
    const navigate = useNavigate();
    const { id: idDocumentItem, categoryId } = useParams();
    const [editorState, setEditorState] = useState<any>(() => EditorState.createEmpty());
    const [filterOffers, setFilterOffers] = useState<IFiterOffers>(initialStateFilterOffers);
    const [nameOffer, setNameOffer] = useState<string>('');
    const [searchNameOffer] = useDebounce(nameOffer, config.offer.debounceSearch);
    const [categoryValue, setCategoryValue] = useState('');

    const { data: { rows: rowsGetOffers = [], count = 0 } = {}, isLoading: isLoadingGetOffers, isFetching: isFetchingGetOffers } = useGetOffersQuery(filterOffers);
    const { data: dataCategoryItemAdmin = { name: null, is_hidden: null, category_id: null, resources: [], offers: [] }, isLoading: isLoadingCategoryItem, isFetching: isFetchingCategoryItem, isSuccess: isSuccessCategoryItem, refetch: refetchOneCategoryItem } = useGetOneCategoryItemAdminQuery(idDocumentItem, { skip: idDocumentItem === undefined, refetchOnMountOrArgChange: true });

    const [createCategoryItemAdmin, { isLoading: isLoadingCreateCategoryItemAdmin, isSuccess: isSuccessCreateCategoryItemAdmin, data: categoryItemData }] = useCreateCategoryItemAdminMutation();
    const [updateCategoryItemAdmin, { isLoading: isLoadingUpdateCategoryItemAdmin, isSuccess: isSuccessUpdateCategoryItemAdmin }] = useUpdateCategoryItemAdminMutation();

    const { permissionMap } = usePermissionContext();
    const { refetch: refetchCategoriesTree } = useGetCategoriesTreeAdminQuery(undefined, { skip: !permissionMap?.Read_Category });

    const { isOpen: isOpenUpdateCategory, onClose: onCloseUpdateCategory, onOpen: onOpenUpdateCategory } = useDisclosure();

    const listCategories = useSelector(selectListCategories);

    const { handleSubmit, control, setValue, reset, } = useForm<createUserFormSchema>({
        resolver: zodResolver(createCategoryItemWithResourceSchema),
        values: {
            typeResources: optionsTypes[0],
            name: dataCategoryItemAdmin?.name || '',
            category_id: listCategories.find((item => item.id === dataCategoryItemAdmin?.category_id)) || null as any,
            is_hidden: idDocumentItem ? dataCategoryItemAdmin?.is_hidden !== undefined ? !dataCategoryItemAdmin?.is_hidden : false : true,
            descriptionResource: '',
        },
    });

    useEffect(() => {
        if (categoryId) {
            const category: any = listCategories.find((item) => item.id === parseInt(categoryId));
            if (category) {
                setValue('category_id', category);
            }
        }
    }, [categoryId, listCategories, setValue]);

    useEffect(() => {
        return () => {
            setEditorState(() => EditorState.createEmpty());
            reset();
        };
    }, []);

    useEffect(() => {
        setFilterOffers((prev: IFiterOffers) => ({ ...prev, search: searchNameOffer }));
    }, [searchNameOffer]);

    const handleServiceInputChange = (value: string): void => {
        setNameOffer(value);
    };

    useEffect(() => {
        if (dataCategoryItemAdmin.resources[0]?.description && idDocumentItem) {
            setEditorState(() => {
                const contentBlocks = htmlToDraft(dataCategoryItemAdmin.resources[0]?.description || '');
                const contentState = ContentState.createFromBlockArray(contentBlocks.contentBlocks);
                return EditorState.createWithContent(contentState);
            });
        }
    }, [dataCategoryItemAdmin]);

    useEffect(() => {
        if (isSuccessCreateCategoryItemAdmin) {
            refetchCategoriesTree();
            toast.success("Документ успішно створенно");

            navigate(paths.privateRoutes.documentUpdate.replace(":id", String(categoryItemData?.id)).replace("/:categoryId", ""));
        }
    }, [isSuccessCreateCategoryItemAdmin]);

    useEffect(() => {
        if (isSuccessUpdateCategoryItemAdmin) {
            refetchCategoriesTree();
            toast.success("Документ успішно оновленно");

            !!categoryId ? navigate(paths.privateRoutes.category.replace(':id', categoryId)) : navigate(paths.privateRoutes.documents);
        }
    }, [isSuccessUpdateCategoryItemAdmin]);

    const onEditorStateChange = (e: EditorState) => {
        setEditorState(e);
    };

    const handleCreateCategoryItemWithResource: SubmitHandler<createUserFormSchema> = (data) => {
        const { name, category_id, is_hidden, typeResources, descriptionResource } = data;

        if (idDocumentItem) {
            const payload = {
                category_item_id: +idDocumentItem,
                category_item: {
                    name,
                    category_id: category_id?.value,
                    is_hidden: !is_hidden,
                },
                resource: {
                    type: typeResources?.value,
                    description: descriptionResource,
                    id: dataCategoryItemAdmin.resources[0].id
                }
            };
            updateCategoryItemAdmin(payload);
        } else {
            const payload = {
                category_item: {
                    name,
                    is_hidden: !is_hidden,
                    category_id: category_id?.value,
                },
                resource: {
                    type: typeResources?.value,
                    description: descriptionResource,
                }
            };
            createCategoryItemAdmin(payload);
        }
    };

    useEffect(() => {
        const contentState = editorState.getCurrentContent();
        const htmlContent = draftToHtml(convertToRaw(contentState) as any);
        setValue("descriptionResource", htmlContent);
    }, [editorState]);

    const onUpdateCategoryModal = () => {
        onOpenUpdateCategory();
    };

    const onRefresh = async () => {
        refetchOneCategoryItem();
    };

    return {
        handleSubmit, handleServiceInputChange, rowsGetOffers, handleCreateCategoryItemWithResource, isSuccessCreateCategoryItemAdmin, isSuccessUpdateCategoryItemAdmin, isLoadingUpdateCategoryItemAdmin, isLoadingCreateCategoryItemAdmin, isLoadingCategoryItem,
        isSuccessCategoryItem, control, listCategories, optionsTypes, onEditorStateChange, editorState, isLoadingGetOffers, isFetchingGetOffers, isFetchingCategoryItem, isOpenUpdateCategory,
        onCloseUpdateCategory, onUpdateCategoryModal, categoryValue, setCategoryValue, dataCategoryItemAdmin, idDocumentItem, onRefresh
    };
};
