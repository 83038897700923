import { Dispatch } from '@reduxjs/toolkit';
import { AnyAction } from 'redux-saga';
import { zooluxCallCenterAPI } from '../../../zooluxCallCenterAPI';

const userAPI = zooluxCallCenterAPI.injectEndpoints({
    endpoints: (builder) => ({
        updateUserAdmin: builder.mutation<any, any>({
            query: (params) => {
                return {
                    url: '/admin/user/update',
                    method: 'PUT',
                    body: params
                };
            },
            async onQueryStarted(_, { dispatch, queryFulfilled }: {
                dispatch: Dispatch<AnyAction>,
                queryFulfilled: Promise<{ data: any; }>,
            }) {
                try { } catch (error) {
                    console.error("Error fetching updateUserAdmin:", error);
                }
            },
            invalidatesTags: ["UserAdmin"]
        }),
    }),
    overrideExisting: false,
});

export const { useUpdateUserAdminMutation } = userAPI;