import { IResponse } from './IResponse';
import Axios from 'axios';

class AxiosRequester {
    private getHeaders = (headers: object = {}) => {
        const accessToken = globalThis.localStorage.getItem('accessToken');

        return {
            Authorization: 'Bearer ' + accessToken,
            ...headers,
        };
    };

    post = async (url: string, data?: object, headers?: object, timeoutMS?: number): Promise<any> => {
        try {
            const config = { headers: this.getHeaders(headers) };
            const response = await Axios.post(
                process.env.REACT_APP_PUBLIC_URL_API + url,
                data,
                config
            ).catch((error) => error.response);
            const result = this.processingResponse(response);
            return result;
        } catch (error: any) {
            console.warn('AxiosRequester -> post: ', error);
            return { isError: true, message: error.message };
        }
    };

    put = async (url: string, data?: object, headers?: object, timeoutMS?: number): Promise<any> => {
        try {
            const config = { headers: this.getHeaders(headers) };
            const response = await Axios.put(process.env.REACT_APP_PUBLIC_URL_API + url, data, config).catch((error) => error.response);
            const result = this.processingResponse(response);
            return result;
        } catch (error: any) {
            console.warn('AxiosRequester -> put: ', error);
            return { isError: true, message: error.message };
        }
    };

    delete = async (url: string, data?: object, headers?: object, timeoutMS?: number): Promise<any> => {
        try {
            const config = { headers: this.getHeaders(headers), data };
            const response = await Axios.delete(process.env.REACT_APP_PUBLIC_URL_API + url, config).catch((error) => error.response);
            const result = this.processingResponse(response);
            return result;
        } catch (error: any) {
            console.warn('AxiosRequester -> post: ', error);
            return { isError: true, message: error.message };
        }
    };

    get = async (url: string, params?: object, headers?: object, timeoutMS?: number): Promise<any> => {
        try {
            const config = { headers: this.getHeaders(headers), params };
            const response = await Axios.get(process.env.REACT_APP_PUBLIC_URL_API + url, config).catch((error) => error.response);
            const result = this.processingResponse(response);
            return result;
        } catch (error: any) {
            console.warn('AxiosRequester -> get: ', error);
            return { isError: true, message: error.message };
        }
    };

    private processingResponse = (response: any): IResponse<any> => {
        let result: any = { isError: true, message: '', };

        if (response?.status >= 200 && response?.status < 300) {
            result = { isError: false, data: response.data, message: '' };

            if (response?.data?.message === "not_valid_data") {
                result = {
                    isError: true,
                    message: "Not validate data",
                };
            }
        }
        if (response?.status >= 400 && response?.status < 500) {
            result = {
                isError: true,
                message: response?.data?.message,
            };

        }
        if (response?.status >= 500) {
            result = {
                isError: true,
                message: 'Server error',
            };
        }

        return result;
    };
}

export const requester = new AxiosRequester();
