import { Box, Card, Typography } from "@mui/material";
import { FC } from "react";
import { IService } from "../entity/IService";
import { useServices } from "../presenter/useServices";
import { getStyles } from "./styles";
import { PreviewService } from "./components/previewService";
import { ServiceCreatingView } from "./components/serviceCreatingView";
import { ServiceHeader } from "./components/serviceHeader";

interface IProps {
    entityId: number;
    services: any[] | undefined;
    onUpdate?: () => Promise<void>;
};

export const ServicesView: FC<IProps> = ({ services, entityId = 0, onUpdate }) => {
    const { card } = getStyles();
    const { visible, onOpen, onClose } = useServices();

    return (
        <Box>
            {services?.length ?
                <Card sx={card}>
                    <Typography variant="h5" mb={2} textAlign="start">Послуги</Typography>
                    {services?.map((item) => (<PreviewService length={services?.length} entityId={entityId} onUpdate={onUpdate} key={item.id} item={item} />))}
                </Card> : null
            }
            {visible
                ? <ServiceCreatingView close={onClose} entityId={entityId} onUpdate={onUpdate} />
                : <ServiceHeader title="Послуги" buttonTitle="Створити послугу" onPress={onOpen} />
            }
        </Box>
    )
};