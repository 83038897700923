export const TYPES_RESOURCE_ADMIN = [{ value: "PAGE", label: "Сторінка", id: 0 }] as const;
const optionsTypes = TYPES_RESOURCE_ADMIN.map((item, index) => {
    return {
        value: item.value,
        id: index,
        label: item.label
    };
});

const TYPES_STATUS_CATEGORY_ITEM_ADMIN = [{ value: false, label: "Опублікованний", id: 1 }, { value: true, label: "Не опублікованний", id: 0 }];

export { optionsTypes, TYPES_STATUS_CATEGORY_ITEM_ADMIN };