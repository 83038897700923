import { LoadingButton } from '@mui/lab';
import { Box, Button } from '@mui/material';
import { FC } from 'react';
import { AutocompleteController, CheckboxController, CircularLoading, InputTextFieldController } from '../../../../uiKit';
import { TextConfigs } from '../presenters/Interfaces';
import { useUpsertCategory } from '../presenters/useUpsertCategory';
import { getStyles } from './style';
import { LayoutModal } from '../../../../uiKit/modal/layoutModal';

interface IProps {
    onCloseUpsertCategory: () => void;
    isOpenUpsertCategory: boolean;
    actionKey: keyof TextConfigs;
    categoryId?: string | undefined;
    dataOneCategoryAdmin?: any;
    inputValue?: string;
};

export const UpsertCategoryModalAdmin: FC<IProps> = ({ onCloseUpsertCategory, isOpenUpsertCategory, actionKey, categoryId, dataOneCategoryAdmin, inputValue }) => {
    const { loading, formWrapper } = getStyles();
    const { handleUpsertCategory, handleSubmit, text, control, categories, isFetchingCategoryAdmin, isLoadingCreateCategory, isLoadingUpdateCategoriesTreeAdmin } = useUpsertCategory({ onCloseUpsertCategory, isOpenUpsertCategory, actionKey, categoryId, dataOneCategoryAdmin, inputValue });

    return (
        <LayoutModal handleSubmit={handleSubmit(handleUpsertCategory)} sxContent={{ ...formWrapper, p: isFetchingCategoryAdmin ? 0 : "0 1rem" }} sxActions={{ p: 2 }} isOpen={isOpenUpsertCategory} onClose={onCloseUpsertCategory} title={text[actionKey].title}
            content={
                !isFetchingCategoryAdmin
                    ? <>
                        <InputTextFieldController defaultValue='' name="name" label="Назва категорії*" control={control} />
                        <AutocompleteController name='parent_id' label="Батьківська категорія" options={categories} control={control} />
                        <CheckboxController defaultValue={false} name="is_hidden" label="Сховати категорію" control={control} />
                    </>
                    : <Box sx={loading}><CircularLoading /></Box>
            }
            childrenDialogActions={<>
                <Button variant='text' color="error" onClick={onCloseUpsertCategory}>Відмінити</Button>
                <LoadingButton loading={isLoadingCreateCategory || isLoadingUpdateCategoriesTreeAdmin} type="submit" color="primary">{text[actionKey].textBtn}</LoadingButton>
            </>}
        />
    );
};

