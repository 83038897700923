import toast from 'react-hot-toast';
import { SagaIterator } from 'redux-saga';
import { call, delay, put, takeLatest } from 'redux-saga/effects';
import { IResponse } from '../../../../libs/requester/IResponse';
import { requester } from '../../../../libs/requester/requester-axios';
import { setUser } from '../../../user/repository/slice';
import { setIsLoggedIn, setLoading } from '../../repository/slice';
import { navigationService } from '../../../../services/navigationService';

function* createPasswordWorker({ payload }: any): SagaIterator {
    try {
        yield put(setLoading(true));
        const { data, isError }: IResponse<any> = yield call(requester.post, '/user-auth/create-password', payload);
        if (isError) {
            toast.error('щось пішло не так, спробуйте ще раз');
        } else {
            globalThis.localStorage.setItem('isLoggedIn', 'true');
            yield put(setIsLoggedIn(true));
            navigationService.redirectTo('/');
            yield put(setUser(data?.user));
            yield delay(1600);
        }
    } catch (error) {
        // console.error('createPasswordWorker: ', error);
    } finally {
        yield put(setLoading(false));
    }
}

export function* createPasswordWatcher() {
    yield takeLatest('@saga/CREATE_PASSWORD', createPasswordWorker);
}
