export const getStyles = () => {
    const styles = {
        actionWrapper: {
            flexDirection: { xs: "column", lg: "row" },
            justifyContent: "space-between",
            my: { xs: 7, lg: 1 },
            columnGap: 1.5,
            rowGap: 2,
            height: 50
        },
        button: {
            minWidth: "250px",
            display: "flex",
            columnGap: 1
        },
    };

    return styles;
};

