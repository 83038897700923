type Clinic = {
    id: number;
    ref_key: string;
    name: string;
    name_enote: string;
    is_hidden: boolean;
    price_type_id: number;
    deleted_at: string | null;
    createdAt: string;
    updatedAt: string;
};

type PriceType = {
    id: number;
    name: string;
    clinics: Clinic[];
};

type Price = {
    id: number;
    price_type_ref_key: string;
    nomenclature_ref_key: string;
    current_price: number;
    future_price: number | null;
    future_price_date: string | null;
    is_hidden: boolean;
    price_type: PriceType;
};

type Offer = {
    id: number;
    name: string;
    ref_key: string;
    is_hidden: boolean;
    prices: Price[];
};

export const transformOffers = (offers: Offer[] | undefined): Array<{ name: string; name_service: string; current_price: number; future_price: number | null; future_price_date: string | null; }> => {
    if (!offers || offers.length === 0) {
        return [];
    };

    return offers.flatMap((offer) => {
        if (!offer.prices || offer.prices.length === 0) {
            return [];
        };

        return offer.prices.flatMap((price) => {
            if (!price.price_type.clinics || price.price_type.clinics.length === 0) {
                return [{
                    name: '',
                    name_service: offer.name,
                    current_price: price.current_price,
                    future_price: price.future_price,
                    future_price_date: price.future_price_date,
                }];
            }

            return price.price_type.clinics.map((clinic) => ({
                name: clinic.name,
                name_service: offer.name,
                current_price: price.current_price,
                future_price: price.future_price,
                future_price_date: price.future_price_date,
            }));
        });
    });
};


type ClinicWithOffer = {
    name: string;
    name_service: string;
    current_price: number;
    future_price: number | null;
    future_price_date: string | null;
};

type GroupedOffers = {
    [key: string]: ClinicWithOffer[];
};

export const groupOffersByService = (offers: ClinicWithOffer[]) => {
    return offers.reduce((acc: GroupedOffers, offer: ClinicWithOffer) => {
        if (!acc[offer.name_service]) {
            acc[offer.name_service] = [];
        }
        acc[offer.name_service].push(offer);
        return acc;
    }, {});
};