import { FC, ReactNode, createContext, useCallback, useState } from 'react';
import { Settings, SettingsContextType } from '../types/settings';

const initialSettings: Settings = {
    layout: 'vertical',
    navColor: 'evident',
    paletteMode: 'light',
};

export const SettingsContext = createContext<SettingsContextType>({
    ...initialSettings,
    handleUpdate: () => { },
});

interface IProps {
    children?: ReactNode;
}

export const SettingsProvider: FC<IProps> = ({ children }) => {
    const [state, setState] = useState<Settings>(initialSettings);

    const handleUpdate = useCallback((settings: Settings): void => {
        setState((prevState) => ({
            ...prevState,
            ...settings,
        }));
    }, []);

    return (
        <SettingsContext.Provider value={{ ...state, handleUpdate }}>
            {children}
        </SettingsContext.Provider>
    );
};

export const SettingsConsumer = SettingsContext.Consumer;
