export const getStyles = () => {
    const styles = {
        timer: {
            my: 2,
            position: 'relative',
            display: 'flex',
            justifyContent: 'center',
            textAlign: 'center',
        },
        progress: {
            position: 'absolute',
        },
    };
    return styles;
};
