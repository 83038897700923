export const paths = {
    index: '/',
    privateRoutes:
    {
        category: '/category/:id',
        profile: '/profile',
        users: '/users',
        update_user: '/users/update/:id',
        create_user: '/users/create-user',
        documents: '/documents',
        documentsCreate: '/documents/create/:categoryId?',
        documentUpdate: '/documents/update/:id/:categoryId?',
        documentsOne: '/documents/:id/:categoryId?',
        clinics: '/clinics'
    }
};