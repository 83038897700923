import { Button, TextField, Theme, useMediaQuery } from '@mui/material';
import { ChangeEvent, FC } from 'react';
import { useTranslation } from 'react-i18next';
import { tokens } from '../../../../../../locales/tokens';
import { ProgressTimer } from '../../../../../../uiKit';
import { getStyles } from './styles';

interface IProps {
  smsCode: string;
  timerInSec: number;
  onChangeTimer: () => void;
  resendSmsCode: () => void;
  onChangeSmsCode: (event: ChangeEvent<HTMLInputElement>) => void;
}

export const SmsInput: FC<IProps> = ({
  smsCode,
  onChangeSmsCode,
  timerInSec,
  onChangeTimer,
  resendSmsCode,
}) => {
  const { t } = useTranslation();
  const downMd = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'));
  const styles = getStyles();

  return (
    <>
      <TextField
        autoFocus
        fullWidth
        label={t(tokens.auth.smsPassword)}
        name='temp_pass'
        onChange={onChangeSmsCode}
        value={smsCode}
        helperText={downMd ? ' ' : t(tokens.auth.messages.enterSms)}
        size='medium'
      />
      {timerInSec < 1 && (
        <Button variant='text' sx={styles.noCode} onClick={resendSmsCode}>
          Не приходить пароль?
        </Button>
      )}
      {timerInSec > 0 && <ProgressTimer timerInSec={timerInSec} />}
    </>
  );
};
