import { LoadingButton } from '@mui/lab';
import { Box, Button } from '@mui/material';
import { FC, RefObject } from 'react';
import { LayoutModal } from '../../../uiKit/modal/layoutModal';

interface IProps {
    isOpen: boolean;
    isLoading?: boolean;
    onClose: () => void;
    handle: () => void;
    title: string;
    content?: string;
    name?: string | undefined | null;
    namesRef?: RefObject<string[]>;
}

export const ConfirmModal: FC<IProps> = ({ isOpen, onClose, namesRef, handle, title, content, isLoading, name }) => (
    <LayoutModal isOpen={isOpen} onClose={onClose} title={title} content={content} sxActions={{ p: 2 }}
        childrenTitle={<>
            {name ? <Box color="#487eb0">{name}</Box> : null}
            {namesRef?.current?.map((item: any, i) => (<Box key={i} color="#487eb0">{item}</Box>))}
        </>}
        childrenDialogActions={<>
            <Button variant="text" color={'success'} onClick={onClose}>Ні</Button>
            <LoadingButton color="error" loading={isLoading} onClick={handle}>Так</LoadingButton>
        </>}
    />
);

