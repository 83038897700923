import { Add as AddIcon } from '@mui/icons-material';
import { Button, SvgIcon } from '@mui/material';
import { Link } from 'react-router-dom';

export const CreateButton = () => {
    return (
        <Button component={Link} to={'/documents/create'} variant='outlined' sx={{ display: "flex", columnGap: "12px" }}>
            <SvgIcon><AddIcon /></SvgIcon >
            Створити
        </Button>
    );
};
