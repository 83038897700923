import SearchIcon from '@mui/icons-material/Search';
import { Box, InputAdornment, OutlinedInput, SvgIcon } from "@mui/material";
import { ChangeEvent, FC } from "react";

interface IProps {
    handleChangeSearch: (e: ChangeEvent<HTMLInputElement>) => void;
    placeholder: string;
};

export const SearchInput: FC<IProps> = ({ handleChangeSearch, placeholder }) => (
    <Box component="form" >
        <OutlinedInput
            defaultValue=""
            fullWidth
            onChange={handleChangeSearch}
            placeholder="Пошук користувача"
            startAdornment={(
                <InputAdornment position="start">
                    <SvgIcon>
                        <SearchIcon />
                    </SvgIcon>
                </InputAdornment>
            )}
        />
    </Box>
);
