import { Dispatch } from '@reduxjs/toolkit';
import { zooluxCallCenterAPI } from '../../../zooluxCallCenterAPI';
import { AnyAction } from 'redux-saga';

const categoryAPI = zooluxCallCenterAPI.injectEndpoints({
    endpoints: (builder) => ({
        deleteCategoryAdmin: builder.mutation<any, any>({
            query: (id) => ({
                url: '/admin/category/delete/' + id,
                method: 'DELETE',
            }),
            async onQueryStarted(_, { dispatch, queryFulfilled }: {
                dispatch: Dispatch<AnyAction>,
                queryFulfilled: Promise<{}>,
            }) {
                try { } catch (error) {
                    console.error("Error fetching deleteCategoryAdmin:", error);
                }
            },
            invalidatesTags: ['CategoryAdmin']
        }),

    }),
    overrideExisting: false,
});

export const { useDeleteCategoryAdminMutation } = categoryAPI;

