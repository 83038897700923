import toast from 'react-hot-toast';
import { ICategory } from '../../../../store/category/repository/ICategory';
import { useUpdateCategoryAdminMutation } from '../../../../store/category/useCases/updateCategoryAdmin/worker';
import { useEffect } from 'react';

interface IProps {
    category: ICategory;
    capturedCategory: ICategory;
    setCapturedCategory: (category: ICategory | null) => void;
    treeCategories: ICategory[];
};

export const useDragCategory = ({ category, capturedCategory, setCapturedCategory, treeCategories }: IProps) => {
    const [updateCategoriesTreeAdmin, { isSuccess: isSuccessUpdateCategoriesTreeAdmin }] = useUpdateCategoryAdminMutation();

    useEffect(() => {
        if (isSuccessUpdateCategoriesTreeAdmin) toast.success("Категорію успішно оновленно");
    }, [isSuccessUpdateCategoriesTreeAdmin]);

    const dragStartHandler = (e: React.DragEvent<HTMLDivElement>) => {
        e.stopPropagation();
        if (!capturedCategory) {
            setCapturedCategory(category);
        }
    };

    const dragLeaveHandler = (_e: React.DragEvent<HTMLDivElement>) => {

    };

    const dragEndHandler = (_e: React.DragEvent<HTMLDivElement>) => {

    };

    const dragOverHandler = (e: React.DragEvent<HTMLDivElement>) => {
        e.preventDefault();
    };

    const dragDropHandler = (e: React.DragEvent<HTMLDivElement>) => {
        e.stopPropagation();
        e.preventDefault();

        if (capturedCategory && treeCategories && category.id !== capturedCategory.id) {
            const capturedParentCategory = findParentCategory(treeCategories, category.id, null);
            const parentCategory = findParentCategory(treeCategories, capturedCategory.id, null);
            if (capturedParentCategory?.id === parentCategory?.id) {
                updateCategoriesTreeAdmin({ id: capturedCategory.id, sort_number: category.sort_number });
            }
        }
        setCapturedCategory(null);
    };

    const findParentCategory = (currentCategories: any[], id: number | null, parent: any): any => {
        if (currentCategories) {
            for (let i = 0; i < currentCategories.length; i++) {
                const item = currentCategories[i];
                if (item.id === id) {
                    return parent;
                }
                if (item.children?.length) {
                    const parentCategory = findParentCategory(item.children, id, item);
                    if (parentCategory) {
                        return parentCategory;
                    }
                }
            }
        }
        return null;
    };

    return { dragDropHandler, dragOverHandler, dragEndHandler, dragLeaveHandler, dragStartHandler };

};