import ReactDOM from 'react-dom/client';
import App from './App';
import reportWebVitals from './reportWebVitals';

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

import { Profiler, ProfilerOnRenderCallback } from 'react';

interface Props {
    id: string;
}

const onRenderCallback: ProfilerOnRenderCallback = (
    id,
    phase,
    actualDuration,
    baseDuration,
    startTime,
    commitTime,
    interactions
) => {
   /*  console.log(`${id}'s render phase:`, {
        phase,
        actualDuration: actualDuration / 1000, // Convert to seconds
        baseDuration: baseDuration / 1000, // Convert to seconds
        startTime: startTime / 1000, // Convert to seconds
        commitTime: commitTime / 1000, // Convert to seconds
        interactions,
    }) */;
};

root.render(
    <Profiler id="MyComponentProfiler" onRender={onRenderCallback}>
        <App />
    </Profiler>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
