import CloseIcon from '@mui/icons-material/Close';
import { Box, Breakpoint, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, SxProps, Theme } from '@mui/material';
import { FC, FormEvent, ReactNode } from 'react';
import { getStyles } from './styles';

interface IProps {
    isOpen: boolean;
    childrenTitle?: ReactNode;
    childrenDialogActions?: ReactNode;
    size?: false | Breakpoint | undefined;
    onClose: () => void;
    title: string;
    content?: string | ReactNode;
    handleSubmit?: (e: FormEvent<HTMLFormElement>) => void;
    sxContent?: SxProps<Theme>;
    sxActions?: SxProps<Theme>;
}

export const LayoutModal: FC<IProps> = ({ size = 'sm', isOpen, onClose, title, childrenTitle, content, childrenDialogActions, handleSubmit, sxContent, sxActions }) => {
    const styles = getStyles();

    return (
        <Dialog fullWidth maxWidth={size} open={isOpen} onClose={onClose} >
            <DialogTitle>
                {title}
                {childrenTitle}
            </DialogTitle>
            <IconButton aria-label="close" onClick={onClose} sx={styles.closeButton} >
                <CloseIcon />
            </IconButton>
            {handleSubmit
                ? <Box component="form" noValidate autoComplete="off" onSubmit={handleSubmit} sx={styles.formWrapper}>
                    {content
                        ? <DialogContent sx={sxContent}>{content}</DialogContent>
                        : null}
                    <DialogActions sx={sxActions}>
                        {childrenDialogActions}
                    </DialogActions>
                </Box>
                : <>
                    {content ? <DialogContent sx={sxContent}>{content}</DialogContent> : null}
                    <DialogActions sx={sxActions}>
                        {childrenDialogActions}
                    </DialogActions>
                </>}
        </Dialog>
    );
};

