import { Button } from '@mui/material';
import { FC } from 'react';
import { getStyles } from './styles';

interface IProps {
  title: string;
  isProcessing?: boolean;
  handleButton: () => void;
}

export const ConfirmButton: FC<IProps> = ({
  title,
  isProcessing,
  handleButton,
}) => {
  const styles = getStyles();

  return (
    <Button
      fullWidth
      variant='contained'
      disabled={isProcessing}
      size='large'
      sx={styles.loginBtn}
      onClick={handleButton}>
      {title}
    </Button>
  );
};
