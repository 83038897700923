import { Checkbox, FormControlLabel } from '@mui/material';
import { FC } from 'react';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { Control, Controller } from 'react-hook-form';

interface IProps {
    name: string;
    control: Control<any>;
    label: string;
    defaultValue?: boolean;
}

export const CheckboxController: FC<IProps> = ({ name, defaultValue, label, control }) => (
    <Controller
        name={name}
        control={control}
        defaultValue={defaultValue}
        render={({ field: { onChange, value }, fieldState: { error } }) => <FormControlLabel control={<Checkbox checked={value} onChange={onChange} />} label={label} />}
    />
);
