import { Box, Stack, Typography } from '@mui/material';
import { FC } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { getStyles } from '../styles';

interface IProps {
    to: string;
    label: string;
}

export const MainNavbar: FC<IProps> = ({ to, label }) => {
    const { pathname } = useLocation();
    const isActive = pathname === to;
    const styles = getStyles();

    return (
        <Stack component={Link} to={to} sx={[isActive && styles.activeLink, styles.link, styles.categoryLink]}>
            <Box component={'img'} src={'/images/main-category.svg'} width={19} height={19} />
            <Typography >{label}</Typography>
        </Stack>
    );
};