import { Dispatch } from '@reduxjs/toolkit';
import { AnyAction } from 'redux-saga';
import { zooluxCallCenterAPI } from '../../zooluxCallCenterAPI';

export interface IClinic {
    id: number;
    is_hidden: boolean;
    name: string;
    name_enote: string;
    price_ref_key: string | null;
    ref_key: string;
    price_type: IPriceType | null;
};

export interface IClinicResponse {
    count: number;
    rows: IClinic[];
};

export interface IPriceType{
    id: number;
    name: string;
};

const clinicAPI = zooluxCallCenterAPI.injectEndpoints({
    endpoints: (builder) => ({
        getClinicsAdmin: builder.query<IClinicResponse, void>({
            query: () => ({
                url: `/admin/clinic/list`,
                method: 'GET',
            }),
            async onQueryStarted(_, { dispatch, queryFulfilled }: {
                dispatch: Dispatch<AnyAction>,
                queryFulfilled: Promise<{ data: IClinicResponse; }>,
            }) {
                try { } catch (error) {
                    console.error("Error fetching getClinicsAdmin:", error);
                }
            },
        }),
    }),
    overrideExisting: false,
});

export const { useGetClinicsAdminQuery } = clinicAPI;