import { Search as SearchIcon } from '@mui/icons-material';
import { Box, Pagination, Stack } from '@mui/material';
import { ActionableHeader, InputIconController } from '../../../../uiKit';
import { ConfirmModal } from '../../../../features/modals';
import { useCategoryItemsAdmin } from '../presenters/useCategoryItemsAdmin';
import { CreateButton } from './components/createButton';
import { FiltersCategoryItem } from './components/filters';
import { TableCategoryItem } from './components/table';

const CategoryItemsAdmin = () => {

    const { control, mainCategories, subCategories, handleClearFilterCategoryItem, isLoadingGetCategoryItemsAdmin, rows, onOpenDeleteCategoryItemModal, idCategoryItemRef, nameCategoryItemRef, count, filters, currentPage, handlePageChange, handleToggleIsHiddenCategoryItem, isOpenDeleteCategoryItem,
        onCloseDeleteCategoryItem, handleDeleteCategoryItem, isLoadingUpdateCategoryItemAdmin, isLoadingDeleteCategoryItemAdmin } = useCategoryItemsAdmin();

    return (
        <>
            <Stack rowGap={2} >
                <ActionableHeader title="Список документів" children={
                    rows?.length === 0
                        ? null
                        : <CreateButton />
                } />
                <InputIconController name="search" control={control} icon={<SearchIcon />} placeholder='Пошук документів' />
                <FiltersCategoryItem mainCategories={mainCategories} subCategories={subCategories} control={control} handleClearFilter={handleClearFilterCategoryItem} />
                {rows?.length === 0 && !isLoadingGetCategoryItemsAdmin
                    ? <Stack justifyContent={"center"} alignItems={"center"} sx={{ height: "100%" }} >
                        <Stack maxWidth={"500px"} sx={{ display: "flex", alignItems: "center" }}>
                            <Box sx={{ width: "200px" }} component={"img"} src='/images/empty-document.png' ></Box>
                            <Box m={0} component={"h2"}>Список документів порожній</Box>
                            <Box textAlign={"center"} component={"p"}>Тут буде відображатись список документів, але зараз він порожній, щоб створити документ натисніть кнопку</Box>
                            <CreateButton />
                        </Stack>
                    </Stack >
                    : <TableCategoryItem isLoading={isLoadingGetCategoryItemsAdmin} rows={rows} onOpenDeleteCategoryItemModal={onOpenDeleteCategoryItemModal} handleToggleIsHiddenCategoryItem={handleToggleIsHiddenCategoryItem}
                        isLoadingUpdateCategoryItemAdmin={isLoadingUpdateCategoryItemAdmin} idUpdateCategoryItem={idCategoryItemRef.current} />}
            </Stack>
            <Stack sx={{ height: "64px", alignSelf: "center", justifyContent: "center" }}>
                {count > filters.perPage
                    ? <Pagination
                        count={Math.ceil(count / filters.perPage)}
                        page={currentPage}
                        color="primary"
                        onChange={handlePageChange} />
                    : null}
            </Stack>
            {isOpenDeleteCategoryItem && <ConfirmModal name={nameCategoryItemRef.current} isOpen={isOpenDeleteCategoryItem} onClose={onCloseDeleteCategoryItem} handle={handleDeleteCategoryItem} title={"Видалити документ"} isLoading={isLoadingDeleteCategoryItemAdmin} />}
        </>
    );
};

export default CategoryItemsAdmin;