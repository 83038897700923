import { CalendarToday as CalendarTodayIcon, FilterAltOff as FilterAltOffIcon } from '@mui/icons-material';
import { Button, Stack, Tooltip } from '@mui/material';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { FC } from 'react';
import { Control, Controller } from 'react-hook-form';
import { useDisclosure } from '../../../../../hooks';
import { IParentCategory } from '../../../../../store/category/repository/ICategory';
import { AutocompleteController } from '../../../../../uiKit';
import { TYPES_STATUS_CATEGORY_ITEM_ADMIN, optionsTypes } from '../../../../../utils';

interface IProps {
    mainCategories: IParentCategory[];
    subCategories: IParentCategory[];
    control: Control<any>;
    handleClearFilter: () => void;
}

export const FiltersCategoryItem: FC<IProps> = ({ mainCategories, subCategories, control, handleClearFilter }) => {

    const { onOpen: onOpenDataPicker, onClose: onCloseDataPicker, isOpen: isOpenDataPicker } = useDisclosure();

    return (
        <Stack direction={'row'} columnGap={"12px"} alignItems={"center"}>
            <AutocompleteController name='filter_category_id' control={control} options={mainCategories} label='Категорія' sx={{ flexGrow: 2, flexBasis: "20%" }} />
            <AutocompleteController name='filter_category_id' control={control} options={subCategories} label='Підкатегорії' sx={{ flexGrow: 2, flexBasis: "20%" }} />
            <AutocompleteController name='filter_type' control={control} options={optionsTypes} label='Тип' sx={{ flexGrow: 1, flexBasis: "10%" }} />
            <AutocompleteController name='filter_hidden' control={control} options={TYPES_STATUS_CATEGORY_ITEM_ADMIN} label='Статус' sx={{ flexGrow: 1.6, flexBasis: "10%" }} />
            <Stack sx={{ minWidth: "64px", height: "100%", alignItems: "center" }}>
                <Tooltip title="Дата">
                    <Button onClick={onOpenDataPicker} sx={{ mr: "-12px", width: "fit-content", height: "100%" }}><CalendarTodayIcon /></Button>
                </Tooltip>
            </Stack>
            <Controller
                name={'filter_date'}
                control={control}
                render={({ field: { ref, onChange, value } }) => (
                    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={'uk'}>
                        <DatePicker open={isOpenDataPicker} onClose={onCloseDataPicker} onOpen={onOpenDataPicker} orientation='landscape' inputRef={ref} sx={{ visibility: 'hidden', width: 0, }}
                            value={value} label="Дата" onChange={(e) => onChange(e)}
                        />
                    </LocalizationProvider>
                )}
            />
            <Tooltip title="Скасувати фільтри">
                <Button onClick={() => handleClearFilter()} sx={{ height: 56 }}><FilterAltOffIcon /></Button>
            </Tooltip>
        </Stack>
    );

}


