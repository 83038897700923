import { SagaIterator } from 'redux-saga';
import { call, put, takeLatest } from 'redux-saga/effects';
import { IResponse } from '../../../../libs/requester/IResponse';
import { requester } from '../../../../libs/requester/requester-axios';
import { setConfirmedSms } from '../../repository/slice';
import toast from 'react-hot-toast';
import i18n from '../../../../locales/i18n';
import { tokens } from '../../../../locales/tokens';

function* confirmSmsCodeWorker({ payload }: any): SagaIterator {
    try {
        const { data, isError, message }: IResponse<any> = yield call(requester.post, '/user-auth/confirm-code', payload);
        if (isError) {
            toast.error(i18n.t(tokens.auth.messages[message as keyof typeof tokens.auth.messages]));
        } else {
            yield call([localStorage, localStorage.setItem], 'accessToken', data.token);
            yield put(setConfirmedSms(true));
        }
    } catch (error) {
        // console.error('confirmSmsCodeWorker: ', error);
    } finally {
    }
}

export function* confirmSmsCodeWatcher() {
    yield takeLatest('@saga/CONFIRM_SMSCODE', confirmSmsCodeWorker);
}
