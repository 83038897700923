import { Dispatch } from '@reduxjs/toolkit';
import { zooluxCallCenterAPI } from '../../../zooluxCallCenterAPI';
import { AnyAction } from 'redux-saga';
import { IOneCategoryItemAdminResponse } from '../createCategoryItemAdmin/worker';


interface IUpdateCategoryItemAdminRequest {
    category_item: {
        name?: string;
        category_id?: number | undefined;
        is_hidden: boolean;
        offer_id?: number | null | undefined;
    };
    resource?: {
        type?: string | undefined;
        description?: string | null;
    };
}

const categoryItemAPI = zooluxCallCenterAPI.injectEndpoints({
    endpoints: (builder) => ({
        updateCategoryItemAdmin: builder.mutation<IOneCategoryItemAdminResponse, IUpdateCategoryItemAdminRequest>({
            query: (body) => ({
                url: '/admin/category-manager',
                method: 'PATCH',
                body
            }),
            async onQueryStarted(_, { dispatch, queryFulfilled }: {
                dispatch: Dispatch<AnyAction>,
                queryFulfilled: Promise<{ data: IOneCategoryItemAdminResponse; }>,
            }) {
                try { } catch (error) {
                    console.error("Error fetching updateCategoryItemAdmin:", error);
                }
            },
            invalidatesTags: ["CategoryItemAdmin"]
        }),
    }),
    overrideExisting: false,
});

export const { useUpdateCategoryItemAdminMutation } = categoryItemAPI;

