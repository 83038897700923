import { Dispatch } from '@reduxjs/toolkit';
import { zooluxCallCenterAPI } from '../../../zooluxCallCenterAPI';
import { AnyAction } from 'redux-saga';

const categoryAPI = zooluxCallCenterAPI.injectEndpoints({
    endpoints: (builder) => ({
        createCategoryAdmin: builder.mutation<void, any>({
            query: (params) => ({
                url: `/admin/category/create`,
                method: 'POST',
                body: params
            }),
            async onQueryStarted(_, { dispatch, queryFulfilled }: {
                dispatch: Dispatch<AnyAction>,
                queryFulfilled: Promise<{}>,
            }) {
                try { } catch (error) {
                    console.error("Error fetching createCategoryAdmin:", error);
                }
            },
            invalidatesTags: ['CategoryAdmin']
        }),

    }),
    overrideExisting: false,
});

export const { useCreateCategoryAdminMutation } = categoryAPI;
