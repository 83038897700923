import { Dispatch } from '@reduxjs/toolkit';
import { AnyAction } from 'redux-saga';
import { zooluxCallCenterAPI } from '../../zooluxCallCenterAPI';
import { IClinicResponse } from '../cliniksAdmin/workers';

const clinicAPI = zooluxCallCenterAPI.injectEndpoints({
    endpoints: (builder) => ({
        getClinics: builder.query<IClinicResponse, void>({
            query: () => ({
                url: `/clinic/list`,
                method: 'GET',
            }),
            async onQueryStarted(_, { dispatch, queryFulfilled }: {
                dispatch: Dispatch<AnyAction>,
                queryFulfilled: Promise<{ data: IClinicResponse; }>,
            }) {
                try { } catch (error) {
                    console.error("Error fetching getClinics:", error);
                }
            },
        }),
    }),
    overrideExisting: false,
});

export const { useGetClinicsQuery } = clinicAPI;