import dayjs from 'dayjs';
import { ChangeEvent, useCallback, useEffect, useRef, useState } from 'react';
import { Range } from 'react-date-range';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import { useDebounce } from 'use-debounce';
import { config } from '../../../config';
import { useDisclosure } from '../../../hooks';
import { useGetConsultationAdminQuery } from '../../../store/consultation/useCases';

interface IFiterSmsCustomer {
    page: number;
    perPage: number;
    search: string;
    startDate: any;
    endDate: any;
}

const initialStateSmsCustomer = { page: 1, perPage: config.smsCustomer.perPage, search: '', startDate: undefined, endDate: undefined };

export const useSmsOperators = () => {
    const refConsultationId = useRef<number | null>(null);

    const [rangeDate, setRangeDate] = useState<Range[]>([{ startDate: initialStateSmsCustomer?.startDate, endDate: initialStateSmsCustomer?.endDate, key: 'selection' }]);
    const [filterSmsCustomer, setFilterSmsCustomer] = useState<IFiterSmsCustomer>(initialStateSmsCustomer);
    const [filterName, setFilterName] = useState('');

    const { isOpen: isOpenRangeDataPicker, onToggle: onToggleRangeDataPicker } = useDisclosure();
    const { isOpen: isOpenInfoSendSmsModal, onClose: onCloseInfoSendSmsModal, onOpen: onOpenInfoSendSmsModal } = useDisclosure();

    const [debouncedFilterName] = useDebounce(filterName, config.smsCustomer.debounceSearch);

    const { data: { rows: rowsGetConsultationAdmin = [], count: countGetConsultationAdmin = 0 } = {}, isFetching: isFetchingGetConsultationAdmin } = useGetConsultationAdminQuery(filterSmsCustomer);

    useEffect(() => {
        setFilterSmsCustomer((prev) => ({ ...prev, search: debouncedFilterName }));
    }, [debouncedFilterName]);

    useEffect(() => {
        if (rangeDate[0].startDate !== undefined) {
            setFilterSmsCustomer((prev) => ({
                ...prev,
                startDate: dayjs(rangeDate[0].startDate).valueOf() + dayjs(rangeDate[0].startDate).utcOffset() * 1000 * 60 || null,
                endDate: dayjs(rangeDate[0].endDate).valueOf() + dayjs(rangeDate[0].endDate).utcOffset() * 1000 * 60 || null
            }));
        }
    }, [rangeDate]);

    const handleChangeSearch = (e: ChangeEvent<HTMLInputElement>): void => {
        setFilterName(e.target.value);
    };

    const handleOpenInfoSmsModal = (id: number) => {
        refConsultationId.current = id;
        onOpenInfoSendSmsModal();
    };

    const clearFilters = useCallback(() => {
        setRangeDate([{ startDate: undefined, endDate: undefined, key: 'selection' }]);
        setFilterSmsCustomer(initialStateSmsCustomer);
        setFilterName('');
    }, []);

    const handlePageChange = useCallback((e: ChangeEvent<unknown>, page: number) => {
        setFilterSmsCustomer((prevState) => ({ ...prevState, page }));
    }, [filterSmsCustomer]);

    return {
        handleChangeSearch, filterName, onToggleRangeDataPicker, isOpenRangeDataPicker, setRangeDate, rangeDate, clearFilters, isFetchingGetConsultationAdmin, rowsGetConsultationAdmin,
        countGetConsultationAdmin, filterSmsCustomer, handlePageChange, handleOpenInfoSmsModal, isOpenInfoSendSmsModal, refConsultationId, onCloseInfoSendSmsModal
    };
};
