import { all, fork } from 'redux-saga/effects';
import { confirmSmsCodeWatcher } from './auth/useCases/confirmSmsCode/worker';
import { createPasswordWatcher } from './auth/useCases/createPassword/worker';
import { recoverPasswordWatcher } from './auth/useCases/recoverPassword/worker';
import { SignInWatcher } from './auth/useCases/signIn/worker';
import { verifyPhoneWatcher } from './auth/useCases/verifyPhone/worker';

export function* rootSaga() {
  yield all([
    fork(verifyPhoneWatcher),
    fork(createPasswordWatcher),
    fork(SignInWatcher),
    fork(confirmSmsCodeWatcher),
    fork(recoverPasswordWatcher),
  ]);
}
