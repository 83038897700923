import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../..';
import { IInitialStateCategory } from './ICategory';

const initialState: IInitialStateCategory = {
  listCategories: [],
  treeCategories: []
};

const CategorySlice = createSlice({
  name: 'categoryRepository',
  initialState,
  reducers: {
    setListCategories: (state, action: PayloadAction<any>) => {
      state.listCategories = action.payload;
    },
    setTreeCategories: (state, action: PayloadAction<any>) => {
      state.treeCategories = action.payload;
    },
  },
});

export const { setListCategories, setTreeCategories } = CategorySlice.actions;

export const CategoryReducer = CategorySlice.reducer;

export const selectListCategories = (state: RootState) => state.categoryRepository.listCategories;
export const selectTreeCategories = (state: RootState) => state.categoryRepository.treeCategories;




