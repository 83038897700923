import { Dispatch } from '@reduxjs/toolkit';
import { AnyAction } from 'redux-saga';
import { zooluxCallCenterAPI } from '../../zooluxCallCenterAPI';

interface UpdateClinicResponse {
    clinic_id: number,
    name: string,
    ref_key: string,
    price_ref_key: string;
};

export interface IUpdateClinicBody {
    clinic_id: number;
    price_ref_key?: string | null;
    price_type_id?: number;
};

const clinicAPI = zooluxCallCenterAPI.injectEndpoints({
    endpoints: (builder) => ({
        updateClinicAdmin: builder.mutation<UpdateClinicResponse, IUpdateClinicBody>({
            query: ({ clinic_id, price_type_id }) => ({
                url: `/admin/clinic/update`,
                method: 'PUT',
                body: {
                    clinic_id,
                    price_type_id
                }
            }),
            async onQueryStarted(_, { dispatch, queryFulfilled }: {
                dispatch: Dispatch<AnyAction>,
                queryFulfilled: Promise<{ data: UpdateClinicResponse; }>,
            }) {
                try { } catch (error) {
                    console.error("Error fetching updateClinicsAdmin:", error);
                }
            },
        }),
    }),
    overrideExisting: false,
});

export const { useUpdateClinicAdminMutation } = clinicAPI;
