import { FC } from "react";
import { IService } from "../../../entity/IService";
import { ServiceControls } from "../serviceControls";
import { ServicePreviewHeader } from "../servicePreviewHeader";
import { useServiceItem } from "../../../presenter/useServiceItem";
import { Box, Divider, Typography } from "@mui/material";
import { ServiceEditingView } from "../serviceEditingView";

interface IProps {
    item: IService;
    length?: number;
    entityId: number;
    onUpdate?: () => Promise<void>;
};

export const PreviewService: FC<IProps> = ({ item, entityId, onUpdate, length }) => {
    const { edit, onEditOpen, onEditClose, onDelete } = useServiceItem(item, entityId, onUpdate);
    
    return (
        <Box sx={{ width: '100%' }} >
            <ServicePreviewHeader onDelete={onDelete} onEdit={onEditOpen} item={item}/>
            {edit && entityId ? <ServiceEditingView item={item} close={onEditClose} entityId={entityId} onUpdate={onUpdate}/> : null}
            <Divider variant="fullWidth" sx={{ my: 2, borderBottomWidth: 3 }} />
        </Box>
    )
};