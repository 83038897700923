import { Box, CircularProgress, Typography } from '@mui/material';
import { FC } from 'react';
import { getStyles } from './styles';

interface IProps {
    timerInSec: number;
}

export const ProgressTimer: FC<IProps> = ({ timerInSec }) => {
    const styles = getStyles();

    return (
        <Box sx={styles.timer}>
            <CircularProgress sx={styles.progress} />
            <Typography variant="body1" mt={1}>
                {timerInSec}
            </Typography>
        </Box>
    );
};
