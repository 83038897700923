import React, { FC } from 'react';
import { Button, Stack, Typography } from '@mui/material';

interface IProps {
    isDisabledButton?: boolean;
    title?: string;
    view?: string;
    buttonTitle?: string;
    onPress?: () => void;
};

export const ServiceHeader: FC<IProps> = ({ title, buttonTitle, onPress, view, isDisabledButton }) => {
    return (
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={3}>
            <Typography variant="h5" gutterBottom>{/* {title} */}</Typography>
            {view !== 'categoryList' && onPress ? 
            <Button variant="outlined" onClick={onPress} disabled={isDisabledButton}>
                {buttonTitle}
            </Button> : null}
        </Stack>
    )
};
