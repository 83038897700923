import { Stack } from "@mui/material";
import { FC } from "react";
import { usePermissionContext } from '../../../../../hooks';
import { useGetCategoriesAdminQuery } from '../../../../../store/category/useCases/categoriesAdmin/worker';
import { useGetCategoriesTreeQuery } from '../../../../../store/category/useCases/getTreeCategories/worker';
import { useGetCategoriesTreeAdminQuery } from '../../../../../store/category/useCases/getTreeCategoriesAdmin/worker';
import { CircularLoading } from '../../../../../uiKit';
import { useCategoryList } from "../../presenters/useCategoryList";
import { CategoriesItem } from "../categoriesItem";

export const Categories: FC = () => {
    const { capturedCategory, setCapturedCategory, currentCategory, setCurrentCategory, paramsCategoryId } = useCategoryList();
    const { permissionMap } = usePermissionContext(); 

    const { data: treeCategoriesAdmin, isLoading: isLoadingCategoriesTreeAdmin } = useGetCategoriesTreeAdminQuery(undefined, { skip: !permissionMap?.Read_Category });
    const { data: treeCategories, isLoading: isLoadingCategoriesTree } = useGetCategoriesTreeQuery(undefined, { skip: permissionMap?.Read_Category });

    useGetCategoriesAdminQuery(undefined, { skip: !permissionMap?.Edit_Category });

    const activeTreeCategories = treeCategoriesAdmin || treeCategories;

    if (isLoadingCategoriesTreeAdmin || isLoadingCategoriesTree) return <CircularLoading />; 

    return (
        <Stack>
            {activeTreeCategories?.map((category) => (
                <CategoriesItem
                    treeCategories={activeTreeCategories}
                    currentCategory={currentCategory}
                    setCurrentCategory={setCurrentCategory}
                    category={category}
                    paddingLeft={0}
                    key={category.id}
                    paramsCategoryId={paramsCategoryId}
                    capturedCategory={capturedCategory}
                    setCapturedCategory={setCapturedCategory}
                />
            ))}
        </Stack>
    );
};