
import { FC } from 'react';
import { Control, FieldValues, Controller, FieldError } from 'react-hook-form';
import { TextField } from '@mui/material';
import { MuiTelInput } from 'mui-tel-input';

interface IProps {
    name: string;
    control: Control<any>;
    label?: string;
    defaultValue?: string;
    placeholder?: string;
}

export const MuiTelInputController: FC<IProps> = ({ name, defaultValue, label, control, placeholder }) => (
    <Controller
        name={name}
        control={control}
        render={({ field: { ref: fieldRef, value, ...fieldProps }, formState: { errors } }) => (
            <MuiTelInput
                {...fieldProps}
                value={value ?? ''}
                sx={{ width: "100%" }}
                inputRef={fieldRef}
                forceCallingCode
                label="Номер телефону"
                onlyCountries={["UA"]}
                defaultCountry='UA'
                helperText={(errors[name] as FieldError | undefined)?.message || ''}
                error={!!errors[name]}
            />
        )}
    />
);