import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDeleteServiceItemAdminMutation } from "../../../../store/service/useCases/deleteServiceItemAdmin/worker";

export const useServiceItem = (item: any, entityId?: number, onUpdate?: () => Promise<void>) => {
    const [edit, setEdit] = useState(false);
    const navigate = useNavigate();

    const [deleteServiceItemAdmin, { isLoading: isLoadingDeleteServiceItemAdmin, isSuccess: isSuccessDeleteServiceItemAdmin }] = useDeleteServiceItemAdminMutation();

    const onEditOpen = () => {
        setEdit(!edit);
    };

    const onEditClose = () => {
        setEdit(false);
    };

    const onDelete = async () => {
        if (entityId) {
            await deleteServiceItemAdmin({entityId, id: item.id});
            //     if (!result?.isError) {
            //         toastService.onSuccess(t('resource.message.resourceRemoved'));
            //     }; 
            await onUpdate?.();
        }
    };

    return { edit, onEditOpen, onEditClose, onDelete }
}