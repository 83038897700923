import { useCallback, useEffect, useState } from "react";
import { IService } from "../entity/IService";
import { useGetOffersQuery } from "../../../../store/offer/getOffersAdmin/workers";
import { config } from "../../../../config";
import { useUpdateServiceItemAdminMutation } from "../../../../store/service/useCases/updateServiceAdmin/worker";
import { z } from "zod";

const DEFAULT_SERVICE: any = { name: '', value: '', file: null, type: '', id: 0 };

const initialStateFilterOffers = { page: 1, perPage: config.offer.perPage, search: '' };

interface IFiterOffers {
    page: number;
    perPage: number;
    search: string;
};

const clinicSchema = z.object({
    id: z.number(),
    name: z.string(),
});

const editServiceItemSchema = z.object({
    clinics: z.array(clinicSchema).nonempty(),
});

export const useServiceEditing = (entityId: number, close: () => void, item: IService = DEFAULT_SERVICE, onUpdate?: () => Promise<void>) => {
    const [service, setService] = useState<any>({ id: item.id, name: item.name, label: item.label });
    const [clinics, setClinics] = useState<any[]>(item.clinics
        .filter(clinic => clinic.is_select)
        .map(clinic => ({ ...clinic, label: clinic.name })));
    const [clinicOptions, setClinicOptions] = useState<any[]>([]);
    const [loading, setLoading] = useState(false);
    const [isInitialized, setInitialized] = useState(false);
    const [errors, setErrors] = useState({ service: '', clinics: '' });

    const [filterOffers, setFilterOffers] = useState<IFiterOffers>(initialStateFilterOffers);
    const { data: { rows: rowsGetOffers = [], count = 0 } = {}, isLoading: isLoadingGetOffers, isFetching: isFetchingGetOffers } = useGetOffersQuery(filterOffers);

    const [updateServiceItemAdmin, { isLoading: isLoadingCreateServiceItemAdmin, isSuccess: isSuccessCreateServiceItemAdmin }] = useUpdateServiceItemAdminMutation();

    const handleServiceChange = useCallback((service: any) => {
        setService(service);
    }, []);

    const handleChangeClinics = (event: any, newValue: readonly any[]) => {
        setClinics([...newValue]);
        setErrors({ ...errors, clinics: '' });
    };

    const serviceOptions = rowsGetOffers?.map((offer: any) => ({ id: offer?.id, name: offer?.name, label: offer?.name, clinics: offer?.clinics }));

    useEffect(() => {
        if (service && isInitialized) {
            setClinicOptions(service.clinics?.map((clinic: any) => ({ ...clinic, label: clinic?.name })));
            setClinics([]);
        }
    }, [service]);

    useEffect(() => {
        if (item) {
            setClinicOptions(item.clinics?.map((clinic: any) => ({ ...clinic, label: clinic?.name })));
            setInitialized(true);
        }
    }, [item]);

    const onEditService = async () => {
        setLoading(true);
        const result = editServiceItemSchema.safeParse({ clinics });

        if (result.success) {
            const payload = {
                categoryItemId: entityId,
                offerId: service.id,
                body: {
                    clinic_ids: clinics.map(clinic => clinic.id)
                }
            };
            await updateServiceItemAdmin(payload);
            // const result: any = await createResourceUseCase(link, entityId, resource as any);
            // if (!result?.isError) {
            //     toast.success("Сервіс створено");
            // }; 
            await onUpdate?.();
            close();
        } else{
            setErrors({ ...errors, clinics: 'Поле клiники обов\'язкове' });
        }
        setLoading(false);
    };

    return { loading, handleServiceChange, service, handleChangeClinics, clinics, serviceOptions, clinicOptions, onEditService, errors }
};