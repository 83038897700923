import { useState } from "react";

export const useServices = () => {
    const [visible, setVisible] = useState(false);

    const onOpen = () => {
        setVisible(true);
    };

    const onClose = () => {
        setVisible(false);
    };

    return { visible, onOpen, onClose };
};
