import { FC } from 'react';
import { ConfirmModal } from '../../../../features/modals';
import { useListUsers } from '../presenters/useListUsers';
import { UsersTable } from './components/usersTable';

const UsersAdmin: FC = () => {

    const { users, isLoadingDeleteUserAdmin, isFetchingGetUsersAdmin, filters, usersCount, selectedIds, currentPage, handleDeleteAll, handleSetUserIds, handleToggleAll, handleToggleOne, handlePageChange,
        isOpenDeleteUserModal, namesUserRef, handleSetUserNames, onCloseDeleteUserModal, handleDeleteUserIds, isLoadingGetUsersAdmin, currentTab, handleTabsChange, handleChangeNameUser, handleRefetchUsers,
        signInToUserAccount } = useListUsers();

    return (
        <>
            <UsersTable
                users={users}
                isLoading={isLoadingGetUsersAdmin || isFetchingGetUsersAdmin}
                handleTabsChange={handleTabsChange}
                handleSetUserNames={handleSetUserNames}
                handleChangeNameUser={handleChangeNameUser}
                handleRefetchUsers={handleRefetchUsers}
                currentTab={currentTab}
                perPage={filters.perPage}
                usersCount={usersCount}
                selectedIds={selectedIds}
                currentPage={currentPage}
                handleDeleteAll={handleDeleteAll}
                handleSetUserIds={handleSetUserIds}
                handleToggleAll={handleToggleAll}
                handleToggleOne={handleToggleOne}
                handlePageChange={handlePageChange}
                signInToUserAccount={signInToUserAccount}
            />
            {isOpenDeleteUserModal &&
                <ConfirmModal
                    title={currentTab?.hasOwnProperty('is_archive') ? `Відновити користувача` : `Архівувати користувача`}
                    content=''
                    onClose={onCloseDeleteUserModal}
                    namesRef={namesUserRef}
                    isOpen={isOpenDeleteUserModal}
                    handle={handleDeleteUserIds}
                    isLoading={isLoadingDeleteUserAdmin}
                />
            }
        </>

    );
};

export default UsersAdmin;