import { Stack, Typography } from '@mui/material';
import { FC, ReactNode } from 'react';

interface IProps {
    title: string | null | undefined;
    children?: ReactNode;
    stickyPosition?: string;
}

export const ActionableHeader: FC<IProps> = ({ title, children, stickyPosition = "73px" }) => (
    <Stack sx={{ position: "sticky", top: stickyPosition, zIndex: 2, py: "10px" }} direction='row' bgcolor="background.default" justifyContent="space-between" alignItems='flex-start'>
        <Typography variant="h4" component="h1" fontWeight={600}>{title}</Typography>
        {children}
    </Stack>

)


