export const getStyles = (paddingLeft: number) => {
    const styles = {
        categoryRow: {
            flexDirection: "row",
            alignItems: "center",
            p: "3px",
            pl: paddingLeft || 0,
        },
        chevronIcon: {
            width: "8%",
            alignItems: 'center'
        },
        iconButton: {
            width: 24,
            height: 'fit-content',
            alignSelf: "flex-start",
            padding: 0,
        },
        dragIcon: {
            mx: 2,
            flexDirection: "row",
            columnGap: 1,
            alignItems: "center",
            cursor: "grab",
            height: 25
        },
        categoryLink: {
            textDecoration: "none",
            color: "common.black",
            "&:active": {
                color: '#86A789'
            },
            whiteSpace: "pre-line",
            overflowWrap: 'anywhere',
            mr: 1.5,
            fontSize: 16
        },
        activeCategoryRow: {
            background: "#D4E7C5",
            flexDirection: "row",
            alignItems: "center",
            borderRadius: 4,
            p: "3px",
            pl: paddingLeft || 0,
        }

    };
    return styles;
};