import { CircularProgress, TableBody, TableCell, TableRow } from "@mui/material";
import { ChangeEvent, FC } from "react";
import { IUsers } from '../../../../../../store/user/repository/IUser';
import { Loading } from '../../../../../../uiKit';
import { getStyles } from './styles';
import { UsersTableItem } from '../usersTableItem';

interface IProps {
    selectedIds: number[];
    users: IUsers[];
    isLoading: boolean;
    handleSetUserIds: (id: number[]) => void;
    handleToggleOne: (event: ChangeEvent<HTMLInputElement>, id: number) => void;
    handleSetUserNames: any;
    signInToUserAccount: (id: number) => void;
};

export const UsersTableBody: FC<IProps> = ({ users, isLoading, handleSetUserNames, handleSetUserIds, handleToggleOne, selectedIds, signInToUserAccount }) => {
    const styles = getStyles();

    return (
        isLoading ? <TableBody>
            <TableRow>
                <TableCell sx={{ p: 0, border: 'none' }}>
                    <Loading sx={styles.emptyTable}>
                        <CircularProgress />
                    </Loading>
                </TableCell>
            </TableRow >
        </TableBody > : <TableBody>
            {users.length > 0 ? users?.map(user => {

                return (
                    <UsersTableItem
                        key={user?.id}
                        user={user}
                        selectedIds={selectedIds}
                        handleSetUserIds={handleSetUserIds}
                        handleToggleOne={handleToggleOne}
                        handleSetUserNames={handleSetUserNames}
                        signInToUserAccount={signInToUserAccount}
                    />
                )
            }) :
                <TableRow>
                    <TableCell sx={{ p: 0, border: 'none' }}>
                        {/* <Loading sx={styles.emptyTable}>Користувачів не має</Loading> */}
                    </TableCell>
                </TableRow >
            }
        </TableBody>
    );
};

