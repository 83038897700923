import { Box, SxProps, Theme } from '@mui/material';
import { FC, ReactNode } from 'react';

interface IProps {
    children: ReactNode;
    sx: SxProps<Theme> | undefined;
}

export const Loading: FC<IProps> = ({ children, sx }) => (
    <Box position={"absolute"} width="100%" sx={sx} display="flex" justifyContent="center" alignItems="center">
        {children}
    </Box>

);