import dayjs from 'dayjs';
import { ChangeEvent, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { useDebounce } from 'use-debounce';
import { z } from 'zod';
import { config } from '../../../../config';
import { useDisclosure, usePermissionContext } from '../../../../hooks/';
import { selectTreeCategories } from '../../../../store/category/repository/slice';
import { useGetCategoryItemsAdminQuery } from '../../../../store/categoryItem/useCases/categoryItemsAdmin/worker';
import { useDeleteCategoryItemAdminMutation } from '../../../../store/categoryItem/useCases/deleteCategoryItemAdmin/worker';
import { useUpdateCategoryItemAdminMutation } from '../../../../store/categoryItem/useCases/updateCategoryItemAdmin/worker';
import { ISeparateCategories, optionsTypes, separateTreeCategories } from '../../../../utils';
import { IPaginationOptionsCategoryItemsAdmin } from './IUseCategoryItemsAdmin';
import toast from 'react-hot-toast';
import { useGetCategoriesTreeAdminQuery } from '../../../../store/category/useCases/getTreeCategoriesAdmin/worker';

const filterCategoryItemsWithResourceSchema = z.object({
    search: z.string().nullable(),
    filter_category_id: z.object({ value: z.union([z.null(), z.number()]) }),
    filter_type: z.object({ value: z.union([z.null(), z.string()]) }),
    filter_hidden: z.object({ value: z.union([z.null(), z.boolean()]) }),
    filter_date: z.string().nullable(),
});

export type createUserFormSchema = z.infer<typeof filterCategoryItemsWithResourceSchema>;

const initialFilterObject = { page: 1, perPage: config.categoryItem.perPage, search: '', filter_type: optionsTypes[0].value, filter_hidden: null, filter_date: null, filter_category_id: null };

export const useCategoryItemsAdmin = () => {
    const { isOpen: isOpenDeleteCategoryItem, onClose: onCloseDeleteCategoryItem, onOpen: onOpenDeleteCategoryItem } = useDisclosure();

    const [filters, setFilters] = useState<IPaginationOptionsCategoryItemsAdmin>(initialFilterObject);
    const [currentPage, setCurrentPage] = useState(filters.page);

    const idCategoryItemRef = useRef<number | null>(null);
    const nameCategoryItemRef = useRef<string | null>(null);

    const { data: { rows = [], count = 0 } = {}, isLoading: isLoadingGetCategoryItemsAdmin, isSuccess: isSuccessGetCategoryItemsAdmin } = useGetCategoryItemsAdminQuery(filters, {});
    const [updateCategoryItemAdmin, { isLoading: isLoadingUpdateCategoryItemAdmin, isSuccess: isSuccessUpdateCategoryItemAdmin }] = useUpdateCategoryItemAdminMutation();
    const [deleteCategoryItemAdmin, { isLoading: isLoadingDeleteCategoryItemAdmin, isSuccess: isSuccessDeleteCategoryItemAdmin }] = useDeleteCategoryItemAdminMutation();

    const { permissionMap } = usePermissionContext();
    const { refetch: refetchGetCategoriesTree } = useGetCategoriesTreeAdminQuery(undefined, { skip: !permissionMap?.Read_Category });

    const treeCategories = useSelector(selectTreeCategories);

    const { mainCategories, subCategories } = useMemo((): ISeparateCategories => (separateTreeCategories(treeCategories) || { mainCategories: [], subCategories: [] }), [treeCategories]) as ISeparateCategories;

    const { control, watch, reset } = useForm<createUserFormSchema>({
        defaultValues: {
            search: '',
            filter_category_id: { value: null },
            filter_type: { value: optionsTypes[0].value },
            filter_hidden: { value: null },
            filter_date: null,
        }
    });

    const filter_category_id = watch('filter_category_id')?.value;
    const filter_type = watch('filter_type')?.value;
    const filter_hidden = watch('filter_hidden')?.value;
    const searchName = watch('search');
    const filter_date = dayjs(watch('filter_date')).valueOf() + dayjs(watch('filter_date')).utcOffset() * 1000 * 60 || null;

    const [search] = useDebounce(searchName, config.categoryItem.debounceSearch);

    const payload = useMemo(() => ({ filter_category_id, filter_type, filter_hidden, filter_date, search }), [filter_category_id, filter_type, filter_hidden, filter_date, search]);

    useEffect(() => {
        setFilters((prevState) => ({ ...prevState, ...payload, }));
    }, [filter_category_id, filter_type, filter_hidden, filter_date, search]);

    useEffect(() => {
        if (isSuccessDeleteCategoryItemAdmin) {
            refetchGetCategoriesTree();
            onCloseDeleteCategoryItem();
            toast.success("Документ успішно видаленно");
        };
    }, [isSuccessDeleteCategoryItemAdmin]);

    const handlePageChange = useCallback((e: ChangeEvent<unknown>, page: number) => {
        setCurrentPage(page);
        setFilters((prevState) => ({ ...prevState, page }));
    }, [filters]);

    const onOpenDeleteCategoryItemModal = (id: number | null, name: string) => {
        onOpenDeleteCategoryItem();
        nameCategoryItemRef.current = name;
        idCategoryItemRef.current = id;
    };

    const handleDeleteCategoryItem = useCallback(() => {
        deleteCategoryItemAdmin(idCategoryItemRef.current);
    }, []);

    const handleToggleIsHiddenCategoryItem = useCallback((is_hidden: boolean, id: number) => {
        const payload = {
            category_item_id: id,
            category_item: { is_hidden: !is_hidden }
        };
        updateCategoryItemAdmin(payload);
        idCategoryItemRef.current = id;
    }, []);

    const handleClearFilterCategoryItem = useCallback(() => {
        const { page, perPage, ...props } = initialFilterObject;
        reset();
        setFilters((prevState) => ({ ...prevState, ...props }));
    }, [reset]);

    return {
        control, handleClearFilterCategoryItem, isLoadingUpdateCategoryItemAdmin, isLoadingGetCategoryItemsAdmin, rows, onOpenDeleteCategoryItemModal, idCategoryItemRef, count, filters, currentPage, handlePageChange,
        isOpenDeleteCategoryItem, nameCategoryItemRef, handleToggleIsHiddenCategoryItem, onCloseDeleteCategoryItem, handleDeleteCategoryItem, isLoadingDeleteCategoryItemAdmin, mainCategories, subCategories
    };
};
