import AppProvider from './providers';
import Router from './navigation/router';
import './global.css';

function App() {
    return (
        <AppProvider>
            <Router />
        </AppProvider>
    );
}

export default App;
