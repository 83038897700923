import { FC, Suspense } from 'react';
import { Route, Routes } from 'react-router-dom';
import { Layout } from '../layouts/dashboard';
import Categories from '../modules/category/categories/ui';
import CategoryItemAdmin from '../modules/categoryItem/categoryItem/ui';
import CategoryItemsAdmin from '../modules/categoryItem/categoryItemsAdmin/ui';
import UpsertCategoryItemAdmin from '../modules/categoryItem/upsertCategoryItemAdmin/ui';
import Clinics from '../modules/clinic/clinics/ui';
import Home from '../modules/home/ui';
import SmsOperators from '../modules/smsOperators/ui';
import UserProfile from '../modules/user/profile/ui';
import UpsertUserAdmin from '../modules/user/upsertUserAdmin/ui';
import UsersAdmin from '../modules/user/usersAdmin/ui';

import { paths } from '../paths';
import { Loader } from '../uiKit';

// const CategoryItemAdmin = lazy(() => import('../modules/categoryItem/categoryItem/ui'));
// const Categories = lazy(() => import('../modules/category/categories/ui'));
// const Home = lazy(() => import('../modules/home/ui'));
// const CategoryItemsAdmin = lazy(() => import('../modules/categoryItem/categoryItemsAdmin/ui'));
// const UpsertCategoryItemAdmin = lazy(() => import('../modules/categoryItem/upsertCategoryItemAdmin/ui'));
// const UpsertUserAdmin = lazy(() => import('../modules/user/upsertUserAdmin/ui'));
// const UsersAdmin = lazy(() => import('../modules/user/usersAdmin/ui'));
// const SmsOperators = lazy(() => import('../modules/smsOperators/ui'));
// const Clinics = lazy(() => import('../modules/clinic/clinics/ui'));
// const UserProfile = lazy(() => import('../modules/user/profile/ui'));

export const PrivateRoutes: FC = () => {

    return (
        <Suspense fallback={<Loader />}>
            <Routes>
                <Route path={paths.index} element={<Layout />}>
                    <Route index element={<Home />} />
                    <Route path={paths.privateRoutes.category} element={<Categories />} />
                    <Route path={paths.privateRoutes.profile} element={<UserProfile />} />
                    <Route path={paths.privateRoutes.users} element={<UsersAdmin />} />
                    <Route path={paths.privateRoutes.create_user} element={<UpsertUserAdmin page="create" />} />
                    <Route path={paths.privateRoutes.update_user} element={<UpsertUserAdmin page="update" />} />
                    <Route path={paths.privateRoutes.documents} element={<CategoryItemsAdmin />} />
                    <Route path={paths.privateRoutes.documentsCreate} element={<UpsertCategoryItemAdmin page="create" />} />
                    <Route path={paths.privateRoutes.documentUpdate} element={<UpsertCategoryItemAdmin page="update" />} />
                    <Route path={paths.privateRoutes.documentsOne} element={<CategoryItemAdmin />} />
                    <Route path={paths.privateRoutes.clinics} element={<Clinics />} />
                    <Route path={'/operators-sms'} element={<SmsOperators />} />
                </Route>
                <Route path="*" element={<div style={{ position: "absolute", left: "50%", top: "50%", transform: "translate(-50%, -50%)" }}>Not Found 404</div>} />
            </Routes>
        </Suspense>
    );
};



