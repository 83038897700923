import { Middleware, isRejectedWithValue } from '@reduxjs/toolkit';
import { RejectedAction } from '@reduxjs/toolkit/dist/query/core/buildThunks';
import { BaseQueryFn, FetchArgs, FetchBaseQueryError, createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import toast from 'react-hot-toast';
import { setLogout } from '../auth/repository/slice';
import { setClearUser } from '../user/repository/slice';

const baseQuery = fetchBaseQuery({
    baseUrl: process.env.REACT_APP_PUBLIC_URL_API,
    prepareHeaders: (headers, { getState }) => {
        headers.set('Content-Type', 'application/json');
        const token = localStorage.getItem('accessToken');
        if (token) {
            headers.set('Authorization', `Bearer ${token}`);
        }
        return headers;
    },

}) as BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError>;

export const zooluxCallCenterAPI = createApi({
    reducerPath: "zooluxCallCenterAPI",
    baseQuery,
    tagTypes: ["CategoryAdmin", "UserAdmin", "CategoryItemAdmin"],
    endpoints: () => ({}),
});

