import { LoadingButton } from '@mui/lab';
import { Box, CircularProgress, Stack } from '@mui/material';
import { FC } from 'react';
import { ActionableHeader, CircularLoading, InputTextFieldController } from '../../../../uiKit';
import { useUpsertUsers } from '../presenters/useUpsertUsers';
import { AutocompleteCheckBoxController } from '../../../../uiKit/controllerFields/autocompleteCheckboxController ';
import { AutocompleteWithCheckBox } from '../../../../uiKit/autocompleteWithCheckBox';

interface IProps {
    page: string;
};

 const UpsertUserAdmin: FC<IProps> = ({ page }) => {
    const { control, handleSubmit, isLoadingUpdateUserAdmin, handleCreateUser, isLoadingCreateUserAdmin, isFetchingGetUserAdmin, isLoadingGetUserAdmin, rolesData,
        handleChangeRoles, rolesList } = useUpsertUsers();

    return (
        <>
            <ActionableHeader title={page === 'create' ? 'Створення користувача' : 'Оновлення користувача'} />
            {isLoadingGetUserAdmin && isFetchingGetUserAdmin ? <Box position={"relative"} height={"100%"}>
                <CircularLoading />
            </Box> :
                <Stack component="form" autoComplete="off" maxWidth={"600px"} rowGap={2} onSubmit={handleSubmit(handleCreateUser)}>
                    <InputTextFieldController name="full_name" control={control} defaultValue={''} label="ПІБ*" />
                    <InputTextFieldController name="phone" control={control} defaultValue={''} label="телефону*" placeholder='0(93)1111111' />
                    {/* <AutocompleteCheckBoxController options={rolesData} name='roles' control={control} label='Роль' /> */}
                    {page !== 'create' && <AutocompleteWithCheckBox positionsList={rolesData} value={rolesList} handleChange={handleChangeRoles} />}
                    <LoadingButton loading={isLoadingCreateUserAdmin || isLoadingUpdateUserAdmin} type='submit' sx={{ maxWidth: "250px" }}>
                        {page === 'create' ? 'Створити' : 'Оновити'}
                    </LoadingButton>
                </Stack>
            }
        </>
    );
};

export default UpsertUserAdmin;

