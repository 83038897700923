import { FC, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useDeleteCategoryAdminMutation } from '../../../../store/category/useCases/deleteCategoryAdmin/worker';
import { ConfirmModal } from '../../../../features/modals';

interface IProps {
    onCloseDeleteCategory: () => void;
    isOpenDeleteCategory: boolean;
    categoryId: string | undefined;
    categoryName: string | undefined | null;
};

export const DeleteCategoryModalAdmin: FC<IProps> = ({ onCloseDeleteCategory, isOpenDeleteCategory, categoryId, categoryName }) => {
    const navigate = useNavigate();
    const location = useLocation();

    const [DeleteCategoryAdmin, { isLoading, isSuccess }] = useDeleteCategoryAdminMutation();

    useEffect(() => {
        if (isSuccess) {
            onCloseDeleteCategory();
            redirectAfterDelete();
        }
    }, [isSuccess]);

    const redirectAfterDelete = () => {
        const currentPath = location.pathname;
        const categoryPath = `/category/${categoryId}`;
        if (currentPath === categoryPath) {
            navigate('/');
        }
    };

    const handleDeleteCategory = () => {
        DeleteCategoryAdmin(categoryId);
    };

    return (
        <ConfirmModal
            title={`Видалення категорії`}
            content={`Їх підкатегорії також будуть виделенні`}
            handle={handleDeleteCategory}
            onClose={onCloseDeleteCategory}
            name={categoryName}
            isLoading={isLoading}
            isOpen={isOpenDeleteCategory} />
    );
};
;
