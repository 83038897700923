import { Box, Card, Pagination, Stack, Table } from "@mui/material";
import { ChangeEvent, FC, SyntheticEvent } from "react";
import { IUsers } from '../../../../../../store/user/repository/IUser';
import { StatusType } from '../../../../../../store/user/repository/TUser';
import { SearchInput } from '../../../../../../uiKit';
import { HeaderUsers } from '../headerUsers';
import { TabStatus } from '../tabStatus';
import { UsersTableBody } from "../usersTableBody";
import { UsersTableHead } from "../usersTableHead";
import { getStyles } from './styles';


interface IProps {
    usersCount: number;
    selectedIds: number[];
    users: IUsers[];
    currentTab: StatusType;
    perPage: number;
    currentPage: number;
    isLoading: boolean;
    handleSetUserIds: (id: number[]) => void;
    handlePageChange: (e: ChangeEvent<unknown>, page: number) => void;
    handleToggleAll: (e: ChangeEvent<HTMLInputElement>) => void;
    handleToggleOne: (e: ChangeEvent<HTMLInputElement>, id: number) => void;
    handleTabsChange: (e: SyntheticEvent<Element, Event>, tab: any) => void;
    handleDeleteAll: () => void;
    handleChangeNameUser: (e: ChangeEvent<HTMLInputElement>) => void;
    handleRefetchUsers: () => void;
    handleSetUserNames: any;
    signInToUserAccount: (id: number) => void;
};

export const UsersTable: FC<IProps> = ({ users, usersCount, perPage, handleSetUserNames, currentPage, currentTab, handlePageChange, handleRefetchUsers, isLoading, handleChangeNameUser, handleSetUserIds,
    handleToggleAll, handleToggleOne, selectedIds, handleDeleteAll, handleTabsChange, signInToUserAccount }) => {
    const selectedAll = selectedIds.length === users.length && selectedIds.length > 0;
    const selectedSome = selectedIds.length > 0 && selectedIds.length < users.length;

    const styles = getStyles();

    return (
        <>
            <HeaderUsers handleRefetchUsers={handleRefetchUsers} isLoading={isLoading} />
            <Card sx={{ p: 2, mt: { xs: 5, lg: 0 } }}>
                <Stack rowGap={2} >
                    <TabStatus currentTab={currentTab} handleTabsChange={handleTabsChange} />
                    <SearchInput placeholder="Пошук користувача" handleChangeSearch={handleChangeNameUser} />
                </Stack>
                <Box sx={styles.tableWrapper}>
                    <Table sx={styles.table}>
                        <UsersTableHead
                            selectedAll={selectedAll}
                            selectedSome={selectedSome}
                            handleToggleAll={handleToggleAll}
                            handleDeleteAll={handleDeleteAll}
                            titleButton={currentTab?.hasOwnProperty('is_archive') ? `Відновити користувачів` : `Архівувати користувачів`}
                        />
                        <UsersTableBody
                            users={users}
                            isLoading={isLoading}
                            handleSetUserIds={handleSetUserIds}
                            handleSetUserNames={handleSetUserNames}
                            selectedIds={selectedIds}
                            handleToggleOne={handleToggleOne}
                            signInToUserAccount={signInToUserAccount}
                        />
                    </Table>
                </Box>
            </Card>
            <Stack sx={styles.paginationWrapper}>
                {usersCount >= perPage &&
                    <Pagination
                        count={Math.ceil(usersCount / perPage)}
                        page={currentPage}
                        color="primary"
                        onChange={handlePageChange}
                    />}
            </Stack>
        </>
    );
};
