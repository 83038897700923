import { FC } from 'react';
import { Outlet } from 'react-router-dom';
import { VerticalLayout } from './vertical-layout';

export const Layout: FC = () => {
    return (
        <VerticalLayout  >
            <Outlet />
        </VerticalLayout>
    );
};
