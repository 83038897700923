import { Dispatch } from '@reduxjs/toolkit';
import { zooluxCallCenterAPI } from '../../zooluxCallCenterAPI';
import { AnyAction } from 'redux-saga';
import { deleteEmptyParams } from '../../../utils';

interface Pet {
    id: number;
    name: string;
    birth_date: Date;
    gender: "Male" | "Female";
    contract_number: number;
}

interface Client {
    id: number;
    name: string;
    info: string;
    ref_key: string;
    code: string;
    is_hidden: boolean;
    createdAt: Date;
    pets: Pet[];
}


const clientAPI = zooluxCallCenterAPI.injectEndpoints({
    endpoints: (builder) => ({
        getPetsAdmin: builder.query<Client, { search: string | undefined; }>({
            query: (query) => ({
                url: `/clients/pets`,
                method: 'GET',
                params: deleteEmptyParams(query)
            }),
            async onQueryStarted(_, { dispatch, queryFulfilled }: {
                dispatch: Dispatch<AnyAction>,
                queryFulfilled: Promise<{ data: Client; }>,
            }) {
                try {
                } catch (error) {
                    console.error("Error fetching consultation: useGetPetsAdminQuery", error);
                }
            },
            keepUnusedDataFor: 0
        }),
    }),
    overrideExisting: false,
});

export const { useGetPetsAdminQuery } = clientAPI;