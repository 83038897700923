export const getStyles = (downMd: boolean) => {
    const styles = {
        background: {
            position: 'fixed',
            width: '100%',
            height: '100%',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
        },
        loader: {
            width: downMd ? '60%' : '20%',
        },
    };
    return styles;
};
