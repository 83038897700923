import { FC, useEffect, useState } from "react";
import { TextField, Autocomplete, Box, Paper, Checkbox, FormControlLabel, Divider, Typography } from "@mui/material";

interface IProps {
    isDisabled?: boolean;
    error?: string;
    label: string;
    options: any[];
    value: any[];
    handleChange: (event: any, newValue: readonly any[]) => void;
};

export const CheckboxAutocompleteWithAll: FC<IProps> = ({ handleChange, label, options, value, isDisabled, error }) => {
    const [selectAll, setSelectAll] = useState<boolean>(false);

    const handleToggleSelectAll = () => {
        setSelectAll((prev) => {
            if (!prev) handleChange(null, [...options]);
            else handleChange(null, []);
            return !prev;
        });
    };

    useEffect(()=>{
        setSelectAll(value?.length === options?.length ? true : false);
    }, [options]);

    return (
        <Autocomplete
            id="autocomplete_with_select_all"
            multiple
            options={options}
            fullWidth
            disableCloseOnSelect
            filterSelectedOptions
            freeSolo={false}
            value={value}
            onChange={(_e, value, reason) => {
                if (reason === "clear" || reason === "removeOption")
                    setSelectAll(false);
                if (reason === "selectOption" && value.length === options.length)
                    setSelectAll(true);
                handleChange(null, value);
            }}
            isOptionEqualToValue={(option, value) => option.id === value.id}
            getOptionLabel={(option) => option.label}
            renderOption={(props, option, { selected }) => (
                <li {...props}>
                    <Checkbox
                        // icon={<span>unchecked</span>}
                        // checkedIcon={<span>checked</span>}
                        style={{ marginRight: 8 }}
                        checked={selected}
                    />
                    {option.label}
                </li>
            )}
            disabled={isDisabled}
            clearIcon={false}
            noOptionsText="Всі клініки обрані"
            renderInput={(params) => (
                <TextField {...params} label={label} helperText={
                    <Typography variant="caption" color="error">{error}</Typography>
                }/>
            )}
            PaperComponent={(paperProps) => {
                const { children, ...restPaperProps } = paperProps;
                return (
                    <Paper {...restPaperProps}>
                        <Box
                            onMouseDown={(e) => e.preventDefault()}
                            pl={1.5}
                            py={0.5}
                        >
                            <FormControlLabel
                                onClick={(e) => {
                                    e.preventDefault();
                                    handleToggleSelectAll();
                                }}
                                label="Всі клініки"
                                control={
                                    <Checkbox id="select-all-checkbox" checked={selectAll} />
                                }
                            />
                        </Box>
                        <Divider />
                        {children}
                    </Paper>
                );
            }}
        />
    );
};