import { LocalPhone as LocalPhoneIcon, Person as PersonIcon } from '@mui/icons-material';
import { Box, Divider, Stack, Typography } from '@mui/material';
import { FC } from 'react';
import { useSelector } from 'react-redux';
import { selectUser } from '../../../../store/user/repository/slice';
import { ActionableHeader, InputIcon } from '../../../../uiKit';
import { getStyles } from './style';

const UserProfile: FC = () => {
    const user = useSelector(selectUser);
    const styles = getStyles();

    return (
        <Stack mt={1} >
            <ActionableHeader title='Профіль' />
            <Stack rowGap={"24px"}>
                <Stack sx={styles.profileWrapper}>
                    <Box sx={styles.imgProfile} component={"img"} src='/images/user-profile.png' />
                    <Stack rowGap={"10px"}>
                        <Typography sx={styles.mainField} component={"p"}>НОМЕР КОРИСТУВАЧА: {"Табельного номеру не має"}</Typography>
                        <Typography component={"p"}>ПІБ: {user?.full_name}</Typography>
                        <Typography component={"p"}>Телефон: {user?.position || 'Немає номеру'}</Typography>
                        <Typography component={"p"}>Посада: {user?.position || 'Без позиції'}</Typography>

                    </Stack>
                </Stack>
                <Divider />
                <Stack sx={styles.wrapperInputs} >
                    <InputIcon id="full_name" label="ПІБ" icon={<PersonIcon />} value={user?.full_name || null} disabled />
                    <InputIcon id="phone" label="Телефон" icon={<LocalPhoneIcon />} value={user?.phone || null} disabled />
                </Stack>
            </Stack>
        </Stack>
    );
};

export default UserProfile;