import { Autocomplete, Box, SxProps, TextField, Theme } from '@mui/material';
import { ChangeEvent, FC } from 'react';

interface IProps {
    onChange: (e: ChangeEvent<{}>, newValue: any) => void;
    value: string | null;
    id: string;
    options: any[];
    label: string;
    sx?: SxProps<Theme>;
}

export const SelectAutocomplete: FC<IProps> = ({ onChange, value, sx = [], id, options, label }) => {
    return (
        <Autocomplete
            sx={sx}
            onChange={onChange}
            value={value}
            renderOption={(props, option, { selected }) => (
                <Box component={'li'} {...props} key={option.id} sx={{ overflowWrap: 'anywhere' }}>
                    {option.label}
                </Box>
            )}
            isOptionEqualToValue={(option, value) => option?.name === value}
            id={id}
            options={options}
            renderInput={(params) => <TextField {...params} label={label} margin="dense" variant='outlined' />}
        />);
};
