
export const getStyles = () => {
    const styles = {
        logoContainer: {
            justifyContent: 'center',
            alignItems: 'end',
            p: 3
        },
        navContainer: {
            flexGrow: 1,
            px: 2,
        },
        addCategoryActionWrapper: {
            flexDirection: "row",
            justifyContent: "end",
            mr: 1
        },
        categoryLink: {
            textDecoration: "none",
            color: "common.black",
            "&:active": {
                color: '#86A789'
            },
            whiteSpace: "pre-line",
            overflowWrap: 'anywhere',
            mr: 1.5,
            fontSize: 16
        },
        link: {
            flexDirection: "row",
            columnGap: 2.2,
            p: .5,
            pl: 'calc(5.66% + 20px)',
            alignItems: "center",
            "&:first-of-type": {
                mt: 2
            }
        },
        activeLink: {
            background: "#D4E7C5",
            borderRadius: 12,
            w: "100%"
        }
    };
    return styles;
};