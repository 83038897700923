export const getStyles = () => {
    const styles = {
        header: {
            backdropFilter: 'blur(6px)',
            bgcolor: "background.default",
            position: 'sticky',
            top: 0,
            borderBottom: '1px solid #D3D3D3',
            zIndex: 100
        },
        headerContainer: {
            justifyContent: 'space-between',
            alignItems: 'center',
            height: 72,
            px: 2,
            pl: { xs: "20px", lg: "calc(30% + 20px)" },
            pr: "20px"
        }
    };
    return styles;
};