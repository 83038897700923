import AddIcon from '@mui/icons-material/Add';
import { Box, IconButton, Stack } from "@mui/material";
import { FC } from "react";
import { Link } from 'react-router-dom';
import { UpsertCategoryModalAdmin } from '../../../../../features/modals';
import { useDisclosure, usePermissionContext } from "../../../../../hooks";
import { Categories as Navbar } from '../../../../../modules/category/treeCategories/ui/categories';
import { paths } from '../../../../../paths';
import { MenuSection, NavColor } from "../../../../../types/settings";
import { SideDrawer } from "../side-drawer/side-drawer";
import { MainNavbar } from './components/mainNavbar';
import { getStyles } from "./styles";
import { useSelector } from 'react-redux';
import { selectUser } from '../../../../../store/user/repository/slice';

interface IProps {
    color?: NavColor;
    sections?: MenuSection[];
    isOpen: boolean;
    onNavOpen: () => void; 
}

export const SideNav: FC<IProps> = ({ color, sections = [], isOpen, onNavOpen }) => {
    const styles = getStyles();
    const { permissionMap  } = usePermissionContext();
    const { ref_key } = useSelector(selectUser);

    const { isOpen: isOpenUpsertCategory, onClose: onCloseCreateCategory, onOpen: onOpenCreateCategory } = useDisclosure();

    return (
        <>
            <SideDrawer isOpen={isOpen} onNavOpen={onNavOpen}>
                <Link to='/'>
                    <Stack direction="row" sx={styles.logoContainer} columnGap={2}>
                        <img src="/images/logo/logo-panel-1.svg" alt="logo" width={80} />
                        <img src="/images/logo/logo-panel-2.svg" alt="logo" width={130} />
                    </Stack>
                </Link>
                <Stack component="nav" sx={styles.navContainer} >
                    {permissionMap?.Read_User && <MainNavbar to={paths.privateRoutes.users} label='Користувачі' />}
                    {permissionMap?.Read_Category_Item && <MainNavbar to={paths.privateRoutes.documents} label='Документи' />}
                    {permissionMap?.Read_Clinic && <MainNavbar to={paths.privateRoutes.clinics} label='Клініки' />}
                    {permissionMap?.Read_User && <MainNavbar to='/operators-sms' label='Архів відправлених SMS' />}
                    {permissionMap?.Edit_Category && <Box alignSelf={"end"}><IconButton sx={styles.addCategoryActionWrapper} onClick={onOpenCreateCategory}><AddIcon /></IconButton></Box>}
                    <Navbar />
                </Stack>
            </SideDrawer>
            {isOpenUpsertCategory && <UpsertCategoryModalAdmin actionKey='create' onCloseUpsertCategory={onCloseCreateCategory} isOpenUpsertCategory={isOpenUpsertCategory} />}
        </>
    );
};

// { ref_key ? <MainNavbar to='/operators-sms' label='Архів відправлених SMS' /> : null; }