import { combineReducers } from '@reduxjs/toolkit';
import { AuthReducer } from './auth/repository/slice';
import { CategoryReducer } from './category/repository/slice';
import { CategoryItemReducer } from './categoryItem/repository/slice';
import { UserReducer } from './user/repository/slice';
import { zooluxCallCenterAPI } from './zooluxCallCenterAPI';

export const rootReducer = combineReducers({
  [zooluxCallCenterAPI.reducerPath]: zooluxCallCenterAPI.reducer,
  auth: AuthReducer,
  userRepository: UserReducer,
  categoryRepository: CategoryReducer,
  categoryItemRepository: CategoryItemReducer,
});
