import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../..';
import { ICategoryItems, IInitialStateCategoryItem } from './ICategoryItem';

const initialState: IInitialStateCategoryItem = {
  categoryItems: {
    count: 0,
    rows: []
  },
  messageClient: ""
};

const CategoryItemSlice = createSlice({
  name: 'categoryItemRepository',
  initialState,
  reducers: {
    setCategoryItems: (state, action: PayloadAction<ICategoryItems>) => {
      state.categoryItems = action.payload;
    },
    setMessageClient: (state, action: PayloadAction<any>) => {
      if (action.payload?.key === 'newMessage') {
        state.messageClient = action.payload.message;
        return;
      }
      state.messageClient += '\n' + action.payload.message;
    },
    setClearCategoryItems: (state) => {
      state.categoryItems = {
        count: 0,
        rows: []
      };
    },
  },
});

export const { setClearCategoryItems, setCategoryItems, setMessageClient } = CategoryItemSlice.actions;

export const CategoryItemReducer = CategoryItemSlice.reducer;

export const selectCategoryItems = (state: RootState) => state.categoryItemRepository.categoryItems;
export const selectMessageClient = (state: RootState) => state.categoryItemRepository.messageClient;





