import { zodResolver } from '@hookform/resolvers/zod';
import { useEffect, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import { z } from 'zod';
import { useCreateUserAdminMutation } from '../../../../store/user/useCases/createUserAdmin/worker';
import { useGetUserAdminQuery } from '../../../../store/user/useCases/getOneUserAdmin/worker';
import { useUpdateUserAdminMutation } from '../../../../store/user/useCases/updateUserAdmin/worker';
import { useGetAllRolesQuery } from '../../../../store/user/useCases/getAllRoles/worker';
import { useCreateUserRoleMutation } from '../../../../store/user/useCases/createUserRole/worker';
import { useDeleteUserRoleMutation } from '../../../../store/user/useCases/deleteUserRole/worker';
import toast from 'react-hot-toast';

const createUserSchema = z.object({
    full_name: z.string().min(1, { message: 'ПІБ не повинно бути пустим' }),
    phone: z.string().nonempty("Рядок не повинен бути пустим")?.length(10, "Рядок повинен містити рівно 10 символів"),
    id: z.number().optional(),
    // roles: z.array(z.object({ id: z.number(), name: z.string(), permissions: z.array(z.any())})).nullable()
});

type createUserFormSchema = z.infer<typeof createUserSchema>;

export const useUpsertUsers = () => {
    const navigate = useNavigate();

    const { id: idString } = useParams();
    const id = Number(idString);
    const [rolesList, setRolesList] = useState<any[]>([]);

    const { data: rolesData, isSuccess: isSuccessGetAllRoles } = useGetAllRolesQuery();
    const { data: { full_name = '', phone = '', roles = [] } = {}, isLoading: isLoadingGetUserAdmin, isFetching: isFetchingGetUserAdmin, isSuccess: isSuccessGetUserAdmin, refetch: refetchGetUserAdmin } = useGetUserAdminQuery(id, { skip: id === undefined, refetchOnMountOrArgChange: true });

    const [createUserAdmin, { isLoading: isLoadingCreateUserAdmin, isSuccess: isSuccessCreateUserAdmin }] = useCreateUserAdminMutation();
    const [updateUserAdmin, { isLoading: isLoadingUpdateUserAdmin, isSuccess: isSuccessUpdateUserAdmin }] = useUpdateUserAdminMutation();
    const [createUserRole, { isSuccess: isSuccessCreateUserRole }] = useCreateUserRoleMutation();
    const [deleteUserRole, { isSuccess: isSuccessDeleteUserRole }] = useDeleteUserRoleMutation();

    useEffect(() => {
        if (isSuccessGetAllRoles && isSuccessGetUserAdmin) {
            setRolesList(roles);
        }
    }, [isSuccessGetAllRoles, isSuccessGetUserAdmin]);

    useEffect(() => {
        if (isSuccessCreateUserAdmin || isSuccessUpdateUserAdmin) navigate('/users');
    }, [isSuccessCreateUserAdmin, isSuccessUpdateUserAdmin]);

    useEffect(() => {
        if (isSuccessCreateUserRole) toast.success('Роль додано');
        refetchGetUserAdmin();
    }, [isSuccessCreateUserRole]);

    useEffect(() => {
        if (isSuccessDeleteUserRole) toast.success('Роль видалено');
        refetchGetUserAdmin();
    }, [isSuccessDeleteUserRole]);

    const { handleSubmit, control, watch } = useForm<createUserFormSchema>({
        resolver: zodResolver(createUserSchema),
        values: {
            full_name,
            phone,
        }
    });

    const handleCreateUser: SubmitHandler<createUserFormSchema> = (payload) => {
        if (id) {
            payload.id = id;
            updateUserAdmin(payload);
        };
        if (!id) createUserAdmin(payload);
    };

    const handleChangeRoles = (event: any, newValue: readonly any[]) => {
        const newRoleNames = newValue.map((role) => role.name);
        const oldRoleNames = rolesList.map((role) => role.name);
        
        const missingRole = oldRoleNames.find(role => !newRoleNames.includes(role));
        if (missingRole) {
            deleteUserRole({ user_id: id, role_name: missingRole });
        }else{
            createUserRole({ user_id: id, roles_name: newRoleNames });
        }
        setRolesList([...newValue]);
    };

    return { control, handleSubmit, handleCreateUser, isSuccessCreateUserAdmin, isLoadingCreateUserAdmin, isLoadingUpdateUserAdmin, isFetchingGetUserAdmin, isLoadingGetUserAdmin, rolesData, handleChangeRoles, rolesList };
};
