import { Search as SearchIcon } from '@mui/icons-material';
import { Box, Button, Checkbox, CircularProgress, Divider, FormControlLabel } from '@mui/material';
import { CSSProperties, FC } from 'react';
import { AutocompleteController, InputIconController, MuiTelInputController, TextareaController } from '../../../../uiKit';
import { useWindowMessageClient } from '../../presenters/useWindowMessageClient';
import { getStyles } from './styles';

interface IProps {
    isOpenWindowMessageClient: boolean;
    onToggleWindowMessageClient: () => void;
}

export const WindowMessageClient: FC<IProps> = ({ isOpenWindowMessageClient, onToggleWindowMessageClient }) => {

    const { iShowAdditionalInfo, control, handleSubmit, handleSendMessage, refWindowSmsClient, isFetchingGetPetsAdmin, isLoadingCreateConsultationAdmin, pets, onChangeShowAdditionalInfo } = useWindowMessageClient({ isOpenWindowMessageClient, onToggleWindowMessageClient });

    const { mainWrapper, form, button } = getStyles({ isOpenWindowMessageClient });

    return (
        <Box component={"section"} ref={refWindowSmsClient} style={{ ...mainWrapper } as CSSProperties}>
            <Box noValidate autoComplete="off" component={'form'} onSubmit={handleSubmit(handleSendMessage)} sx={form}>
                <Box component={"span"} sx={{ whiteSpace: 'nowrap' }}>Відправка sms</Box>
                <Divider sx={{ mb: 2, mt: .5 }} />
                <MuiTelInputController control={control} name="phone" />
                <TextareaController sxLabel={{ whiteSpace: "nowrap" }} control={control} name="text" label="Текст SMS" />
                <Divider sx={{ mt: 1 }} />
                <FormControlLabel sx={{ span: { whiteSpace: "nowrap" } }} control={<Checkbox value={iShowAdditionalInfo} onChange={onChangeShowAdditionalInfo} />} label={"Користувач є клієнтом клініки?"} />
                {iShowAdditionalInfo && <>
                    <InputIconController name="client" control={control} icon={<SearchIcon />} placeholder='0970001122' label='Договір питомця або номер клієнта' />
                    {isFetchingGetPetsAdmin
                        ? <CircularProgress sx={{ alignSelf: "center", my: 1 }} />
                        : <AutocompleteController noOptionsText="Питомця не знайдено" loading={isFetchingGetPetsAdmin} control={control} name="pets" label="Питомець"
                            options={pets?.map(({ id, name }) => ({ value: id, label: name, id }))} />}
                </>}
                <Button disabled={isLoadingCreateConsultationAdmin} sx={button} type='submit'>{iShowAdditionalInfo ? `Відправити SMS та створити запис` : "Відправити sms"}</Button>
            </Box>
        </Box >
    );
};
