import { CalendarToday as CalendarTodayIcon, FilterAltOff as FilterAltOffIcon } from '@mui/icons-material';
import { Button, Pagination, Paper, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Tooltip, Typography } from '@mui/material';
import { uk } from 'date-fns/locale';
import dayjs from 'dayjs';
import { FC } from 'react';
import { DateRange, RangeKeyDict } from 'react-date-range';
import { InfoSendSmsModal } from '../../../features/modals';
import { ActionableHeader, CircularLoading } from '../../../uiKit';
import { useSmsOperators } from '../presenters/useSmsOperators';
import { getStyles } from './styles';

const SmsOperators: FC = () => {
  const { wrapper, wrapperFilter, searchInput, dateRangePicker, table } = getStyles();

  const { handleChangeSearch, filterName, onToggleRangeDataPicker, isOpenRangeDataPicker, setRangeDate, rangeDate, clearFilters, isFetchingGetConsultationAdmin, rowsGetConsultationAdmin,
    countGetConsultationAdmin, filterSmsCustomer, handlePageChange, handleOpenInfoSmsModal, isOpenInfoSendSmsModal, refConsultationId, onCloseInfoSendSmsModal } = useSmsOperators();

  return (
    <Stack sx={wrapper}>
      <ActionableHeader title={"Відправлені SMS працівників"} />
      <Stack sx={wrapperFilter} >
        <TextField onChange={handleChangeSearch} value={filterName} sx={searchInput} label='Пошук за ПІБ працівника або по номеру телефона' />
        <Stack sx={dateRangePicker} >
          <Tooltip title="Період">
            <Button onClick={onToggleRangeDataPicker}><CalendarTodayIcon /></Button>
          </Tooltip>
          {isOpenRangeDataPicker &&
            <DateRange
              displayMode='dateRange'
              startDatePlaceholder='Початок'
              endDatePlaceholder='Кінець'
              dragSelectionEnabled={true}
              locale={uk}
              editableDateInputs={false}
              onChange={(item: RangeKeyDict) => setRangeDate([item.selection])}
              moveRangeOnFirstSelection={false}
              ranges={rangeDate}
              preventSnapRefocus={true}
            />
          }
          <Tooltip title="Скасувати фільтри">
            <Button onClick={clearFilters}><FilterAltOffIcon /></Button>
          </Tooltip>
        </Stack>
      </Stack>
      <TableContainer component={Paper} sx={table}>
        <Table>
          <TableHead>
            <TableRow sx={{ position: "sticky", top: 0, bgcolor: "background.default", th: { fontWeight: 600 } }}>
              <TableCell>Номер клієнта</TableCell>
              <TableCell>ПІБ працівника</TableCell>
              <TableCell>Дата відправлення</TableCell>
              <TableCell>Текст sms</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {isFetchingGetConsultationAdmin
              ? <TableRow>
                <TableCell sx={{ borderBottom: "none" }}>
                  <CircularLoading />
                </TableCell>
              </TableRow>
              : rowsGetConsultationAdmin.length > 0 ? rowsGetConsultationAdmin.map(({ phone, initiator, text, createdAt, id }) => (
                <TableRow sx={{ cursor: "pointer" }} key={id} hover onClick={() => handleOpenInfoSmsModal(id)}>
                  <TableCell>{phone}</TableCell>
                  <TableCell>{initiator.full_name}</TableCell>
                  <TableCell>{dayjs(createdAt).format('DD.MM.YYYY')}</TableCell>
                  <TableCell>
                    <Typography noWrap sx={{ width: "200px", overflow: 'hidden', textOverflow: 'ellipsis', }} >
                      {text}
                    </Typography>
                  </TableCell>
                </TableRow>
              ))
                : <tr><td><Typography position={"absolute"} top={"50%"} left={"50%"} sx={{ transform: "translate(-50%,-50%)" }}>Нікого не знайдено</Typography></td></tr>}
          </TableBody>
        </Table>
      </TableContainer>
      {countGetConsultationAdmin >= filterSmsCustomer.perPage
        && <Pagination
          sx={{ alignSelf: "center" }}
          count={Math.ceil(countGetConsultationAdmin / filterSmsCustomer.perPage)}
          page={filterSmsCustomer.page}
          color="primary"
          onChange={handlePageChange} />
      }
      {isOpenInfoSendSmsModal && <InfoSendSmsModal title='Інформація відправленної sms' content={rowsGetConsultationAdmin?.find(({ id }) => id === refConsultationId.current)} isOpen={isOpenInfoSendSmsModal} onClose={onCloseInfoSendSmsModal} handle={() => { }} />}
    </Stack >
  );
};

export default SmsOperators;