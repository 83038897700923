import { Dispatch } from '@reduxjs/toolkit';
import { zooluxCallCenterAPI } from '../../../zooluxCallCenterAPI';
import { AnyAction } from 'redux-saga';

const categoryAPI = zooluxCallCenterAPI.injectEndpoints({
    endpoints: (builder) => ({
        updateCategoryAdmin: builder.mutation<void, any>({
            query: (params) => ({
                url: `/admin/category/update`,
                method: 'PUT',
                body: {
                    ...params
                },
            }),
            async onQueryStarted(_, { dispatch, queryFulfilled }: {
                dispatch: Dispatch<AnyAction>,
                queryFulfilled: Promise<{}>,
            }) {
                try { } catch (error) {
                    console.error("Error fetching updateCategoryAdmin:", error);
                }
            },
            invalidatesTags: ['CategoryAdmin']
        }),
    }),
    overrideExisting: false,
});

export const { useUpdateCategoryAdminMutation } = categoryAPI;