import { FC, ReactNode, createContext } from 'react';
import { usePermissionContext } from '../hooks';
import { useGetClinicsQuery } from '../store/clinic/cliniks/workers';
import { IClinic, useGetClinicsAdminQuery } from '../store/clinic/cliniksAdmin/workers';

interface IProps {
    clinics: IClinic[];
    clinicsCount: number;
    isLoadingGetClinics: boolean;
}

export const ClinicContext = createContext<IProps | undefined>(undefined);

interface ClinicProviderProps {
    children: ReactNode;
}

export const ClinicProvider: FC<ClinicProviderProps> = ({ children }) => {

    const token = globalThis.localStorage.getItem('accessToken');

    const { permissionMap } = usePermissionContext();

    const { data: { rows: clinicsAdmin = [], count: clinicsCountAdmin = 0 } = {}, isLoading: isLoadingGetClinicsAdmin } = useGetClinicsAdminQuery(undefined, { skip: !token || !permissionMap?.Read_Clinic });
    const { data: { rows: clinicsUser = [], count: clinicsCountUser = 0 } = {}, isLoading: isLoadingGetClinicsUser } = useGetClinicsQuery(undefined, { skip: !token || permissionMap?.Read_Clinic });

    const clinics = clinicsAdmin?.length > 0 ? clinicsAdmin : clinicsUser;
    const clinicsCount = clinicsCountAdmin ? clinicsCountAdmin : clinicsCountUser;
    const isLoadingGetClinics = isLoadingGetClinicsAdmin || isLoadingGetClinicsUser;

    return (
        <ClinicContext.Provider value={{ clinics, clinicsCount, isLoadingGetClinics }}>
            {children}
        </ClinicContext.Provider>
    );
};
