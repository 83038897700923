import { DeleteForever as DeleteForeverIcon, EditOutlined as EditOutlinedIcon, VisibilityOffOutlined as VisibilityOffOutlinedIcon, VisibilityOutlined as VisibilityOutlinedIcon } from '@mui/icons-material';
import { Box, CircularProgress, IconButton, SvgIcon, Table, TableBody, TableCell, TableRow, Tooltip, Typography } from '@mui/material';
import dayjs from 'dayjs';
import localeUk from 'dayjs/locale/uk';
import { FC } from 'react';
import { Link } from 'react-router-dom';
import { ICategoryItemRow } from '../../../../../../store/categoryItem/repository/ICategoryItem';
import { Loading } from '../../../../../../uiKit';
import { getStyles } from './styles';

dayjs.locale(localeUk);

interface IProps {
    isLoading: boolean;
    rows: ICategoryItemRow[];
    onOpenDeleteCategoryItemModal: (id: number, name: string) => void;
    handleToggleIsHiddenCategoryItem: (is_hidden: boolean, id: number) => void;
    isLoadingUpdateCategoryItemAdmin: boolean;
    idUpdateCategoryItem: number | null;
}

export const TableCategoryItem: FC<IProps> = ({ isLoading, rows, onOpenDeleteCategoryItemModal, handleToggleIsHiddenCategoryItem, isLoadingUpdateCategoryItemAdmin, idUpdateCategoryItem }) => {
    const styles = getStyles();

    // const { idCategoryItemRef, loadingPdf, handleCreatePdf } = useCreatePdf();

    return (
        <Box sx={styles.tableWrapper}>
            <Table>
                {isLoading ? <TableBody>
                    <TableRow>
                        <TableCell sx={{ p: 0, border: 'none' }}>
                            <Loading sx={{ height: "100%" }}>
                                <CircularProgress />
                            </Loading>
                        </TableCell>
                    </TableRow >
                </TableBody > : <TableBody>
                    {rows.map(({ id, name, createdAt, is_hidden }) => (
                        <TableRow hover key={id}>
                            <TableCell sx={styles.tableCell_1}>
                                <Typography>{name}</Typography>
                            </TableCell>
                            <TableCell sx={styles.tableCell_2}>
                                <Typography>
                                    {dayjs(createdAt).format('DD.MM.YYYY')}
                                </Typography>
                            </TableCell>
                            <TableCell sx={styles.tableCell_3}  >
                                {/* <IconButton size="small" onClick={() => handleCreatePdf(id)} disabled={loadingPdf && idCategoryItemRef.current === id}>
                                    <Tooltip title={'Завантажити'} >
                                        <SvgIcon style={styles.svgIcon}><SimCardDownloadOutlinedIcon /></SvgIcon>
                                    </Tooltip>
                                </IconButton> */}
                                <IconButton disabled={isLoadingUpdateCategoryItemAdmin && idUpdateCategoryItem === id} size="small" onClick={() => handleToggleIsHiddenCategoryItem(is_hidden, id)}>
                                    <Tooltip title={is_hidden ? 'Не опублікований' : 'Опублікований'} >
                                        <SvgIcon style={styles.svgIcon}>{is_hidden ? <VisibilityOffOutlinedIcon /> : <VisibilityOutlinedIcon />}</SvgIcon>
                                    </Tooltip>
                                </IconButton>
                                <IconButton size="small" component={Link} to={`/documents/update/${id}`}>
                                    <Tooltip title={'Редагувати'}>
                                        <SvgIcon style={styles.svgIcon}><EditOutlinedIcon /></SvgIcon>
                                    </Tooltip>
                                </IconButton>
                                <Tooltip title={'Видалити'}>
                                    <IconButton size="small" onClick={() => onOpenDeleteCategoryItemModal(id, name)}>
                                        <SvgIcon style={styles.svgIcon}><DeleteForeverIcon /></SvgIcon>
                                    </IconButton>
                                </Tooltip>
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
                }
            </Table>
        </Box >
    );
};
