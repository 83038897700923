import { useDisclosure } from '../../../../hooks';

export const useHandleCategory = () => {
    const { isOpen: isOpenDeleteCategory, onClose: onCloseDeleteCategory, onOpen: onOpenDeleteCategory } = useDisclosure();
    const { isOpen: isOpenUpdateCategory, onClose: onCloseUpdateCategory, onOpen: onOpenUpdateCategory } = useDisclosure();

    const onDeleteCategoryModal = () => {
        onOpenDeleteCategory();
    };

    const onUpdateCategoryModal = () => {
        onOpenUpdateCategory();
    };

    return { onDeleteCategoryModal, isOpenDeleteCategory, onCloseDeleteCategory, isOpenUpdateCategory, onCloseUpdateCategory, onUpdateCategoryModal };

};