import { Dispatch } from '@reduxjs/toolkit';
import { AnyAction } from 'redux-saga';
import { zooluxCallCenterAPI } from '../../../zooluxCallCenterAPI';
import { setListCategories } from '../../repository/slice';

export interface ICategoriesAdmin {
    id: number | null;
    name: string | undefined;
    is_hidden: string | undefined;
}

const categoryAPI = zooluxCallCenterAPI.injectEndpoints({
    endpoints: (builder) => ({
        getCategoriesAdmin: builder.query<ICategoriesAdmin[], void>({
            query: () => ({
                url: `/admin/category/list`,
                method: 'GET',
            }),
            async onQueryStarted(_, { dispatch, queryFulfilled }: {
                dispatch: Dispatch<AnyAction>,
                queryFulfilled: Promise<{ data: ICategoriesAdmin[]; }>,
            }) {
                try {
                    const { data } = await queryFulfilled;

                    const optionsList = data?.map(({ id, name }: ICategoriesAdmin) => ({ id, value: id, label: name }));

                    dispatch(setListCategories(optionsList));
                } catch (error) {
                    console.error("Error fetching getCategoriesAdmin:", error);
                }
            },
            providesTags: ["CategoryAdmin"],
        }),
    }),
    overrideExisting: false,
});

export const { useGetCategoriesAdminQuery } = categoryAPI;