import { Button } from '@mui/material';
import { FC } from 'react';
import { getStyles } from './styles';

interface IProps {
  title: string;
  handleLogin: () => void;
}

export const LogInButton: FC<IProps> = ({ title, handleLogin }) => {
  const styles = getStyles();

  return (
    <Button
      fullWidth
      variant='contained'
      size='large'
      sx={styles.loginBtn}
      onClick={handleLogin}>
      {title}
    </Button>
  );
};
