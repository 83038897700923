import { Dispatch } from '@reduxjs/toolkit';
import { zooluxCallCenterAPI } from '../../../zooluxCallCenterAPI';
import { AnyAction } from 'redux-saga';
import { IResource } from '../../repository/ICategoryItem';
import { IOffer } from '../../../offer/getOffersAdmin/workers';

interface ICreateCategoryItemAdminRequest {
    category_item: {
        name: string;
        category_id: number | undefined;
        is_hidden: boolean;
        // offer_id: number | null | undefined;
    };
    resource: {
        type: string | undefined;
        description: string | null;
    };
};

export interface IOneCategoryItemAdminResponse {
    id: number;
    name: string;
    description: string | null;
    category_id: number;
    is_hidden: boolean;
    createdAt: string;
    updatedAt: string;
    category: {
        id: number,
        name: string;
    };
    initiator: {
        id: number,
        full_name: string;
    };
    resources: IResource[];
    offer?: IOffer;
    offers?: any[]
};

const categoryItemAPI = zooluxCallCenterAPI.injectEndpoints({
    endpoints: (builder) => ({
        createCategoryItemAdmin: builder.mutation<IOneCategoryItemAdminResponse, ICreateCategoryItemAdminRequest>({
            query: (body) => ({
                url: '/admin/category-manager',
                method: 'POST',
                body
            }),
            async onQueryStarted(_, { dispatch, queryFulfilled }: {
                dispatch: Dispatch<AnyAction>,
                queryFulfilled: Promise<{ data: IOneCategoryItemAdminResponse; }>,
            }) {
                try { } catch (error) {
                    console.error("Error fetching createCategoryItemAdmin:", error);
                }
            },
            invalidatesTags: ["CategoryItemAdmin"]
        }),
    }),
    overrideExisting: false,
});

export const { useCreateCategoryItemAdminMutation } = categoryItemAPI;