import { Dispatch } from '@reduxjs/toolkit';
import { AnyAction } from 'redux-saga';
import { zooluxCallCenterAPI } from '../../../zooluxCallCenterAPI';
import { ICategoryItems } from '../../repository/ICategoryItem';
import { deleteEmptyParams } from '../../../../utils';
import { IPaginationOptionsCategoryItemsAdmin } from '../../../../modules/categoryItem/categoryItemsAdmin/presenters/IUseCategoryItemsAdmin';

const categoryItemAPI = zooluxCallCenterAPI.injectEndpoints({
    endpoints: (builder) => ({
        getCategoryItemsAdmin: builder.query<ICategoryItems, IPaginationOptionsCategoryItemsAdmin>({
            query: (params) => ({
                url: '/admin/category-manager',
                method: 'GET',
                params: deleteEmptyParams(params)
            }),
            async onQueryStarted(_, { dispatch, queryFulfilled }: {
                dispatch: Dispatch<AnyAction>,
                queryFulfilled: Promise<{ data: ICategoryItems; }>,
            }) {
                try { } catch (error) {
                    console.error("Error fetching getCategoryItemsAdmin:", error);
                }
            },
            providesTags: ["CategoryItemAdmin"]
        }),
    }),
    overrideExisting: false,
});

export const { useGetCategoryItemsAdminQuery } = categoryItemAPI;
