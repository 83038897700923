import { Dispatch } from '@reduxjs/toolkit';
import { zooluxCallCenterAPI } from '../../../zooluxCallCenterAPI';
import { AnyAction } from 'redux-saga';
import { IOneCategoryItemAdminResponse } from '../createCategoryItemAdmin/worker';

const categoryItemAPI = zooluxCallCenterAPI.injectEndpoints({
    endpoints: (builder) => ({
        getOneCategoryItem: builder.query<IOneCategoryItemAdminResponse | undefined, string | undefined>({
            query: (id) => ({
                url: '/category-manager/one/' + id,
                method: 'GET',
            }),
            async onQueryStarted(_, { dispatch, queryFulfilled }: {
                dispatch: Dispatch<AnyAction>,
                queryFulfilled: Promise<{ data: IOneCategoryItemAdminResponse | undefined; }>,
            }) {
                try { } catch (error) {
                    console.error("Error fetching getOneCategoryItemAdmin:", error);
                }
            },
            keepUnusedDataFor: 0
        }),
    }),
    overrideExisting: false,
});

export const { useGetOneCategoryItemQuery, useLazyGetOneCategoryItemQuery } = categoryItemAPI;