import { Dispatch } from '@reduxjs/toolkit';
import { AnyAction } from 'redux-saga';
import { zooluxCallCenterAPI } from '../../zooluxCallCenterAPI';

export interface IPermission {
    id: number;
    name: string;
}

const permissionAPI = zooluxCallCenterAPI.injectEndpoints({
    endpoints: (builder) => ({
        getPermission: builder.query<IPermission[], void>({
            query: () => ({
                url: `/permission`,
                method: 'GET',
            }),
            async onQueryStarted(_, { dispatch, queryFulfilled }: {
                dispatch: Dispatch<AnyAction>,
                queryFulfilled: Promise<{ data: IPermission[]; }>,
            }) {
                try { } catch (error) {
                    console.error("Error fetching getPermission:", error);
                }
            },
        }),
    }),
    overrideExisting: false,
});

export const { useGetPermissionQuery } = permissionAPI;