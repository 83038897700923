import { ChangeEvent, useCallback, useEffect, useState } from "react";
import { IService } from "../entity/IService";
import { useGetOffersQuery } from "../../../../store/offer/getOffersAdmin/workers";
import { config } from "../../../../config";
import { useCreateServiceItemAdminMutation } from "../../../../store/service/useCases/createServiceAdmin/worker";
import { z } from "zod";
import { useDebounce } from "../../../../hooks/useDebounce";

const DEFAULT_SERVICE: any = { name: '', value: '', file: null, type: '', id: 0 };

const initialStateFilterOffers = { page: 1, perPage: config.offer.perPage, search: '' };

interface IFiterOffers {
    page: number;
    perPage: number;
    search: string;
};

const clinicSchema = z.object({
    id: z.number(),
    name: z.string(),
});

const serviceSchema = z.object({
    id: z.number(),
    name: z.string(),
    label: z.string(),
    clinics: z.array(clinicSchema).nonempty(),
});

const createServiceItemSchema = z.object({
    service: serviceSchema,
    clinics: z.array(clinicSchema).nonempty(),
});

export const useServiceCreating = (entityId: number, close: () => void, item: IService = DEFAULT_SERVICE, onUpdate?: () => Promise<void>) => {
    const [service, setService] = useState<any>(null);
    const [clinics, setClinics] = useState<any[]>([]);
    const [clinicOptions, setClinicOptions] = useState<any[]>([]);
    const [loading, setLoading] = useState(false);
    const [errors, setErrors] = useState({ service: '', clinics: '' });

    const [filterOffers, setFilterOffers] = useState<IFiterOffers>(initialStateFilterOffers);
    const { data: { rows: rowsGetOffers = [], count = 0 } = {}, isLoading: isLoadingGetOffers, isFetching: isFetchingGetOffers, refetch: getServiceRefetch } = useGetOffersQuery(filterOffers);

    const [createServiceItemAdmin, { isLoading: isLoadingCreateServiceItemAdmin, isSuccess: isSuccessCreateServiceItemAdmin }] = useCreateServiceItemAdminMutation();

    const handleServiceChange = useCallback((service: any) => {
        setService(service);
        setErrors({ ...errors, service: '' });

        setClinics([]);
    }, []);

    const changeServiceText = (event: ChangeEvent<HTMLInputElement>) => {
        debouncedHandleServiceSeacrh(event.target.value);
    };

    const onSearchService = (value: string) => {
        setFilterOffers(prev => ({ ...prev, search: value }))
    };

    useEffect(()=>{
        getServiceRefetch();
    }, [filterOffers]);

    const { debouncedWrapper: debouncedHandleServiceSeacrh } = useDebounce(onSearchService, 700);

    const handleChangeClinics = (event: any, newValue: readonly any[]) => {
        setClinics([...newValue]);
        setErrors({ ...errors, clinics: '' });
    };

    const serviceOptions = rowsGetOffers?.map((offer: any) => ({ id: offer?.id, name: offer?.name, label: offer?.name, clinics: offer?.clinics }));

    useEffect(() => {
        if (service) {
            setClinicOptions(service.clinics.map((clinic: any) => ({ ...clinic, label: clinic?.name })));
        }
    }, [service]);

    const onCreateService = async () => {
        setLoading(true);
        const result = createServiceItemSchema.safeParse({ service, clinics });

        if (result.success) {
            const payload = {
                categoryItemId: entityId,
                offerId: service.id,
                body: {
                    clinic_ids: clinics.map(clinic => clinic.id)
                }
            };
            await createServiceItemAdmin(payload);
            // const result: any = await createResourceUseCase(link, entityId, resource as any);
            // if (!result?.isError) {
            //     toast.success("Сервіс створено");
            // }; 

            await onUpdate?.();
            close();
        } else {
            const newErrors: { [key: string]: string } = {};
            result.error.errors.forEach((err) => {
                const fieldPath = err.path.join('.');
                if (fieldPath === 'service') {
                    newErrors.service = 'Поле послуга обов\'язкове';
                } else if (fieldPath === 'clinics') {
                    newErrors.clinics = 'Поле клiники обов\'язкове';
                }
            });

            setErrors({ ...errors, ...newErrors });
        }
        setLoading(false);
    };

    return { loading, handleServiceChange, service, handleChangeClinics, clinics, serviceOptions, clinicOptions, onCreateService, errors, setClinics, changeServiceText }
};