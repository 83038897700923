import { Dispatch, FC, ReactNode, SetStateAction, createContext, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useGetAccess } from '../hooks';
import { selectAuth } from '../store/auth/repository/selector';
import { IPermission, useGetPermissionQuery } from '../store/permission/getPermission/worker';
import { useGetProfileUserQuery } from '../store/user/useCases/getProfileUser/worker';
import { Loader } from '../uiKit';

interface PermissionMap {
    [key: string]: boolean | undefined;
};
interface IProps {
    permissionMap: PermissionMap;
    refetchGetProfileUser: () => void;
};

export const PermissionContext = createContext<IProps | undefined>(undefined);

interface PermissionProviderProps {
    children: ReactNode;
};

export const PermissionProvider: FC<PermissionProviderProps> = ({ children }) => {
    const { isLoading, } = useGetAccess();
    const { isLoggedIn } = useSelector(selectAuth);
    const token = globalThis.localStorage.getItem('accessToken');

    // const permissionMap: PermissionMap = {};
    const [permissionMap, setPermissionMap] = useState<PermissionMap>({});

    const { data: dataProfileUser, isLoading: isLoadingGetProfileUser, isSuccess: isSuccessGetProfileUser, refetch: refetchGetProfileUser } = useGetProfileUserQuery(undefined, { skip: !isLoggedIn || !token, refetchOnMountOrArgChange: true });
    const { data: permissionServer = [], isLoading: isLoadingGetPermission, isSuccess: isSuccessGetPermission } = useGetPermissionQuery(undefined, { skip: !isLoggedIn || !token, refetchOnMountOrArgChange: true });

    useEffect(() => {
        if (!!dataProfileUser && permissionServer?.length > 0) {
            const updatedPermissionMap = { ...permissionMap };

            permissionServer.forEach((permission: IPermission) => {
                updatedPermissionMap[permission.name] = dataProfileUser?.permissions.some((userPermission: IPermission) => userPermission.name === permission.name);
            });
            setPermissionMap(updatedPermissionMap);
        }
    }, [dataProfileUser, permissionServer]);

     // if (permissionServer.length > 0) {
    //     permissionServer.forEach((permission: IPermission) => {
    //         permissionMap[permission.name] = dataProfileUser?.permissions.some((userPermission: IPermission) => userPermission.name === permission.name);
    //     });
    // };
    
    if (isLoading || isLoadingGetProfileUser || isLoadingGetPermission) return <Loader />;

    return (
        <PermissionContext.Provider value={{ permissionMap, refetchGetProfileUser }}>
            {children}
        </PermissionContext.Provider>
    );
};


// const permissionServer = [{ "name": "Read_Permission", "id": 1 }, { "name": "Read_Role", "id": 2 }, { "name": "Edit_Role", "id": 3 }, { "name": "Edit_Role_Permission", "id": 4 }, { "name": "Edit_User_Permission", "id": 5 }, { "name": "Edit_User_Role", "id": 6 }, { "name": "Edit_User", "id": 7 }, { "name": "Read_User", "id": 8 }, { "name": "Read_Category", "id": 9 }, { "name": "Edit_Category", "id": 10 }, { "name": "Edit_File", "id": 11 }, { "name": "Read_Service_Info", "id": 12 }, { "name": "Edit_Service_Info", "id": 13 }, { "name": "Read_Category_Item", "id": 14 }, { "name": "Edit_Category_Item", "id": 15 }, { "name": "Read_Resource", "id": 16 }, { "name": "Edit_Resource", "id": 17 }, { "name": "Read_Clinic", "id": 18 }, { "name": "Edit_Clinic", "id": 19 }, { "name": "Read_Offer", "id": 20 }, { "name": "Edit_Offer", "id": 21 }];
// const permissionUser: Permission[] = [
//     { "name": "Read_Permission", "id": 1 },
//     { "name": "Read_Role", "id": 2 },
//     { "name": "Edit_Role", "id": 3 },
//     { "name": "Edit_Role_Permission", "id": 4 },
//     { "name": "Edit_User_Permission", "id": 5 },
//     { "name": "Read_Category_Item", "id": 14 },
//     { "name": "Edit_Category_Item", "id": 15 },
//     { "name": "Read_Resource", "id": 16 },
//     { "name": "Edit_Resource", "id": 17 },
//     { "name": "Read_Clinic", "id": 18 },
//     { "name": "Edit_Clinic", "id": 19 },
//     { "name": "Read_Offer", "id": 20 },
//     { "name": "Edit_Offer", "id": 21 }
// ];