import { Link } from '@mui/material';
import type { Hit as AlgoliaHit } from 'instantsearch.js/es/types';
import { Snippet } from 'react-instantsearch-hooks-web';
import { Link as Links } from 'react-router-dom';

type HitProps = {
  hit: AlgoliaHit<{
    name: string;
    image: string;
    brand: string;
    categories: string[];
  }>;
};

export function Hit({ hit }: any) {
  return (
    <Link component={Links} to={`/documents/${hit?.objectID}`} underline="none">
      <article className="hit">
        <h1>
          <Snippet hit={hit} attribute="name" />
        </h1>
        <Snippet hit={hit} attribute="description" />
      </article>
    </Link>

  );
}
