import React, { FC } from 'react';
import { Card, Stack } from '@mui/material';
import { IService } from '../../../entity/IService';
import { useServiceCreating } from '../../../presenter/useServiceCreating';
import { ServiceControls } from '../serviceControls';
import { AutocompleteController } from '../../../../../../uiKit';
import { TextAutocomplete } from '../../../../../../uiKit/textAutocomplete';
// import { CheckBoxAutocomplete } from '../../../../../../uiKit/checkBoxAutocomplete';
import { useServiceEditing } from '../../../presenter/useServiceEditing';
import { CheckboxAutocompleteWithAll } from '../../../../../../uiKit/checkboxAutocompleteWithAll';


interface IProps {
    item?: IService;
    entityId: number;
    close: () => void;
    onUpdate?: () => Promise<void>;
}

export const ServiceEditingView: FC<IProps> = ({ item, entityId, onUpdate, close }) => {
    const { loading, handleServiceChange, service, handleChangeClinics, clinics, serviceOptions, clinicOptions, onEditService, errors } = useServiceEditing(entityId, close, item, onUpdate);

    return (
        <Card sx={{ p: 2, my: 2 }}>
            <Stack spacing={2} maxWidth="100%">
                <TextAutocomplete title='Послуга' value={service} handleChangeValue={handleServiceChange} optionList={serviceOptions} isDisabled={true}/>
                {/* <CheckBoxAutocomplete label="Клініки" value={clinics} handleChange={handleChangeClinics} options={clinicOptions} isDisabled={!(!!service)}/> */}
                <CheckboxAutocompleteWithAll label='Клініки' value={clinics} handleChange={handleChangeClinics} options={clinicOptions} isDisabled={!(!!service)} error={errors.clinics}/>
                <ServiceControls onClose={close} onSave={onEditService} isDisabledButton={loading} />
            </Stack>
        </Card>
    );
};
