import { Dispatch } from '@reduxjs/toolkit';
import { zooluxCallCenterAPI } from '../../../zooluxCallCenterAPI';
import { AnyAction } from 'redux-saga';

interface ICreateServiceItemAdminRequest {
    categoryItemId: number;
    offerId: number;
    body: {
        clinic_ids: number[];
    };
};

const serviceItemAPI = zooluxCallCenterAPI.injectEndpoints({
    endpoints: (builder) => ({
        createServiceItemAdmin: builder.mutation<void, ICreateServiceItemAdminRequest>({
            query: (payload) => ({
                url: `/admin/category-manager/${payload.categoryItemId}/offers/${payload.offerId}`,
                method: 'POST',
                body: payload.body
            }),
            async onQueryStarted(_, { dispatch, queryFulfilled }: {
                dispatch: Dispatch<AnyAction>,
                queryFulfilled: Promise<{ data: void }>,
            }) {
                try {
                    await queryFulfilled;
                } catch (error) {
                    console.error("Error fetching createServiceItemAdmin:", error);
                }
            },
            invalidatesTags: ["CategoryItemAdmin"]
        }),
    }),
    overrideExisting: false,
});

export const { useCreateServiceItemAdminMutation } = serviceItemAPI; 