import { Delete as DeleteIcon, Edit as EditIcon } from '@mui/icons-material';
import { Box, Button, Link, Stack, Tooltip } from '@mui/material';
import dayjs from 'dayjs';
import { Link as Links } from 'react-router-dom';
import { Algolia } from '../../../../features';
import { UpsertCategoryModalAdmin } from '../../../../features/modals';
import { ActionableHeader, CircularLoading } from '../../../../uiKit';
import { DeleteCategoryModalAdmin } from '../../deleteCategoryModalAdmin/ui';
import { useCategoriesAdmin } from '../presenters/useCategoriesAdmin';
import { getStyles } from './style';
import { paths } from '../../../../paths';

const Categories = () => {
    const { actionButton, headerTable, headerBody, createButton } = getStyles();

    const { onCloseDeleteCategory, isLoadingGetOneCategory, isLoadingOneCategoryAdmin, isEmptyStateSearchAlgolia, handleEmptyState, permissionMap, onCloseUpdateCategory, categoryId, isOpenDeleteCategory, isOpenUpdateCategory, dataCategory, onUpdateCategoryModal, onDeleteCategoryModal } = useCategoriesAdmin();

    return (
        <Stack rowGap={2}>
            {isLoadingOneCategoryAdmin || isLoadingGetOneCategory ? <CircularLoading />
                : <>
                    {!isEmptyStateSearchAlgolia && <ActionableHeader title={dataCategory?.name} /* children={
                        permissionMap?.Edit_Category && <Stack sx={actionButton}>
                            <Tooltip title="Видалити"><Button onClick={onDeleteCategoryModal}><DeleteIcon /></Button></Tooltip>
                            <Tooltip title="Редагувати"><Button onClick={onUpdateCategoryModal}><EditIcon /></Button></Tooltip>
                        </Stack>
                    } */ />}
                    {isLoadingOneCategoryAdmin || isLoadingGetOneCategory ? null : <Algolia skipRequestEmptyName handleEmptyState={handleEmptyState} stickyPositionWrapper={0} isEmptyStateSearchAlgolia={isEmptyStateSearchAlgolia} />}

                    {!isEmptyStateSearchAlgolia && (!!dataCategory?.category_items?.length ? <Stack paddingBottom={2}>
                        <Stack sx={headerTable}>
                            <Box flexBasis="40%">Назва</Box>
                            <Box flexBasis="20%">Дата створення</Box>
                            <Box flexBasis="20%">Дата оновлення</Box>
                            <Box flexBasis="20%">Ініціатор</Box>
                        </Stack>
                        {dataCategory?.category_items?.map(({ id, name, createdAt, updatedAt, initiator }) => (
                            <Link underline="none" key={id} component={Links} to={`/documents/${id}/${categoryId}`}>
                                <Stack direction={"row"} sx={headerBody}>
                                    <Box flexBasis="40%" component="span">{name}</Box>
                                    <Box flexBasis="20%" component="span">{dayjs(createdAt).format('DD.MM.YYYY')}</Box>
                                    <Box flexBasis="20%" component="span">{dayjs(updatedAt).format('DD.MM.YYYY')}</Box>
                                    <Box flexBasis="20%" component="span">{initiator?.full_name}</Box>
                                </Stack>
                            </Link>
                        ))}
                    </Stack>

                        : <Stack alignItems="center" spacing={2}>
                            <Box component="img" src="/images/empty-document.png" width={200} />
                            Список документів порожній
                            {permissionMap?.Edit_Category_Item &&
                                <Button
                                    component={Links}
                                    to={paths.privateRoutes.documentsCreate.replace(':categoryId', categoryId || '')}
                                    variant='outlined'
                                    sx={createButton}
                                >
                                    Створити
                                </Button>
                            }
                        </Stack>
                    )}

                    {isOpenDeleteCategory && <DeleteCategoryModalAdmin categoryId={categoryId} onCloseDeleteCategory={onCloseDeleteCategory} isOpenDeleteCategory={isOpenDeleteCategory} categoryName={dataCategory?.name} />}
                    {isOpenUpdateCategory && <UpsertCategoryModalAdmin dataOneCategoryAdmin={dataCategory} actionKey='update' categoryId={categoryId} onCloseUpsertCategory={onCloseUpdateCategory} isOpenUpsertCategory={isOpenUpdateCategory} />}
                </>}
        </Stack>
    );
};

export default Categories;