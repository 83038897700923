import { zodResolver } from '@hookform/resolvers/zod';
import { useEffect } from "react";
import { SubmitHandler, useForm } from 'react-hook-form';
import { useSelector } from "react-redux";
import { z } from 'zod';
import { selectListCategories } from '../../../../store/category/repository/slice';
import { useCreateCategoryAdminMutation } from '../../../../store/category/useCases/createCategoryAdmin/worker';
import { useGetOneCategoryAdminQuery } from '../../../../store/category/useCases/getOneCategoryAdmin/worker';
import { useUpdateCategoryAdminMutation } from '../../../../store/category/useCases/updateCategoryAdmin/worker';
import { TextConfigs } from './Interfaces';

interface IProps {
    onCloseUpsertCategory: () => void;
    isOpenUpsertCategory: boolean;
    actionKey: keyof TextConfigs;
    categoryId?: string | undefined;
    dataOneCategoryAdmin?: any;
    inputValue?: string
};

const text = {
    create: {
        title: "Створити категорію",
        textBtn: "Створити"
    },
    update: {
        title: "Оновити категорію",
        textBtn: "Оновити"
    }
};

const upsertCategorySchema = z.object({
    name: z.string().min(1, { message: 'Поле не повинно бути пустим' }).nullable().optional(),
    parent_id: z.object({
        id: z.number().nullish(), value: z.number().nullish(), label: z.string().nullish()
    }).nullable().nullish().optional(),
    is_hidden: z.boolean().optional(),
    id: z.number().nullable().optional(),
});

type upsertCategoryFormSchema = z.infer<typeof upsertCategorySchema>;

export const useUpsertCategory = ({ onCloseUpsertCategory, actionKey, categoryId, inputValue }: IProps) => {
    const [createCategory, { isSuccess: isSuccessCreateCategory, isLoading: isLoadingCreateCategory }] = useCreateCategoryAdminMutation();
    const [updateCategoriesTreeAdmin, { isSuccess: isSuccessUpdateCategoriesTreeAdmin, isLoading: isLoadingUpdateCategoriesTreeAdmin }] = useUpdateCategoryAdminMutation();

    const categories = useSelector(selectListCategories);
    const { data: oneCategoryAdmin, isFetching: isFetchingCategoryAdmin } = useGetOneCategoryAdminQuery(categoryId, { skip: categoryId === undefined, refetchOnMountOrArgChange: true });

    const { control, handleSubmit } = useForm<upsertCategoryFormSchema>({
        resolver: zodResolver(upsertCategorySchema),
        defaultValues: {
            name: oneCategoryAdmin?.name || null,
            parent_id: categories?.find((item: any) => item.id === oneCategoryAdmin?.parent_id) as object,
            is_hidden: false,
            id: null
        },
        values: {
            name: inputValue || oneCategoryAdmin?.name,
            parent_id: categories?.find((item: any) => item.id === oneCategoryAdmin?.parent_id) as object,
            is_hidden: oneCategoryAdmin?.is_hidden,
        }
    });

    useEffect(() => {
        if (isSuccessCreateCategory || isSuccessUpdateCategoriesTreeAdmin) {
            onCloseUpsertCategory();
        }
    }, [isSuccessCreateCategory, isSuccessUpdateCategoriesTreeAdmin]);

    const handleUpsertCategory: SubmitHandler<upsertCategoryFormSchema> = (data) => {        
        const createPayload = {
            ...data,
            is_hidden: String(data.is_hidden),
            parent_id: data.parent_id?.id || null
        };
        const updatePayload = {
            ...createPayload,
            id: Number(categoryId) || null
        };

        if (actionKey === 'create') createCategory(createPayload);
        if (actionKey === 'update') updateCategoriesTreeAdmin(updatePayload);
    };

    return { handleUpsertCategory, text, categories, isLoadingCreateCategory, isLoadingUpdateCategoriesTreeAdmin, handleSubmit, control, isFetchingCategoryAdmin };
};