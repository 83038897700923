import { FC } from 'react';
import { useSelectPriceType } from '../../../presenters/useSelectPriceType';
import { SelectAutocomplete } from '../../../../../../uiKit';
import { IPriceType } from '../../../../../../store/clinic/cliniksAdmin/workers';

interface IProps {
    idClinic: number;
    price_type: IPriceType | null;
};

export const SelectPriceType: FC<IProps> = ({ idClinic, price_type }) => {
    const { priceTypeOptions, handleChangePriceType } = useSelectPriceType({ idClinic });

    return (
        <SelectAutocomplete
            label="Тіпи Цін"
            options={priceTypeOptions}
            id={idClinic.toString()}
            value={price_type?.name || null}
            onChange={handleChangePriceType}
            sx={{ minWidth: 250 }}
        />
    );
};