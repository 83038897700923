import { useTheme } from "@emotion/react";
import { Box } from "@mui/material";
import { FC, ReactNode } from "react";
import { useDisclosure } from '../../../hooks';
import { MenuSection, NavColor } from "../../../types/settings";
import { SideNav } from "./components/side-nav/side-nav";
import { TopNav } from "./components/top-nav/top-nav";
import { getStyles } from "./styles";

interface IProps {
    children?: ReactNode;
    navColor?: NavColor;
    sections?: MenuSection[];
};

export const VerticalLayout: FC<IProps> = ({ children, sections, navColor }) => {
    const theme = useTheme();
    const styles = getStyles(theme);
    const { isOpen, onToggle } = useDisclosure();

    return (
        <>
            <TopNav onNavOpen={onToggle} />
            <SideNav color={navColor} sections={sections} isOpen={isOpen} onNavOpen={onToggle} />
            <Box sx={styles.verticalLayoutRoot}>
                <Box sx={styles.verticalLayoutContainer}>
                    {children}
                </Box>
            </Box>
        </>
    );
};