import { useNavigate, useParams } from 'react-router-dom';
import { useDisclosure, usePermissionContext } from '../../../../hooks';
import { useGetOneCategoryAdminQuery } from '../../../../store/category/useCases/getOneCategoryAdmin/worker';
import { useGetOneCategoryQuery } from '../../../../store/category/useCases/getOneCategory/worker';
import { useEffect, useState } from 'react';

export const useCategoriesAdmin = () => {
    const navigate = useNavigate();

    const { id: categoryId } = useParams();
    const { permissionMap } = usePermissionContext();

    const [isEmptyStateSearchAlgolia, setIsEmptyStateSearchAlgolia] = useState<string>('');

    const handleEmptyState = (value: string) => {
        setIsEmptyStateSearchAlgolia(value);
    };

    const { data: dataOneCategoryAdmin, isLoading: isLoadingOneCategoryAdmin, refetch: refetchOneCategoryAdmin } = useGetOneCategoryAdminQuery(categoryId, { skip: !permissionMap?.Read_Category || (categoryId === undefined) });
    const { data: dataOneCategory, isLoading: isLoadingGetOneCategory, refetch: refetchOneCategory } = useGetOneCategoryQuery(categoryId, { skip: /* false */permissionMap?.Read_Category });

    const { isOpen: isOpenDeleteCategory, onClose: onCloseDeleteCategory, onOpen: onOpenDeleteCategory } = useDisclosure();
    const { isOpen: isOpenUpdateCategory, onClose: onCloseUpdateCategory, onOpen: onOpenUpdateCategory } = useDisclosure();
    const dataCategory = dataOneCategoryAdmin || dataOneCategory;

    useEffect(() => {
        if (categoryId && permissionMap?.Read_Category) {
            refetchOneCategoryAdmin();
        }
    }, [categoryId, permissionMap?.Read_Category, refetchOneCategoryAdmin]);

    const onDeleteCategoryModal = () => {
        onOpenDeleteCategory();
    };

    const onUpdateCategoryModal = () => {
        onOpenUpdateCategory();
    };
    
    return { isEmptyStateSearchAlgolia, handleEmptyState, permissionMap, isLoadingOneCategoryAdmin, onCloseDeleteCategory, onCloseUpdateCategory, categoryId, isOpenDeleteCategory, isOpenUpdateCategory, dataCategory, onUpdateCategoryModal, onDeleteCategoryModal, isLoadingGetOneCategory };
};
