import { ContentCopy as ContentCopyIcon } from '@mui/icons-material';
import { Box, Stack, Tooltip } from '@mui/material';
import copy from 'copy-to-clipboard';
import { domToReact } from 'html-react-parser';
import { useCallback, useEffect, useRef, useState } from 'react';
import toast from 'react-hot-toast';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { usePermissionContext, useClinicContext, useDisclosure } from '../../../../hooks';
import { selectTreeCategories } from '../../../../store/category/repository/slice';
import { useGetOneCategoryItemQuery } from '../../../../store/categoryItem/useCases/getOneCategoryItem/worker';
import { useGetOneCategoryItemAdminQuery } from '../../../../store/categoryItem/useCases/getOneCategoryItemAdmin/worker';
import { findBreadCrumbCategory, groupOffersByService, hasNonEmptyTextDeep, transformOffers } from '../../../../utils';
import { setMessageClient } from '../../../../store/categoryItem/repository/slice';
import { useDeleteCategoryItemAdminMutation } from '../../../../store/categoryItem/useCases/deleteCategoryItemAdmin/worker';
import { paths } from '../../../../paths';
import { useGetCategoriesTreeAdminQuery } from '../../../../store/category/useCases/getTreeCategoriesAdmin/worker';

const tag = ['p', 'ul', 'h1', 'h2', 'h3', 'h4', 'h5', 'h6'];

export const useCategoryItem = () => {
    const { id, categoryId } = useParams();
    const { permissionMap } = usePermissionContext();
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [isEmptyStateSearchAlgolia, setIsEmptyStateSearchAlgolia] = useState('');
    const textRefs = useRef<any>({});

    const { data: dataCategoryItem, isLoading: isLoadingCategoryItem, isFetching: isFetchingGetOneCategoryItem } = useGetOneCategoryItemQuery(id, { skip: false });

    const { name, resources, category_id, offers } = dataCategoryItem ?? {};
    
    const { clinics, isLoadingGetClinics } = useClinicContext();
    
    const clinicsWithOffer = transformOffers(offers);
    const groupedOffers = groupOffersByService(clinicsWithOffer);

    const activeTreeCategories = useSelector(selectTreeCategories);

    const breadcrumbCategoryItem = findBreadCrumbCategory(activeTreeCategories, category_id);

    const { isOpen: isOpenDeleteCategoryItem, onClose: onCloseDeleteCategoryItem, onOpen: onOpenDeleteCategoryItem } = useDisclosure();
    const idCategoryItemRef = useRef<number | null>(null);
    const nameCategoryItemRef = useRef<string | null>(null);
    
    const [deleteCategoryItemAdmin, { isLoading: isLoadingDeleteCategoryItemAdmin, isSuccess: isSuccessDeleteCategoryItemAdmin }] = useDeleteCategoryItemAdminMutation();

    const { refetch: refetchGetCategoriesTree } = useGetCategoriesTreeAdminQuery(undefined, { skip: !permissionMap?.Read_Category });

    useEffect(()=>{
        if(isSuccessDeleteCategoryItemAdmin){
            refetchGetCategoriesTree();
            navigate(paths.privateRoutes.category.replace(':id', String(categoryId)));
        };
    }, [isSuccessDeleteCategoryItemAdmin]);
    
    const handleEmptyState = useCallback((value: string) => {
        setIsEmptyStateSearchAlgolia(value);
    }, []);

    const handleCopyText = useCallback((id: string) => {
        const text = textRefs.current[id]?.innerText || '';

        dispatch(setMessageClient({ message: text }));

        copy(text);
        const cutText = text.slice(0, 30);
        toast.success(() => (
            <Box>Текст: <Box component={"span"} sx={{ fontWeight: 600 }}>{cutText}...</Box></Box>
        ));
    }, [textRefs]);

    const copyInfoService = ({ name, name_service, current_price, future_price }: any) => {
        const clinicName = name || '-';
        const price = current_price ? `${current_price} грн` : '-';
        const futurePrice = future_price ? `${future_price} грн` : '-';
        const textCopy = `Клініка: ${clinicName}, Послуга: ${name_service}, Ціна ${price}, Ціна у майбутньому: ${futurePrice} .`;

        dispatch(setMessageClient({ message: textCopy }));

        copy(textCopy);
        toast.success(textCopy);
    };

    const options = {
        replace: (domNode: any) => {
            if (domNode.type === 'tag' && (tag.includes(domNode.name) || ['ol', 'a'].includes(domNode.name))) {
                const hasNonEmptyText = hasNonEmptyTextDeep(domNode.children);

                const cleanChildren = domNode.children?.map((child: any) => {
                    if (child.attribs && child.attribs.style && child.name !== 'a') {
                        child.attribs = { style: child.attribs.style };
                    } else if (child.name !== 'a') {
                        child.attribs = {};
                    }
                    return child;
                });

                if (!hasNonEmptyText) return null;

                const id = Math.random().toString(36).substring(2);
                if (domNode.name === 'a') {
                    console.log(domNode);

                    return (
                        <Box component="a" href={domNode.attribs.href} key={id} ref={el => textRefs.current[id] = el} sx={{ color: 'blue' }} target={domNode.attribs.target}>
                            {domToReact(cleanChildren, options)}
                        </Box>
                    );
                };

                return (
                    <Stack direction={"row"} columnGap={1} sx={{ overflowWrap: 'anywhere' }} alignItems={"center"} key={id}>
                        <Tooltip title="Скопіювати">
                            <Box onClick={() => handleCopyText(id)}
                                sx={{ p: .5, ":hover": { opacity: .6, cursor: "pointer" } }}>
                                <ContentCopyIcon />
                            </Box>
                        </Tooltip>
                        <Box component={domNode.name} key={id} ref={el => textRefs.current[id] = el}>
                            {domToReact(cleanChildren, options)}
                        </Box>
                    </Stack >
                );
            }
        },
    };

    const onOpenDeleteCategoryItemModal = (id: number | null, name: string) => {
        onOpenDeleteCategoryItem();
        nameCategoryItemRef.current = name;
        idCategoryItemRef.current = id;
    };

    const handleDeleteCategoryItem = useCallback(() => {
        deleteCategoryItemAdmin(idCategoryItemRef.current);
    }, []);

    return {
        options, copyInfoService, isLoadingGetClinics, clinicsWithOffer, breadcrumbCategoryItem, handleEmptyState,
        isLoadingCategoryItem, isFetchingGetOneCategoryItem, isEmptyStateSearchAlgolia, resources, name, id,
        onOpenDeleteCategoryItemModal, isOpenDeleteCategoryItem, nameCategoryItemRef, onCloseDeleteCategoryItem,
        handleDeleteCategoryItem, isLoadingDeleteCategoryItemAdmin, categoryId, permissionMap, groupedOffers
    };
};

