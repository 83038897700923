import { Dispatch } from '@reduxjs/toolkit';
import { zooluxCallCenterAPI } from '../../zooluxCallCenterAPI';
import { AnyAction } from 'redux-saga';
import { deleteEmptyParams } from '../../../utils';

const consultationAPI = zooluxCallCenterAPI.injectEndpoints({
    endpoints: (builder) => ({
        createConsultationAdmin: builder.mutation<any, any>({
            query: (body) => ({
                url: `/consultations`,
                method: 'POST',
                body: deleteEmptyParams(body)
            }),
            async onQueryStarted(_, { dispatch, queryFulfilled }: {
                dispatch: Dispatch<AnyAction>,
                queryFulfilled: Promise<{}>,
            }) {
                try { } catch (error) {
                    console.error("Error fetching consultation: useCreateConsultationAdminMutation", error);
                }
            },
        }),
    }),
    overrideExisting: false,
});

export const { useCreateConsultationAdminMutation } = consultationAPI;