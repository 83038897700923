import { keyframes } from '@emotion/react';

const pulseAnimation = keyframes`
  0% {
    transform: scale(1);
    opacity: 0;
  }
  50% {
    opacity: .3;
  }
  100% {
    transform: scale(2);
    opacity: 0;
  }
`;

export const getStyles = () => ({
  whatsappButton: {
    background: "#1ab744",
    borderRadius: "50%",
    height: "45px",
    width: "45px",
    lineHeight: "45px",
    position: "relative",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    "&::before, &::after": {
      content: "''",
      position: "absolute",
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      borderRadius: "50%",
      animation: `${pulseAnimation} 2s infinite`,
      animationDelay: "0.5s",
    },
    "&::before": {
      background: "#1ab744",
    },
    "&::after": {
      background: "transparent",
    },
  },
  windowMessageClient: {
    display: "inline-block",
    color: "#fff",
    position: "fixed",
    bottom: "50px",
    right: "50px",
    zIndex: 1301,
    cursor: "pointer",
  },
});