import { useTheme } from '@mui/material/styles';
import type { FC } from 'react';
import { Toaster as HotToaster } from 'react-hot-toast';

export const Toaster: FC = () => {
    const theme = useTheme();

    return (
        <HotToaster
            position="top-right"
            toastOptions={{
                style: {
                    backdropFilter: 'blur(6px)',
                    background: '#18933A' /* '#FFF59B' */,
                    color: theme.palette.common.white,
                    boxShadow: theme.shadows[16],
                },
            }}
        />
    );
};
