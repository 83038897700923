export const uk = {
    auth: {
        phone: 'Номер телефону',
        password: 'Пароль',
        confirmPass: 'Підтвердити пароль',
        enter: 'Увійти',
        logout: 'Вийти',
        createAndEnter: 'Створити пароль',
        forgotPass: 'Забули пароль?',
        checkPhone: 'Перевірити номер',
        recoverPhone: 'Відновити пароль',
        confirmCode: 'Підтвердити код',
        resend: 'Надіслати код повторно',
        smsPassword: 'Пароль з sms',
        validation: {
            phoneValid: 'Невірний формат номера. Зразок: +380XXXXXXXXX.',
            passValid: 'Пароль повинен бути більше ніж 3 символи і меньше 18',
            matchPass: 'Пароль не збігається. Будь ласка, перевірте ваш пароль.',
        },
        messages: {
            createSafePass: 'Створіть безпечний пароль',
            confirmYourPass: 'Підтвердіть ваш пароль',
            enterSms: 'Введіть код, який ви отримали по смс',
            not_valid_phone_or_password: 'Номер телефону або пароль не вірний',
            not_found_phone: 'Номер телефону не знайдено',
            not_valid_data: 'Логін або пароль введенний не вірно',
            not_valid_code: 'Не вірний код',
        },
    },
    category: {
        create_category: "Створити категорію",
        messages: {
            not_valid_data: "Не валідні данні, будь ласка спробуйте пізніше",
            empty_category_name: "Заповніть, назву категорії"
        }
    }
};
