
export const getStyles = () => {
    const styles = {
        formWrapper: {
            display: 'flex',
            flexDirection: 'column',
            p: 2
        },
        closeButton: {
            position: 'absolute',
            right: 8,
            top: 8,
            color: "gray",
        }
    };

    return styles;
};
