import { Box, IconButton, Stack, SvgIcon, Theme, useMediaQuery } from "@mui/material";
import { FC } from "react";
import { getStyles } from "./styles";
import MenuIcon from '@mui/icons-material/Menu';
import { AccountButton } from "../../../../../uiKit";

interface IProps {
    onNavOpen: () => void;
}

export const TopNav: FC<IProps> = ({ onNavOpen }) => {
    const styles = getStyles();
    const lgUp = useMediaQuery((theme: Theme) => theme.breakpoints.up('lg'));

    return (
        <Box component="header" sx={styles.header} >
            <Stack direction="row" spacing={2} sx={styles.headerContainer} >
                {!lgUp && (<IconButton onClick={onNavOpen}><SvgIcon><MenuIcon /></SvgIcon></IconButton>)}
                <Stack direction='row' alignItems='center' gap={1}>
                </Stack>
                <Stack direction='row'>
                    <AccountButton />
                </Stack>
            </Stack>
        </Box>
    );
};
