import { NavigateFunction } from 'react-router-dom';

type NavigationServiceType = {
    navigate: NavigateFunction | null;
    setNavigator: (navigator: NavigateFunction) => void;
    redirectTo: (path: string) => void;
};

export const navigationService: NavigationServiceType = {
    navigate: null,

    setNavigator(navigator) {
        this.navigate = navigator;
    },

    redirectTo(path) {
        if (this.navigate) {
            this.navigate(path);
        }
    }
};