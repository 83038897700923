import { ChangeEvent, useEffect, useState } from "react";
import { useUpdateClinicAdminMutation } from "../../../../store/clinic/updateClinicAdmin/workers";
import toast from "react-hot-toast";
import { useGetPriceTypeQuery } from "../../../../store/clinic/priceType/workers";
import { IPriceType, useGetClinicsAdminQuery } from "../../../../store/clinic/cliniksAdmin/workers";
import { usePermissionContext } from "../../../../hooks";

interface IProps {
    idClinic: number;
};

interface IPriceTypeOption{
    id: number; 
    name: string; 
    label: string;
};

export const useSelectPriceType = ({ idClinic }: IProps) => {
    const { permissionMap } = usePermissionContext();
    const token = globalThis.localStorage.getItem('accessToken');

    const [priceTypeOptions, setPriceTypeOptions] = useState<IPriceTypeOption[]>([]);

    const { data: { rows: priceTypes = [], count: priceTypesCount = 0 } = {}, isLoading: isLoadingGetPriceTypes } = useGetPriceTypeQuery(undefined, { skip: false });
    const [updateClinicAdmin, { isLoading: isLoadingUpdateClinicAdmin, isSuccess: isSuccessUpdateClinicAdmin }] = useUpdateClinicAdminMutation();
    const { data: clinicsAdminData, refetch, isLoading: isLoadingGetClinicsAdmin } = useGetClinicsAdminQuery(undefined, { skip: !token || !permissionMap?.Read_Clinic });

    useEffect(() => {
        if (priceTypes?.length) {
            const options = priceTypes.map((type: any) => ({ id: type.id, name: type.name, label: type.name }));
            setPriceTypeOptions(options);
        };
    }, [priceTypes]);

    useEffect(() => {
        if (isSuccessUpdateClinicAdmin) {
            toast.success("Тип ціни змінено!");
            refetch();
        }
    }, [isSuccessUpdateClinicAdmin, refetch]);

    const handleChangePriceType = (e: ChangeEvent<{}>, newValue: any) => {
        updateClinicAdmin({ clinic_id: idClinic, price_type_id: newValue?.id || null })
    };

    return { priceTypeOptions, handleChangePriceType };
};