export const getStyles = () => (
    {
        wrapper: {
            rowGap: 2,
            pb: 1,
        },
        wrapperFilter: {
            flexDirection: 'row',
            justifyContent: "space-between"
        },
        searchInput: {
            minWidth: "500px"
        },
        dateRangePicker: {
            ".rdrMonth": {
                width: "23.667em"
            },
            ".rdrCalendarWrapper": {
                position: "absolute",
                top: "56px",
                zIndex: 10,
                right: 0,
            },
            '.MuiButtonBase-root': {
                height: "100%"
            },
            position: "relative",
            columnGap: 3,
            flexDirection: "row"
        },
        table: {
            position: "relative",
            height: "calc(100vh - 72px - 20px - 62px - 56px - 48px - 32px - 2px - 8px)"
        }
    }
)

