import { Stack, Tab, Tabs } from "@mui/material";
import { FC, SyntheticEvent } from "react";
import { useTranslation } from "react-i18next";
import { ITabOption } from '../../../../../../store/user/repository/IUser';
import { StatusType } from '../../../../../../store/user/repository/TUser';

interface IProps {
    currentTab: StatusType;
    handleTabsChange: (event: SyntheticEvent<Element, Event>, value: any) => void;
};

const tabs: ITabOption[] = [
    { label: 'Всі', value: null },
    { label: 'Співробітники', value: 'EMPLOYEE' },
    { label: 'Стажери', value: 'INTERN' },
    { label: 'Архів', value: { is_archive: true } },
];

export const TabStatus: FC<IProps> = ({ handleTabsChange, currentTab }) => {
    const { t } = useTranslation();

    return (
        <Stack sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <Tabs indicatorColor="primary" onChange={handleTabsChange} scrollButtons="auto" textColor="primary" value={currentTab} variant="scrollable">
                {tabs.map((tab, index) => (
                    <Tab key={index} label={tab.label} value={tab.value} />
                ))}
            </Tabs>
        </Stack>
    );
};