import { Autocomplete, Box, TextField } from '@mui/material';
import { Dispatch, FC, SetStateAction, useState } from 'react';
import { Control, Controller, FieldError } from 'react-hook-form';
import { IParentCategory } from '../../store/category/repository/ICategory';

interface IProps {
    name: string;
    control: Control<any>;
    options: IParentCategory[];
    label: string;
    handleInputChange?: (value: string) => void;
    defaultValue?: any | undefined;
    loading?: boolean;
    sx?: any;
    noOptionsText?: string; 
    onModalOpen: () => void; 
    setInputValue: Dispatch<SetStateAction<string>>;
};

export const AutocompleteWithAddController: FC<IProps> = ({ name, control, options, label, loading, handleInputChange, defaultValue, sx = {}, onModalOpen, setInputValue }) => {

    return (
        <>
            <Controller
                name={name}
                control={control}
                defaultValue={defaultValue}
                render={({ field: { onChange, value, ref }, formState: { errors } }) => {
                    return (
                        <Autocomplete
                            sx={sx}
                            value={options.find(option => option.label === value?.label) || null}
                            getOptionLabel={(option) => option.label || ''}
                            isOptionEqualToValue={(option, value) => option.id === value.id}
                            renderOption={(props, option, { selected }) => (
                                <Box component={'li'} {...props} key={option.id} sx={{ overflowWrap: 'anywhere' }}>{option.label}</Box>)}
                            noOptionsText={
                                <Box sx={{ cursor: 'pointer' }} onClick={onModalOpen}>Створити категорію</Box>
                            }
                            loading={loading}
                            onInputChange={(e, value) => {
                                setInputValue(value);
                                handleInputChange && handleInputChange(value);
                            }}
                            onChange={(_, data) => onChange(data)}
                            options={options}
                            clearIcon={false}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label={label}
                                    inputRef={ref}
                                    error={!!errors[name]}
                                    helperText={(errors[name] as FieldError | undefined)?.message || (errors[name] as any | undefined)?.label?.message || ''}
                                />
                            )}
                        />
                    );
                }}
            />             
        </>
    );
};
