import { FC, ReactNode } from 'react';
import { I18nextProvider } from 'react-i18next';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { Toaster } from '../uiKit';
import i18n from '../locales/i18n';
import { store } from '../store';
import { AuthProvider } from '../context/auth';
import { ThemeManager } from '../context/themeManager';
import { PermissionProvider } from '../context/permission';
import { ClinicProvider } from '../context/clinic';

interface IProps {
    children: ReactNode;
}

const AppProvider: FC<IProps> = ({ children }) => (
    <BrowserRouter >
        <ThemeManager>
            <I18nextProvider i18n={i18n}>
                <Provider store={store}>
                    <AuthProvider>
                        <PermissionProvider>
                            <ClinicProvider>
                                {children}
                                <Toaster />
                            </ClinicProvider>
                        </PermissionProvider>
                    </AuthProvider>
                </Provider>
            </I18nextProvider>
        </ThemeManager>
    </BrowserRouter >
);

export default AppProvider;
