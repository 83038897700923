import { Add as AddIcon } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import { Button, Stack } from '@mui/material';
import { FC } from 'react';
import { Link } from 'react-router-dom';
import { ActionableHeader } from '../../../../../../uiKit';
import { getStyles } from './styles';

interface IProps {
    handleRefetchUsers: () => void;
    isLoading: boolean;
}

export const HeaderUsers: FC<IProps> = ({ handleRefetchUsers, isLoading }) => {
    const styles = getStyles();

    return (
        <Stack sx={styles.actionWrapper}>
            <ActionableHeader title='Користувачі' />
            <Stack direction={{ xs: 'column', lg: "row" }} columnGap={"12px"} rowGap={"8px"}>
                <LoadingButton disabled={isLoading} sx={styles.button} onClick={handleRefetchUsers}>
                    Оновити
                </LoadingButton>
                <Button component={Link} to='/users/create-user' sx={styles.button} startIcon={<AddIcon />}>Створити стажера</Button>
            </Stack>
        </Stack>
    );
};
