export const getStyles = () => {
    const styles = {
        actionButton: {
            pt: .5,
            columnGap: "20px",
            justifyContent: "end",
            flexDirection: "row"
        },
        headerTable: {
            flexDirection: "row",
            borderBottom: "1px solid",
            borderColor: "divider",
            bgcolor: "background.default",
            pl: 1,
            zIndex: 0,
            py: 1,
            position: "sticky", top: "132px"
        },
        headerBody: {
            borderBottom: "1px solid",
            borderColor: "divider",
            py: 2,
            pl: 1,
            ":hover": { backgroundColor: "grey.200" }
        },
        createButton: {
            textTransform: 'none', 
            fontSize: '16px'
        }
    };

    return styles;
};
