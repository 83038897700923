import { Middleware, isRejectedWithValue } from '@reduxjs/toolkit';
import { setIsLoggedIn, setLogout } from './auth/repository/slice';
import toast from 'react-hot-toast';

export const errorHandlingMiddleware: Middleware = (store) => (next) => (action) => {

    if (isRejectedWithValue(action)) {
        const error = action.payload as IApiError;

        toast.error(`Status ${error.status}

         ${error?.data?.message || error.error}`);

        if (error.status === 401) {
            globalThis.localStorage.removeItem('accessToken');
            globalThis.localStorage.removeItem('isLoggedIn');
            store.dispatch(setLogout());
            store.dispatch(setIsLoggedIn(false));
        }
    }
    return next(action);
};

interface IApiError {
    status: number;
    error?: string;
    data?: {
        message: string | string[];
    };
};;
