import { FC } from 'react';
import { Route, Routes } from 'react-router-dom';
import { ForgotPassword } from './../modules/auth/forgotPassword/ui';
import { Login } from './../modules/auth/login/ui';

export const PublicRoutes: FC = () => {

    return (
        <Routes>
            <Route path="/" element={<Login />} />
            <Route path="/forgot-password" element={<ForgotPassword />} />
            <Route path="*" element={<div style={{ position: "absolute", left: "50%", top: "50%", transform: "translate(-50%, -50%)" }}>Not Found 404</div>} />
        </Routes >
    );
};

