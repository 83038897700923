export const getStyles = () => {
    const styles = {
        svgIcon: {
            fontSize: 26
        },
        tableWrapper: {
            borderY: "1px solid",
            borderStyle: "solid",
            borderWidth: "1px 0",
            borderColor: "divider",
            height: "calc(100vh - 72px - 20px - 42px - 64px - 56px - 68px - 36px - 1px - 20px)",
            position: 'relative',
            overflowY: "scroll",
            width: "100%"
        },
        tableCell_1: {
            width: "60%",
            minWidth: "300px",
        },
        tableCell_2: {
            width: "15%",
            textAlign: "right",
            minWidth: "150px",
        },
        tableCell_3: {
            width: "25%",
            minWidth: "150px",
            textAlign: "right"
        },
    };
    return styles;
};