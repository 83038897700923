import { ICategory } from '../../store/category/repository/ICategory';

interface ISeparateSelectOption {
    id: number,
    value: number | string;
    label: string;
}
export interface ISeparateCategories {
    mainCategories: ISeparateSelectOption[];
    subCategories: ISeparateSelectOption[];
}
export const separateTreeCategories = (categories: ICategory[]): ISeparateCategories | undefined => {
    if (categories?.length === 0) return;
    const mainCategories = [] as any;
    const subCategories = [] as any;

    function processCategory(category: ICategory, isSubcategory = false) {
        const transformed = {
            id: category.id,
            value: category.id,
            label: category.name
        };

        if (isSubcategory) {
            subCategories.push(transformed);
        } else {
            mainCategories.push(transformed);
        }

        if (category.children && category.children.length > 0) {
            category.children.forEach((child: ICategory) => processCategory(child, true));
        }
    }

    categories.forEach((category: ICategory) => processCategory(category));

    return { mainCategories, subCategories };
};