
export const getStyles = (lgUp: boolean) => {
    const styles = {
        drawer: {
            width: lgUp ? '30%' : "300px",
            backgroundColor: 'background.navbar',
            color: '#FFFFFF',
        },
    };
    return styles;
};