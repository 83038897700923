import { Stack, Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material';
import { useClinicContext } from '../../../../hooks';
import { ActionableHeader, CircularLoading } from '../../../../uiKit';
import { getStyles } from './styles';
import { SelectPriceType } from './components/selectPriceType';

const Clinics = () => {
    const { tableHead, firstColumn, secondColumn } = getStyles();
    const { clinics, isLoadingGetClinics } = useClinicContext();

    return (
        <>
            <ActionableHeader title={"Клініки"} />
            <Table>
                <TableHead sx={tableHead}>
                    <TableRow>
                        <TableCell sx={firstColumn} align="center">Назва</TableCell>
                        <TableCell sx={secondColumn} align="center">Типи цін</TableCell>
                    </TableRow>
                </TableHead>
                {isLoadingGetClinics ?
                    <TableBody>
                        <TableRow><TableCell sx={{ p: 0, border: 'none' }}><CircularLoading /></TableCell></TableRow>
                    </TableBody> :
                    <TableBody>
                        {clinics?.map(({ id, name, price_type }) => (
                            <TableRow key={id}>
                                <TableCell sx={firstColumn}>{name}</TableCell>
                                <TableCell>
                                    <Stack alignItems="center">
                                        <SelectPriceType idClinic={id} price_type={price_type}/>
                                    </Stack>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>}
            </Table>
        </>
    );
};

export default Clinics;
