
export const getStyles = () => {
    const styles = {
        formWrapper: {
            display: 'flex',
            flexDirection: 'column',
            p: 2,
            rowGap: 1.5,
            position: "relative"
        },
        closeButton: {
            position: 'absolute',
            right: 8,
            top: 8,
            color: "gray",
        },
        loading: {
            minHeight: "190px",
            position: "relative",
            top: "-20px"
        }
    };

    return styles;
};
