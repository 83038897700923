export const getStyles = () => {
    const styles = {
        profileWrapper: {
            flexDirection: { xs: "column", lg: 'row' },
            alignItems: 'center',
            columnGap: '60px'
        },
        imgProfile: {
            width: "250px",
        },
        mainField: {
            fontSize: "18px",
            fontWeight: 600
        },
        wrapperInputs: {
            flexDirection: { xs: "column", lg: "row" },
            columnGap: { xs: null, lg: "30px" },
            rowGap: { xs: "30px", lg: null }
        }
    };

    return styles;
};

