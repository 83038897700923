import { PaletteMode } from '@mui/material';
import { grey } from '@mui/material/colors';

export const getDesignTokens = (mode: PaletteMode) => ({
    palette: {
        mode,
        ...(mode === 'light'
            ? {
                background: {
                    default: '#F5F5F5',
                    paper: '#F5F5F5',
                    navbar: "#E1F0DA"
                },
                color: {
                    lightGreen: {
                        100: "#E1F0DA"
                    }
                },
                text: {
                    secondary: grey[800],
                },
            }
            : {
                // palette values for dark mode
                // primary: deepOrange,
                // divider: deepOrange[700],
                // background: {
                //   default: deepOrange[900],
                //   paper: deepOrange[900],
                // },
                // text: {
                //   primary: '#fff',
                //   secondary: grey[500],
                // },
            }),
    },
    components: {
        MuiButton: {
            defaultProps: {
                variant: 'outlined' as 'outlined' | 'text' | 'contained',

            },
        },
        MuiLoadingButton: {
            defaultProps: {
                variant: 'outlined' as 'outlined' | 'text' | 'contained',

            }
        },
        MuiTooltip: {
            defaultProps: {
                arrow: true,
                PopperProps: {
                    modifiers: [
                        {
                            name: 'offset',
                            options: {
                                offset: [0, -6],
                            },
                        },
                    ],
                },
            },
            styleOverrides: {
                tooltip: {

                },

            },
        },

    },

});
